import { Component, Input, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { OrderService } from '../../../services/order.service';
declare var $;
import { GlobalService } from 'src/app/services/global.service';
import { HeaderComponent } from '../../partials/header/header.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  url: string = "https://angular.io/api/router/RouterLink";
  urlSafe = "https://localhost/cybersource_secure_acceptance_UAT_Swen/php/sa-wm/";
  paramss: any;
  lang: any ="Français"
  constructor(
    public sanitizer: DomSanitizer,
    private OrderService: OrderService,
    public globalSvc: GlobalService,

  ) { 
    this.globalSvc.setTitle('Accueil');
  }

  ngOnInit(): void {
    this.lang = this.globalSvc.preferedLanguage;
    this.globalSvc.fixDisplay();
    $("#testButton").click(function(){
      // $("#payment_confirmation").submit();
      // alert('click')
      // $.redirect("https://localhost/cybersource_secure_acceptance_UAT_Swen/php/sa-wm/payment_confirm.php",
      $.redirect("https://testsecureacceptance.cybersource.com/pay",
      {
        profile_id : "C05EF21D-A208-4ED9-B5A1-E62679B87612",
        access_key : "1f9d96dcbcbd39599d9a7e3bedf35747",
        transaction_uuid : "610525661ba31",
        signed_date_time : "2021-07-31T10:26:46Z",
        signed_field_names : "profile_id,access_key,transaction_uuid,signed_field_names,unsigned_field_names,signed_date_time,locale,transaction_type,reference_number,auth_trans_ref_no,amount,currency,merchant_descriptor,override_custom_cancel_page,override_custom_receipt_page",
        unsigned_field_names : "signature,bill_to_forename,bill_to_surname,bill_to_email,bill_to_phone,bill_to_address_line1,bill_to_address_line2,bill_to_address_city,bill_to_address_state,bill_to_address_country,bill_to_address_postal_code,customer_ip_address,merchant_defined_data1,merchant_defined_data2,merchant_defined_data3,merchant_defined_data4",
        transaction_type : "sale",
        reference_number : "B1627727207920",
        auth_trans_ref_no : "B1627727207920",
        amount: "10.00",
        currency: 'GHS',
        locale : "en-us",
        merchant_descriptor : "Swen",
        bill_to_forename : "Noreal",
        bill_to_surname : "Name",
        bill_to_email : "null@cybersource.com",
        bill_to_phone : "+662-2962-000",
        bill_to_address_line1 : "1295 Charleston Rd",
        bill_to_address_line2 : "1295 Charleston Rd",
        bill_to_address_city : "Mountain View",
        bill_to_address_state : "CA",
        bill_to_address_country: 'US',
        bill_to_address_postal_code : "94043",
        override_custom_cancel_page : "http://localhost/cybersource_secure_acceptance_UAT_Swen/php/sa-wm/response.php",
        override_custom_receipt_page : "http://localhost/cybersource_secure_acceptance_UAT_Swen/php/sa-wm/response.php",
        customer_ip_address : "::1",
        line_item_count : "2",
        merchant_defined_data1 : "MDD#1",
        merchant_defined_data2 : "MDD#2",
        merchant_defined_data3 : "MDD#3",
        merchant_defined_data4 : "MDD#4",
        signature : "xlxad8JvPZHNI1Tw2MauIEZl0CPtAKQI5RTsE8bRLek="

      }
      , "POST");
     });
    //  $("#payment_confirmation").submit();
  }

  onVisa()
  {
    let ref = Math.random().toString(36).substr(2, 5);
    this.paramss = {
      profile_id : "C05EF21D-A208-4ED9-B5A1-E62679B87612",
      access_key : "1f9d96dcbcbd39599d9a7e3bedf35747",
      transaction_uuid : "61042ddd80f59",
      signed_date_time : "2021-07-30T16:50:37Z",
      signed_field_names : "profile_id,access_key,transaction_uuid,signed_field_names,unsigned_field_names,signed_date_time,locale,transaction_type,reference_number,auth_trans_ref_no,amount,currency,merchant_descriptor,override_custom_cancel_page,override_custom_receipt_page",
      unsigned_field_names : "signature,bill_to_forename,bill_to_surname,bill_to_email,bill_to_phone,bill_to_address_line1,bill_to_address_line2,bill_to_address_city,bill_to_address_state,bill_to_address_country,bill_to_address_postal_code,customer_ip_address,merchant_defined_data1,merchant_defined_data2,merchant_defined_data3,merchant_defined_data4",
      transaction_type : "sale",
      reference_number : ref,
      auth_trans_ref_no : ref,
      amount: "10.00",
      currency: "GHS",
      locale : "en-us",
      merchant_descriptor : "Swen",
      bill_to_forename : "Noreal",
      bill_to_surname : "Name",
      bill_to_email : "null@cybersource.com",
      bill_to_phone : "+662-2962-000",
      bill_to_address_line1 : "1295 Charleston Rd",
      bill_to_address_line2 : "1295 Charleston Rd",
      bill_to_address_city : "Mountain View",
      bill_to_address_state : "CA",
      bill_to_address_country: "US",
      bill_to_address_postal_code : "94043",
      override_custom_cancel_page : "http://localhost/cybersource_secure_acceptance_UAT_Swen/php/sa-wm/customresponse.php",
      override_custom_receipt_page : "http://localhost/cybersource_secure_acceptance_UAT_Swen/php/sa-wm/customresponse.php",
      // override_custom_cancel_page : "http://localhost:4200/om-transaction-status/" + ref,
      // override_custom_receipt_page : "http://localhost:4200/om-transaction-status/" + ref,
      customer_ip_address : "::1",
      line_item_count : "2",
      merchant_defined_data1 : "MDD#1",
      merchant_defined_data2 : "MDD#2",
      merchant_defined_data3 : "MDD#3",
      merchant_defined_data4 : "MDD#4",
      signature : "uvSvinvz2Q9a+CoYdBGOUNTn9bv1U0lOD98kIXjR0Ws="
    }

    this.OrderService.visadata(this.paramss).subscribe(resp =>{
      console.log(resp);
      this.paramss.transaction_uuid = resp['uuid'];
      this.paramss.signed_date_time = resp['datetime'];
      this.paramss.signature = resp['signature'];
      // this.paramss.reference_number = resp['ref'];
      // this.paramss.auth_trans_ref_no = resp['auth'];
      console.log(this.paramss);

      $.redirect("https://testsecureacceptance.cybersource.com/pay", this.paramss, 'POST');
    }, async error => {
      console.error(error)
    });


    // this.route.navigateByUrl("http://localhost/cybersource_secure_acceptance_UAT_Swen/php/sa-wm/");
    // window.location.href ='http://localhost/cybersource_secure_acceptance_UAT_Swen/php/sa-wm/payment_form.php';
    // this.urlSafe= this.sanitizer.sanitize(SecurityContext.URL, this.url);
    // const iframe =  document.getElementById('embeddedPage') as HTMLIFrameElement;
    // iframe.contentWindow.location.replace(this.url);
  }

  visa1() {
    $.redirect("https://testsecureacceptance.cybersource.com/pay", this.paramss, 'POST');
  }

}
