<div class="container">
  <div class="row">

    <div class="col-md-10 offset-md-1 pt-2 pb-2">
      <mat-tab-group animationDuration="0ms" >
        <mat-tab label="Traitement & Suivi">
          <div class="mt-3 mb-3">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Impedit numquam, optio, modi nobis tempora delectus, magni eveniet iusto sunt aperiam dolore molestias. Et, debitis tempore. Error molestias quidem temporibus exercitationem.
          </div>

        </mat-tab>
        <mat-tab label="Etat Actuel">
          <div class="mt-3 mb-3">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Impedit numquam, optio, modi nobis tempora delectus, magni eveniet iusto sunt aperiam dolore molestias. Et, debitis tempore. Error molestias quidem temporibus exercitationem.
          </div>
        </mat-tab>
        <mat-tab label="Antécédents Familiaux">
          <div class="mt-3 mb-3">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Impedit numquam, optio, modi nobis tempora delectus, magni eveniet iusto sunt aperiam dolore molestias. Et, debitis tempore. Error molestias quidem temporibus exercitationem.
          </div>
        </mat-tab>

      </mat-tab-group>
    </div>
  </div>
</div>
