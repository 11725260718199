<div class="shell">
  <div class="container">
    <div class="row">
      <div class="col-md-5 col-lg-4 col-sm-6 offset-md-1 offset-lg-2">
        <!-- <div>
          <p>
            Notre offre la plus populaire <i class="fa fa-certificate" style="color: gold;"></i>
          </p>
        </div> -->
        <div class="wsk-cp-product">
          <!-- <div class="wsk-cp-img">
            <img src="../../../../assets/img/carte-santé.png" alt="Product" class="img-responsive" />
          </div> -->
          <div class="wsk-cp-text">
            <!-- <div class="category">
              <span>200.000 GNF</span>
            </div> -->
            <div class="title-product">
              <h3>Formule carte simple</h3>
            </div>
            <div class="text-center mb-3">
              <span>200.000 GNF</span>
            </div>
            <div class="description-prod mb-2">
              <!-- <p>Description Product tell me how to change playlist height size like 600px in html5 player. player good
                work now check this link</p> -->
              <div>
                <span><i class="fa fa-check-square-o" style="color: green;" aria-hidden="true"></i> Dossier Médical
                  Partagé DMP</span><br><br>
                <span><i class="fa fa-check-square-o" style="color: green;" aria-hidden="true"></i> Portemonnaie
                  électronique de santé</span><br><br>
                <span><i class="fa fa-check-square-o" style="color: green;" aria-hidden="true"></i> Réseau de
                  prestatires de soins de qualité</span><br><br>
                <span style="text-decoration: line-through; color: red;"><i class="fa fa-window-close-o"
                    aria-hidden="true"></i> Couverture hospi de 100%</span><br>
              </div>
            </div>
            <div class="card-footer">
              <p class="text-center">
                <button class="btn btn-outline-success rounded" (click)="goToOffer('default')">Commander</button>
              </p>
              <!-- <div class="wcf-left"><span class="price">Rp500.000</span></div>
              <div class="wcf-right"><a href="#" class="buy-btn"><i class="zmdi zmdi-shopping-basket"></i></a></div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-5 col-lg-4 col-sm-6">
        <div class="wsk-cp-product">
          <!-- <div class="wsk-cp-img">
            <img src="../../../../assets/img/carte-santé.png" alt="Product" class="img-responsive" />
          </div> -->
          <div class="wsk-cp-text">
            <!-- <div class="category">
              <span>1.000.000 GNF</span>
            </div> -->
            <div class="title-product">
              <h3>Formule Hospi</h3>
            </div>
            <div class="text-center mb-3">
              <span>1.000.000 GNF</span>
            </div>
            <div class="description-prod mb-2">
              <!-- <p>Description Product tell me how to change playlist height size like 600px in html5 player. player good
                work now check this link</p> -->
              <span><i class="fa fa-check-square-o" style="color: green;" aria-hidden="true"></i> Dossier Médical
                Partagé DMP</span><br><br>
              <span><i class="fa fa-check-square-o" style="color: green;" aria-hidden="true"></i> Portemonnaie
                électronique de santé</span><br><br>
              <span><i class="fa fa-check-square-o" style="color: green;" aria-hidden="true"></i> Réseau de prestatires
                de soins de qualité</span><br><br>
              <span><i class="fa fa-check-square-o" style="color: green;" aria-hidden="true"></i> Couverture hospi de
                100%</span><br>
            </div>
            <div class="card-footer">
              <p class="text-center">
                <button class="btn btn-outline-success rounded" (click)="goToOffer('hospi')">Commander</button>
              </p>
              <!-- <div class="wcf-left"><span class="price">Rp500.000</span></div>
              <div class="wcf-right"><a href="#" class="buy-btn"><i class="zmdi zmdi-shopping-basket"></i></a></div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
