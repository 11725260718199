
<div class="container mb-5">
  <div class="row">
    <div class="col-12">
      <mat-progress-spiner mode="indeterminate"></mat-progress-spiner>
      <h1 class="text-center pt-5" style="font-weight:bold; color: #3db549;">
        Votre demande a été prise en compte. Rendez-vous  au point de retrait que vous avez choisi
        pour effectuer le paiement et finaliser votre demande.
      </h1>
    </div>
  </div>
</div>
