
<section class="backcolor posi">
   <div class="py-3">
  </div>
  <div class="container">

       <!-- <section class="container-fluid pt-grid-gutter">

         <div class="row">
           <div class="col-xl-3 col-md-6 mb-grid-gutter">
             <div class="card"  style="background:#034B4A ; color: #0BB133 ;">
               <div class="card-body text-center"><i class="fa fa-map-marker fa-2x white-text mt-2 mb-4" style="color:#01d6a3 ;" aria-hidden="true" ></i>
                 <h3 class="h6 mb-2">Taouyah Corniche</h3>
                 <p class="font-size-sm white-text">Commune de Ratoma, BP. 200 Conakry</p>

               </div>
              </div>
            </div>
           <div class="col-xl-3 col-md-6 mb-grid-gutter">
             <div class="card" style="background:#034B4A ; color: #0BB133 ;">
               <div class="card-body text-center" ><i class="fa fa-clock-o fa-2x mt-2 mb-4 white-text"></i>
                 <h3 class="h6 mb-3">Heures d'ouverture</h3>
                 <ul class="list-unstyled font-size-sm text-muted mb-0">
                   <li class="white-text">Lun - Ven: 8h30 - 17h30</li>
                 </ul>
               </div>
             </div>
           </div>
           <div class="col-xl-3 col-md-6 mb-grid-gutter">
             <div class="card" style="background:#034B4A ; color: #0BB133 ;">
               <div class="card-body text-center" ><i class="fa fa-phone fa-2x mt-2 mb-4 white-text"></i>
                 <h3 class="h6 mb-3">Téléphone</h3>
                 <ul class="list-unstyled font-size-sm mb-0">
                   <li><span class="white-text mr-1">+224 625 700 000</span></li>

                 </ul>
               </div>
             </div>
           </div>
           <div class="col-xl-3 col-md-6 mb-grid-gutter">
             <div class="card" style="background:#034B4A ; color: #0BB133 ;">
               <div class="card-body text-center" ><i class="fa fa-envelope fa-2x mt-2 mb-4 white-text"></i>
                 <h3 class="h6 mb-3">Adresse email</h3>
                 <ul class="list-unstyled font-size-sm mb-0">
                   <li><span class="white-text mr-1">contact@teliya.net</span></li>

                 </ul>
               </div>
             </div>
           </div>
         </div>
         <div class="py-4">

         </div>
         <div class="py-4">


         </div>

       </section> -->



       <div class="container-fluid pt-5 mt-5 mb-5">

         <!--Grid row-->
         <div class="row" style="margin-top: -100px;">

           <!--Grid column-->
           <div class="col-md-12">

             <div class="card pb-5">
               <div class="card-body">

                 <div class="container">

                   <!--Section: Contact v.2-->
                   <section class="section">

                     <!--Section heading-->
                     <h2 class="font-weight-bold text-center h1 my-2">Contactez nous</h2>
                     <!--Section description-->


                     <div class="row pt-2">

                       <!--Grid column-->
                       <div class="col-md-8 col-xl-9">
                         <form [formGroup]="form">

                           <!--Grid row-->
                           <div class="row">

                             <!--Grid column-->
                             <div class="col-md-6">
                               <div class="md-form">
                                 <input type="text" id="contact-name" name="name" formControlName="nameCtrl" class="form-control">
                                 <label for="contact-name" class="">Votre nom</label>
                               </div>
                             </div>
                             <!--Grid column-->

                             <!--Grid column-->
                             <div class="col-md-6">
                               <div class="md-form">
                                <input
                                  type="email" id="contact-email" name="email"
                                  pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}"
                                  formControlName="emailCtrl" class="form-control" required
                                >
                                <label for="contact-email" class="">Votre email</label>
                               </div>
                             </div>
                             <!--Grid column-->

                           </div>
                           <!--Grid row-->

                           <!--Grid row-->
                           <div class="row">
                             <div class="col-md-12">
                               <div class="md-form">
                                 <input type="text" id="contact-Subject"  name="subject" formControlName="subjectCtrl" class="form-control">
                                 <label for="contact-Subject" class="">Objet</label>
                               </div>
                             </div>
                           </div>
                           <!--Grid row-->

                           <!--Grid row-->
                           <div class="row">

                             <!--Grid column -->
                             <div class="col-md-12">

                               <div class="md-form">
                                 <textarea type="text" id="contact-message" name="message" formControlName="messageCtrl" class="md-textarea form-control" rows="3"></textarea>
                                 <label for="contact-message">Votre message</label>
                               </div>

                             </div>
                           </div>
                           <!--Grid row-->

                         </form>

                         <div class="text-center text-md-left my-4">
                           <button class="btn peach-gradient" (click)="sendMail()" [disabled]="!form.valid" >Envoyer</button>
                         </div>
                       </div>
                       <!--Grid column-->

                       <!--Grid column-->
                       <div class="col-md-4 col-xl-3">
                         <ul class="contact-icons text-center list-unstyled">
                           <li>
                             <i class="fa fa-map-marker fa-2x green-text"></i>
                             <p>Taouyah Corniche,Commune de Ratoma, B.P. 200 Conakry</p>

                           </li>
                           <li>
                             <i class="fa fa-clock-o fa-2x green-text"></i>
                             <p>Ouvert: Lun - Ven: 8h30 - 17h30</p>

                           </li>

                           <li>
                             <i class="fa fa-phone fa-2x green-text"></i>
                             <p>+224 625 700 000</p>
                           </li>

                           <li>
                             <i class="fa fa-envelope fa-2x green-text"></i>
                             <p>contact@teliya.net</p>
                           </li>
                         </ul>
                       </div>
                       <!--Grid column-->

                     </div>

                   </section>
                   <!--Section: Contact v.2-->

                 </div>
               </div>

             </div>
             <!--Grid column-->

           </div>
           <!--Grid row-->

         </div>

       </div>

       <div class="py-4">

       </div>
       <div class="py-4">

       </div>
 </div>
 </section>
