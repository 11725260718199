import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-error',
  templateUrl: './visapay.component.html',
  styleUrls: ['./visapay.component.css']
})
export class VisapayComponent implements OnInit {

  urlSafe = "https://localhost/cybersource_secure_acceptance_UAT_Swen/php/sa-wm/";

  constructor() { }

  ngOnInit(): void {
  }

}
