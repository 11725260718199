import { Component, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NotificationType } from 'src/app/enum/notification-type.enum';
import { OrderService } from 'src/app/services/order.service';
import { NotifierService } from 'angular-notifier';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { GlobalService } from 'src/app/services/global.service';
import { MatStepper } from '@angular/material/stepper';
import { MatSelectChange } from '@angular/material/select';
import {  Router } from '@angular/router';

@Component({
  selector: 'app-adhere',
  templateUrl: './adhere.component.html',
  styleUrls: ['./adhere.component.css']
})
export class AdhereComponent implements OnInit {
  @ViewChild('stepper', {static: false}) stepper: MatStepper;
  @Output()
  selectionChange: EventEmitter< MatSelectChange>
  isLinear: boolean = true;
  showDescription: boolean = true;
  form_adhere: FormGroup;
  representative_form: FormGroup;
  partnersList: any[] = [];
  otherPartnersList: any[] = [];
  selectedPartners;
  otherPart: boolean = false;
  seen: boolean = false;
  @ViewChild(ToastContainerDirective, { static: true })
  toastContainer: ToastContainerDirective;

  constructor(private formBuilder: FormBuilder,
     private ording: OrderService, private toastr: ToastrService,
     private notificationService: NotifierService,
     private globalSvc: GlobalService,
     private router: Router,
     ) {
        this.globalSvc.setTitle("J'adhère au réseau de soins");
      }

  ngOnInit(): void {
    this.globalSvc.fixDisplay();
    this.toastr.overlayContainer = this.toastContainer;

    this.form_adhere = this.formBuilder.group({
      strucCtrl: new FormControl('', [Validators.required]),
      // denom: new FormControl('', [Validators.required]),
      denomCtrl:  new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      contact: new FormControl('', [Validators.required]),
      partenatCtrl:  new FormControl(''),
      addOtherPart:  new FormControl(false),
      otherPartnersCtrl:  new FormControl('',),
      rccmCtrl: new FormControl('', [Validators.required]),

    });
    this.representative_form = this.formBuilder.group({
      aggissant:  new FormControl('', [Validators.required]),
      firstCtrl: new FormControl('', [Validators.required, Validators.maxLength(100)]),
      lastCtrl: new FormControl('', [Validators.required]),
      phone: new FormControl('', [Validators.required, Validators.maxLength(15)]),
      email: new FormControl('', [Validators.required, Validators.email]),
    });
  }


  structure: any = [
    {value: "CHU"},
    {value: "Centre de santé"},
    {value: "Clinique"},
    {value: "Pharmacie"},
    {value: "Laboratoire"},
    {value: "Cabinet dentaire"},
    {value: "Cabinet d'ophtamologie"},
    {value: "Autres"}
  ];

  insurance: any = [
    {value: "NSIA Assurance"},
    {value: "UGAR-Activa"},
    {value: "SOGAM"},
    {value: "Premium Assurance"},
  ];



  saveAdderer(){
    this.globalSvc.showNgxLoader();
    const donnee = {
      partnair:  this.form_adhere.get("partenatCtrl").value,
      otherPartnair:  this.form_adhere.get("otherPartnersCtrl").value,
    }


    const data = {

      structure: this.form_adhere.get("strucCtrl").value,
      denomination:this.form_adhere.get("denomCtrl").value,
      rccm_name: this.form_adhere.get("rccmCtrl").value,
      // rccm_id: this.form_adhere.get("number_rccmCtrl").value,
      address: this.form_adhere.get("address").value,
      contact: this.form_adhere.get("contact").value,
      partnair:  this.partnersList,
      otherPartnair:  this.otherPartnersList,
      firstName: this.representative_form.get("firstCtrl").value,
      lastName: this.representative_form.get("lastCtrl").value,
      function:  this.representative_form.get("aggissant").value,
      phone:  this.representative_form.get("phone").value,
      email:  this.representative_form.get("email").value

     }
    if(this.form_adhere.invalid || this.representative_form.invalid){
      return;

    }else{
      console.log(this.form_adhere.get("otherPartnersCtrl").value);

      if (donnee.partnair != null) {
        for (let t of donnee.partnair) {
          let found= null;
          found =  this.partnersList.find(element => element === t);
          if(found==null){
            this.partnersList.push(t);
          }
        }
        console.log(this.partnersList);

      }
      if (donnee.otherPartnair != null) {
        let dt = donnee.otherPartnair.split(",");
        console.log(dt);

        for (let el of dt) {
          let found= null;
          found =  this.partnersList.find(element => element === el);
          if(found==null){
            this.otherPartnersList.push(el);
            this.partnersList.push(el);
          }else{
            this.otherPartnersList.push(el);
          }
        }
        console.log(this.partnersList);
        console.log(this.otherPartnersList);

      }
      console.log(data);
      // return;
      this.ording.saveadhesion(data).subscribe(resp =>{
        this.globalSvc.spinner.hide();
        this.form_adhere.reset();
        // this.form_adhere.get("strucCtrl").clearValidators;
        // this.form_adhere.get("strucCtrl").updateValueAndValidity;
        // this.form_adhere.get("denomCtrl").clearValidators;
        // this.form_adhere.get("denomCtrl").updateValueAndValidity;
        // this.form_adhere.get("rccmCtrl").clearValidators;
        // this.form_adhere.get("rccmCtrl").updateValueAndValidity;
        // this.form_adhere.get("number_rccmCtrl").clearValidators;
        // this.form_adhere.get("number_rccmCtrl").updateValueAndValidity;
        // this.form_adhere.get("address").clearValidators;
        // this.form_adhere.get("address").updateValueAndValidity;
        // this.form_adhere.get("contact").clearValidators;
        // this.form_adhere.get("contact").updateValueAndValidity;
        // this.form_adhere.get("partenatCtrl").clearValidators;
        // this.form_adhere.get("partenatCtrl").updateValueAndValidity;
        // this.representative_form.get("firstCtrl").clearValidators;
        // this.representative_form.get("firstCtrl").updateValueAndValidity;
        // this.representative_form.get("lastCtrl").clearValidators;
        // this.representative_form.get("lastCtrl").updateValueAndValidity;
        // this.representative_form.get("phone").clearValidators;
        // this.representative_form.get("phone").updateValueAndValidity;
        // this.representative_form.get("email").clearValidators;
        // this.representative_form.get("email").updateValueAndValidity;
        // this.representative_form.get("aggissant").clearValidators;
        // this.representative_form.get("aggissant").updateValueAndValidity;
        this.representative_form.reset();
        // this.form_adhere.reset();
        this.otherPart=false;
        this.seen = false;
        this.partnersList= [];
        this.otherPartnersList= [];


        this.toastr.success('SUCCESS', `Votre demande a été enregister avec success`.toUpperCase(),{
          timeOut: 4000,
          progressBar: true
        });
        this.router.navigateByUrl('/insurance-request-notification');

      },
      error=>{
        console.log(error);

        this.globalSvc.spinner.hide();
        this.toastr.error('ECHEC', "l'opération à échouée. Veuillez réessayer!" ,{
          timeOut: 4000,
          progressBar: true
        });
      });
    }

  }

  onCheckboxChange(event: MatCheckboxChange){

    if(event.checked){
      console.log(event.checked)

      this.seen = event.checked;
    } else{
      this.seen = false;
    }

  }
  onAddPartner(event: MatCheckboxChange){

    if(event.checked){
      console.log(event.checked)

      this.otherPart = event.checked;
    } else{
      this.otherPart = false;
    }

  }

  scrollToTop(){
    this.globalSvc.fixDisplay();
  }
  canShowDescription(status: boolean){
    this.showDescription = status;
  }
}
