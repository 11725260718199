import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-order-confirmation',
  templateUrl: './order-confirmation.component.html',
  styleUrls: ['./order-confirmation.component.css']
})
export class OrderConfirmationComponent implements OnInit {
  age: any = null;
  constructor(
    private globalSvc: GlobalService,
  ) { 
    this.globalSvc.setTitle('Confirmation de Commande');
  }

  ngOnInit(): void {
    // this.age = this.globalSvc.calculateAge('2000/02/03');
    // console.log(this.age);
    
  }

}
