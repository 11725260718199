import { Component, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  constructor(
    private globalSvc: GlobalService,
    private router: Router
  ) { 
    this.globalSvc.setTitle('A propos');
  }

  ngOnInit(): void {
    this.globalSvc.fixDisplay();
  }

}
