 <!--Intro Section-->
 <section class="view intro-2 posi">
  <picture>
    <img
    sizes="(max-width: 1400px) 100vw, 1400px"
    srcset="
    ../../../../assets/img/ing_ovr3wm/ing_ovr3wm_c_scale,w_200.png 200w,
    ../../../../assets/img/ing_ovr3wm/ing_ovr3wm_c_scale,w_404.png 404w,
    ../../../../assets/img/ing_ovr3wm/ing_ovr3wm_c_scale,w_549.png 549w,
    ../../../../assets/img/ing_ovr3wm/ing_ovr3wm_c_scale,w_678.png 678w,
    ../../../../assets/img/ing_ovr3wm/ing_ovr3wm_c_scale,w_791.png 791w,
    ../../../../assets/img/ing_ovr3wm/ing_ovr3wm_c_scale,w_898.png 898w,
    ../../../../assets/img/ing_ovr3wm/ing_ovr3wm_c_scale,w_995.png 995w,
    ../../../../assets/img/ing_ovr3wm/ing_ovr3wm_c_scale,w_1060.png 1060w,
    ../../../../assets/img/ing_ovr3wm/ing_ovr3wm_c_scale,w_1145.png 1145w,
    ../../../../assets/img/ing_ovr3wm/ing_ovr3wm_c_scale,w_1224.png 1224w,
    ../../../../assets/img/ing_ovr3wm/ing_ovr3wm_c_scale,w_1294.png 1294w,
    ../../../../assets/img/ing_ovr3wm/ing_ovr3wm_c_scale,w_1400.png 1400w"
    src="../../../../assets/img/ing_ovr3wm/ing_ovr3wm_c_scale,w_1400.png"
    alt="">
    </picture>

   <!-- <div class="mask rgba-gradient">../../../../assets/img/ing.png
     <div class="container h-100 d-flex justify-content-center align-items-center">
       <div class="row flex-center pt-5 mt-3">
         <div class="col-md-6 text-center text-md-left margins">

        </div>
        <div class="col-md-6 wow fadeInRight d-flex justify-content-center" data-wow-delay="0.3s">

        </div>
      </div>
    </div>
  </div> -->
</section>
