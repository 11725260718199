 <!--Intro Section-->
 <section class="view intro-2 posi"
    style="background-image: url(../../../../assets/img/we-are-teliya.png);
           background-size: cover; background-repeat: no-repeat; height: 80%;">
    <div class="mask rgba-gradient">
        <div class="container h-100 d-flex justify-content-center align-items-center">
          <div class="flex-center pt-5 mt-3">
            <div class="text-center text-md-left margins">
              <div class="white-text" >
                <h1 class="text-center" style="font-size:4rem; font-weight: bold; line-height: 1em; color: white;" >Nous sommes Teliya santé</h1>
                <hr class="hr-light mt-5 line-un">
                <br>
              </div>
            </div>
          </div>
        </div>
        </div>
  </section>


  <section class="backcolor">

    <section class="container">
      <div class="content-header">
        <h2 class="size-33 teta text-center" style="color:#033b55">Nous intervenons tout au long du parcours patient</h2>
        <div class="row">
          <!--Grid column-->
        <div class="col-md-4 offset-md-1 text-center">
            <img class="bd-placeholder-img" width="260px" height="245px" src="../../../../assets/img/parcours-patient.png" alt="">
        </div>
        <div class="col-md-7">
         <p class="text-center size-22 pt-5" style="line-height: 1.3em; margin: 5px;">
           Teliya santé met en relation tous les acteurs de la santé afin de faciliter
           les échanges d'information pour une meilleure qualité de soins
         </p>
        </div>
        <!--Grid column-->

        </div>
      </div>
    </section>

    <section id="banner-7" class="bg-scroll wide-100 info-section division mt-5" style="background-image: url(../../../../assets/img/our-mission.png); color: white;">
          <div class="container white-color">

             <div class="row d-flex align-items-center">

               <!-- BANNER TEXT -->
               <div class="col-md-8 col-lg-7 col-xl-6">
                <div class="banner-txt wow fadeInUp" data-wow-delay="0.4s">

                  <!-- Title  -->
                  <h2 class="h2-xs text-center size-33" style="color:#033b55">NOTRE MISSION</h2>

                    <!-- Text -->
                    <p class="size-22 text-center" style="line-height: 1.3em;">Etre acteur dans la démarche d'atteinte des objectifs du développement durable en Afrique.</p>
                    <p class="size-15 text-center" style="line-height: 1.3em;"> <img src="../../../../assets/img/odd3.8.png" width="50px" height="50px" alt=""> ODD3.8 - Assurer la santé et le bien-être de tous</p>


                </div>
              </div>	<!-- END BANNER TEXT -->


            </div>      <!-- End row -->
          </div>	    <!-- End container -->
    </section>

    <section id="info-5" class="bg-scroll wide-100 info-section division" style="background-image: url(../../../../assets/img/our-vision.png); color: black;">
          <div class="container mt-5">
            <div class="row d-flex align-items-center">


              <!-- TEXT BLOCK -->
              <div class=" col-xs-12 col-sm-10 offset-sm-1 col-md-8 col-lg-6 col-xl-6 offset-md-2 offset-lg-5 offset-xl-6 offset-lg-6">
                <div class="banner-txt wow fadeInUp" data-wow-delay="0.4s" style="background-color: white; opacity: 0.8; height: 100%; width: 100%;" >

                      <!-- Title  -->
                  <h2 class="h2-xs text-center size-33 pt-5" style="color:#033b55">NOTRE VISION</h2>

                    <p class="size-22 text-center" style="line-height: 1.3em;">Utiliser les outils informatiques pour améliorer la gestion des soins de santé et garantir la qualité des soins <br> Tout en réduisant le risque financier lié à la maladie.
                    </p>


                </div>
              </div>	<!-- END TEXT BLOCK -->


            </div>    <!-- End row -->
          </div>	   <!-- End container -->
    </section>

    <section id="banner-7" class="bg-scroll wide-100 info-section division mt-5" style="background-image: url(../../../../assets/img/notre-equipe.png); background-size: cover; color: black;">
          <div class="container white-color">

             <div class="row d-flex align-items-center">

               <!-- BANNER TEXT -->
               <div class="col-md-12 col-lg-12 col-xl-12" style="  width: 100%;">
                <div class="txt-block pc-30 white-color text-center wow fadeInUp" data-wow-delay="0.4s">


                  <h2 class="h2-xs text-center size-33 pt-3" style="color:#033b55">NOTRE EQUIPE</h2>
                  <p class="size-22" style="line-height: 1.3em;">Une équipe orientée amélioration des soins de santé </p>
                  <p class="size-22" style="line-height: 1.3em;">&bull; Nous sommes des experts du domaine des NTIC appliquées à la santé.</p>
                  <p class="size-22" style="line-height: 1.3em;">&bull; Nous combinons plus de 30 années d'expériences dans différents domaines : Télécommunications, Santé, ...</p>
                 <p class="size-22" style="line-height: 1.3em;"> &bull; Nous offrons des solutions informatiques et technologiques aux acteurs des soins de santé : Grand public, professionnels de santé, assurances, mutuelles,...</p>
                 <p class="size-22">
                   <span class="text-center"><a style="font-weight: bold;" routerLink="/contact">Nous Joindre</a></span>
                 </p>


                </div>
              </div>	<!-- END BANNER TEXT -->


            </div>      <!-- End row -->
          </div>	    <!-- End container -->
    </section>

  </section>
