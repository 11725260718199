import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-insurance',
  templateUrl: './insurance.component.html',
  styleUrls: ['./insurance.component.css']
})
export class InsuranceComponent implements OnInit {
  panelOpenState = false;
  constructor(
    private toastr: ToastrService,
    private globalSvc: GlobalService,
  ) { 
    this.globalSvc.setTitle("Offres d'assurance");
  }

  ngOnInit(): void {
    this.globalSvc.fixDisplay();
  }
  showInDevelopmentMessage(){
    this.toastr.success('', `Ce module est en cours de developpement`.toUpperCase(),{
      timeOut: 5000,
      progressBar: true,
    });
  }

}
