import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-insurance-notif',
  templateUrl: './insurance-notif.component.html',
  styleUrls: ['./insurance-notif.component.css']
})
export class InsuranceNotifComponent implements OnInit {
  constructor(
    private globalSvc: GlobalService,
    private router: Router
  ) { 
    this.globalSvc.setTitle("Confirmation de demande");
  }

  ngOnInit(): void {

    this.globalSvc.fixDisplay();

    this.globalSvc.showLoading();

    this.globalSvc.hideLoading();

  }

}
