import { Component, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit {

  constructor(
    private globalSvc: GlobalService,
    private router: Router
  ) { 
    this.globalSvc.setTitle('Non trouvé');
  }

  ngOnInit(): void {
    this.globalSvc.fixDisplay();
  }

}
