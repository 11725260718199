import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-conditions',
  templateUrl: './conditions.component.html',
  styleUrls: ['./conditions.component.css']
})
export class ConditionsComponent implements OnInit {

  constructor(
    private globalSvc: GlobalService,
  ) { }

  ngOnInit(): void {
    this.globalSvc.fixDisplay();
  }

}
