import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {

  constructor(
    private globalSvc: GlobalService,
  ) { 
    this.globalSvc.setTitle('Politique de Confidentialité');
  }

  ngOnInit(): void {
    this.globalSvc.fixDisplay();
  }

}
