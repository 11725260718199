<div class="glob">
  <!-- <section class="view intro-2 posi">
    <div class="mask rgba-gradient">
      <div class="container h-100 d-flex justify-content-center align-items-center">
        <div class="row flex-center pt-3 mt-3">
          <div class="col-md-6 text-center text-md-left margins">
            <div class="white-text">
              <h1 class="h1-responsive font-weight-bold wow fadeInLeft text-white text-center" data-wow-delay="0.3s"
                style="font-size:4.5rem; line-height: 1em;">Teliya santé</h1>
              <br>
              <div class="text-center">
                <hr class="wow fadeInLeft" data-wow-delay="0.3s">
                <h3 class="text-white">GERER LE TIERS PAYANT</h3>
                <hr class="hr-green wow fadeInLeft" data-wow-delay="0.3s">
                <h3 class="text-white">ELARGIR SON PORTEFEUILLE DE PARTENAIRE</h3>
                <hr class="hr-green wow fadeInLeft" data-wow-delay="0.3s">
                <h3 class="text-white">CREER DE NOUVEAUX REVENUS POUR VOTRE STRUCTURE</h3>
                <hr class="hr-green wow fadeInLeft" data-wow-delay="0.3s">
                <h3 class="text-white">AMELIORER LA RAPIDITE DE PRISE EN CHARGE</h3>
              </div>
              <br>
            </div>
          </div>
          <div class="col-md-6 wow fadeInRight d-flex justify-content-center" data-wow-delay="0.3s">
            <img src="../../../../assets/img/professionne-santel.png" height="500" width="383" alt=""
              class="img-responsive">
          </div>
        </div>
      </div>
    </div>
  </section> -->
  <div class="posi pl-2 pr-2" style=" background: url(../../../../assets/img/row-bgimage-8.jpg);
                background-repeat: no-repeat; background-position:  center center;
                 background-size: cover;"
  >
    <div class="mask rgba-gradient">
      <div class="container h-100 d-flex justify-content-center align-items-center">
        <div class="row flex-center pt-3 mt-3">
          <div class="col-md-6 text-center text-md-left margins">
            <div class="white-text">
              <h1 class="h1-responsive font-weight-bold wow fadeInLeft text-white text-center" data-wow-delay="0.3s"
                style="font-size:4.5rem; line-height: 1em;">Teliya santé</h1>
              <br>
              <div class="text-center">
                <hr class="wow fadeInLeft" data-wow-delay="0.3s">
                <h4 class="text-white">GERER LE TIERS PAYANT</h4>
                <hr class="hr-green wow fadeInLeft" data-wow-delay="0.3s">
                <h4 class="text-white">ELARGIR SON PORTEFEUILLE DE PARTENAIRE</h4>
                <hr class="hr-green wow fadeInLeft" data-wow-delay="0.3s">
                <h4 class="text-white">GENERER DE NOUVEAUX REVENUS POUR VOTRE STRUCTURE</h4>
                <hr class="hr-green wow fadeInLeft" data-wow-delay="0.3s">
                <h4 class="text-white">AMELIORER LA RAPIDITE DE PRISE EN CHARGE</h4>
              </div>
              <br>
            </div>
          </div>
          <div class="col-md-6 wow fadeInRight d-flex justify-content-center" data-wow-delay="0.3s">
            <img src="../../../../assets/img/professionne-santel.png" height="500" width="383" alt=""
              class="img-responsive">
          </div>
        </div>
      </div>
    </div>
  </div>


  <section class="backcolor">
    <div>
      <!--Section: Features v.2-->
      <div>

        <div class=" row">

          <div class="col-10 offset-1">
            <h1 class="text-center mt-3" style="font-weight: bold; font-size: 38px; color:#033B55;">Le Réseau de soins
              Teliya</h1>

            <p class=" text-center mt-3 size-20" style="line-height: 25px;">
              Teliya santé est un réseau de professionnels de santé mis en place dans le but de favoriser la médecine
              collaborative,
              de mettre en place tous les outils permettant de faciliter la gestion du tiers payant et de garantir des
              soins de santé de qualité.</p>
            <p class="text-center">
              <a routerLink="/adhere" class="btn btn-success change" role="button">Adhérer au réseau de soins</a>
            </p>
          </div>
        </div>
      </div>
      <section class="container" style="background-color: #f8f9fb;">
        <h2 class="text-center size-30" style="font-weight: bold;">Les plus du réseau de soins Teliya santé</h2>
        <p class="text-center" style="font-size: 24px;">En plus de Bénéficiez de la force d'un réseau de soins, teliya
          santé</p>


        <div class="row text-center mb-3">
          <div class="col-lg-6">
            <img class="bd-placeholder-img" width="260" height="140" src="../../../../assets/img/ame.png" alt="">

            <h2 class="size-20">Plateforme de gestion du tiers payants</h2>
            <!-- <p class="text-center size-18">Accessible sur tous les supports (tablettes, smartphone, PC) et pour toute votre équipe</p> -->



          </div><!-- /.col-lg-4 -->

          <div class="col-lg-6">
            <img class="bd-placeholder-img" width="260" height="140" src="../../../../assets/img/dev-partenaire.png"
              alt="">


            <h2 class="size-20">Développement du réseau de partenariats</h2>
            <!-- <p class="size-18">La plateforme est sécurisée à l'aide du SSL</p> -->

          </div><!-- /.col-lg-4 -->
        </div><!-- /.row -->


        <div class="row text-center">
          <div class="col-lg-6">
            <img class="bd-placeholder-img" width="260" height="140" src="../../../../assets/img/revenu.png" alt="">

            <h2 class="size-20">Opportunités de générer de nouveaux revenus</h2>
            <!-- <p class="size-18">Le Backoffice est accessible pour toute votre <br> équipe sur n'importe quel ordinateur.</p> -->



          </div><!-- /.col-lg-4 -->

          <div class="col-lg-6">
            <img class="bd-placeholder-img" width="240" height="140" src="../../../../assets/img/+outils.png" alt="">


            <h2 class="size-20">Accéder à plus d'outils pour améliorer la rentabilité</h2>
            <!-- <p class="size-18">Une équipe spécialisée à votre disposition</p> -->

          </div><!-- /.col-lg-4 -->
        </div><!-- /.row -->
      </section>
      <section class="backcolor">
        <div>
          <!--Section: Features v.2-->
          <div class="ml-2 mr-2">

            <div class="row">

              <div class="col-12">
                <p class=" text-center mt-3 mb-3 size-20" style="line-height: 25px;">
                  Vous êtes un professionnel de santé et vous souhaitez adhérer au Réseau Teliya santé ?
                  Vous pouvez remplir et envoyer le <a routerLink="/adhere" style="color: #3db549;">formulaire d'adhesion</a>.
                  Votre demande sera examinée et nous vous répondrons dans les meilleurs délais.
                </p>

              </div>
            </div>
          </div>

        </div>

      </section>
      <div style="background-image: url(../../../../assets/img/footer-bg.jpg); color: #f8f9fb;">
        <div class="container pt-2">
          <div>
            <p class="size-30 text-center m-5" style="font-weight: bold; line-height: 1.2em;">La solution Teliya pour la
              gestion du tiers payant, quelles avantages?</p>
          </div>



          <div class="mt-5 mb-5 text-center">
            <p class="mt-2 mb-5 container text-center size-18">
              Le réseau de soins Teliya mets à la disposition de ses membres une plateforme de gestion du tiers payant
              adaptée à leurs besoins
            </p>

            <div class="row mb-5">
              <div class="col-md-4 text-center">
                <span> <img class="bd-placeholder-img" width="25%" height="80"
                    src="../../../../assets/img/recoverate.svg" alt=""></span>
                <h2>Remboursement</h2>
                <p>L'assurance d'être automatiquement remboursé : Une seule interface pour la gestion du tiers payant.
                </p>

              </div>

              <div class="col-md-4 text-center">
                <span> <img class="bd-placeholder-img" width="25%" height="80" src="../../../../assets/img/reduce.svg"
                    alt=""></span>
                <h2>Nouveaux revenus</h2>
                <p>commission sur la remise des cartes de santé</p>

              </div>
              <div class="col-md-4 text-center">
                <span> <img class="bd-placeholder-img" width="25%" height="80"
                    src="../../../../assets/img/features_statistics.svg" alt=""></span>
                <h2>Efficacité</h2>
                <p>La coordination des professionnels de santé améliore la qualité de soins</p>

              </div>
            </div>

            <div class="row">
              <div class="col-md-4">
                <span> <img class="bd-placeholder-img" width="25%" height="80"
                    src="../../../../assets/img/features_monitoring.svg" alt=""></span>

                <h2>Partenariats</h2>
                <p>Augmentation des patients dans votre structure</p>

              </div>
              <div class="col-md-4">

                <span> <img class="bd-placeholder-img" width="25%" height="80"
                    src="../../../../assets/img/simplification.svg" alt=""></span>
                <h2>Simplification</h2>
                <p>Du suivi des impayés, émission des factures; passerelle avec les assureurs.</p>

              </div>
              <div class="col-md-4">
                <span> <img class="bd-placeholder-img" width="25%" height="80"
                    src="../../../../assets/img/acceleration.svg" alt=""></span>
                <h2>Rapidité</h2>
                <p>Grace au DMP accélérer la prise en charge des partients</p>

              </div>
            </div>





          </div>


        </div>
      </div>
    </div>

  </section>



  <section class="backcolory">
    <div class="container">
      <div class="section-working text-center pl-3">
        <h1 class="text-center mt-3" style="font-weight: bold; font-size: 38px; color:#033B55;">
          La solution de gestion du tiers payant, comment ca marche ?
        </h1>

        <p class=" text-center mt-3 size-20" style="line-height: 25px;">
          Le déploiement de Teliya santé dans votre établissement est une opportunité pour simplifier
           et sécuriser les échanges avec vos partenaires assureurs tout en assurant une prise en charge
            de qualité à vos patients.
        </p>
        <!-- <h2 class="size-30">Teliya santé, comment ça marche ?</h2> -->

        <div class="row mt-3">

          <div class="col-sm-4 mb-4" class="details">
            <h3 class="text-left size-20">Identifiez le patient lors de la consultation</h3>

            <p class="text-left pl-3 size-18">- Connectez-vous depuis votre logiciel; <br>
              - Scanner la carte teliya santé sur le lecteur de carte ; <br>
              - Vérifier l'identité du patient ; <br></p>
          </div>

          <div class="col-sm-4 mb-4" class="details">

            <h3 class="text-left size-20">Consultez et Alimentez son dossier</h3>

            <p class="text-left pl-3 size-18">- Accéder le DMP pour votre consultation ; <br>
              - Alimentez le DMP ; <br>
              - Emettre une ordonnance électronique ; <br></p>
          </div>

          <div class="col-sm-4 mb-4" class="details">
            <h3 class="text-left size-20">Transmission des données</h3>
            <p class="text-left pl-3 size-18">
              - Envoie des fiches de soins ; <br>
              - Transmission des factures ; <br>
            </p>
          </div>

        </div>
      </div>

      <div class="text-center">
        <h2 class="size-30 pt-3" style="font-weight: bold;">Structure de soins</h2>
        <p class="size-20">Le déploiement de Teliya santé en 3 étapes: Simple, Rapide & Gratuit</p>
      </div>
      <div class="row text-center justify-content-center align-items-center">
        <div class="col-lg-4">
          <img src="../../../../assets/img/deploiement1.png" class="bd-placeholder-img" width="120" height="110" alt="">


          <p class="size-18">Remplir et transmettre le formulaire de demande d'adhésion en ligne</p>

        </div><!-- /.col-lg-4 -->
        <div class="col-lg-4">
          <img src="../../../../assets/img/deploiement2.png" class="bd-placeholder-img " width="120" height="110"
            alt="">


          <p class="size-18">Prendre un rendez-vous pour la mise en place du matériel.</p>

        </div><!-- /.col-lg-4 -->
        <div class="col-lg-4">
          <img src="../../../../assets/img/deploiement3.png" class="bd-placeholder-img" width="120" height="110" alt="">


          <p class="size-18">Se former à l'utilisation du logiciel et du lecteur d'empreinte</p>

        </div><!-- /.col-lg-4 -->
        <a routerLink="/adhere" class="btn btn-success p-3 m-3" role="button">Adhérer au réseau de soins</a>
      </div><!-- /.row -->
      <!-- <div class=" row pb-3">

        <div class="col-10 offset-1 ">
          <h1 class="text-center mt-3" style="font-weight: bold; font-size: 38px; color:#033B55;">NOS MEMBRES</h1>

          <p class=" text-center mt-3 size-22" style="line-height: 25px;">
            Retrouvez l'ensemble du réseau de soins Teliya
          </p>

        </div>
        <div class="col-4 offset-4 " style="background-color: #033B55;">
          <ngb-carousel class="mt-3 mb-3">
            <ng-template ngbSlide>
              <div class="picsum-img-wrapper">
                <img src="../../../../assets/img/deploiement1.png" alt="Angular Carousel 1">
              </div>
              <div class="carousel-caption">
                <h3>NSIA ASSURANCE</h3>
              </div>
            </ng-template>
            <ng-template ngbSlide>
              <div class="picsum-img-wrapper">
                <img src="../../../../assets/img/deploiement3.png" alt="Angular Carousel 2">
              </div>
              <div class="carousel-caption">
                <h3>SOGAM</h3>
              </div>
            </ng-template>
          </ngb-carousel>

        </div>
      </div> -->
    </div>

  </section>

</div>
