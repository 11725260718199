<div class="ml-2 mr-2 pt-2 pb-2">
    <h2 mat-dialog-title class="text-center">CONDITIONS GENERALES</h2>
    <div mat-dialog-content>

        <p>
            La carte de santé Teliya est produite par Gamma Concept S.A. société anonyme, immatriculée au registre de commerce et du crédit mobilier da Kaloum sous le numéro RCCM/GC-KAL/05755/2004 dont le siège social est situé à Taouyah, Commune de Ratoma Conakry Guinée.
        </p>
        <p>
            <strong><u>La carte de santé - Teliya :</u></strong>
            La carte de santé est une carte à puce biométrique contenant le Dossier Médical Partagé (DMP), les informations de l’assurance santé avec la possibilité de souscrire à un organisme payeur ainsi qu’un portefeuille électronique dédiés aux soins de santé.
        </p>
        <p>
            <strong><u>Le Dossier Médical Partagé (DMP) :</u></strong>
            Le DMP est un carnet de santé numérique permettant aux professionnels de santé autorisés d’accéder aux données médicales nécessaires à la coordination et à la continuité des soins. Il contient l’historique des soins du patient, ses antécédents médicaux (maladies, allergies, etc.), les comptes rendus d’hospitalisation, ses ordonnances médicales, les résultats de ses examens (radio, analyses biologiques, etc.).
        </p>
        <p>
            <strong><u>Livraison :</u></strong>
            La livraison de la carte elle est faite, a priori, auprès de la structure au sein de laquelle la demande a été faite. Le délai moyen de livraison de la carte de santé Teliya est de 7 jours à partir de la réception de la fiche de renseignements par Teliya. Ce délai ne constituant pas un délai de rigueur, Teliya Santé ne pourra voir sa responsabilité engagée en cas de retard de livraison.
        </p>
        <p>
            <strong><u>Perte ou vol :</u></strong>
            Toute perte ou vol de la carte Teliya Santé doit être signalé dans les 72 heures sur le 625 70 00 00 ou à l’adresse contact@teliya.net. La carte sera bloquée et pourra être rééditée après règlement par le client du tarif de réédition en vigueur au moment de la demande.
        </p>
        <p>
            <strong><u>Protection des données à caractère personnel :</u></strong>
            Teliya Santé s’engage à préserver la confidentialité et la sécurité des données à caractère personnel des utilisateurs de ses produits et services. Les données à caractère personnel collectées par Teliya Santé et ses partenaires (professionnels de santé et assureurs) seront traitées exclusivement à des fins de diagnostic, de suivi médical, et pour le remboursement des frais de santé par les assureurs.
        </p>
        <p>
            Le traitement de données à caractère personnel effectué par Teliya Santé et ses partenaires est en conformité avec les dispositions de la loi L/2016/037/AN du 28 juillet 2016 sur la cyber-sécurité et la protection des données à caractère personnel.
        </p>
        <p>
            A l’exception des professionnels de santé et assureurs membres du réseau de soins Teliya Santé, aucune donnée à caractère personnel ne sera transférée à un tiers qu’il soit basé en Guinée ou à l’étranger. Le décès du titulaire implique la clôture de son DMP.
        </p>
        <p>
            Conformément à la loi L/2016/037/AN du 28 juillet 2016 sur la cyber-sécurité et la protection des données à caractère personnel vous pouvez exercer vos droits d’accès, d’opposition, de rectification et de suppression en vous adressant à contact@teliya.net.
        </p>
        <p>
            Le Titulaire de la carte contrôle l’accès à son DMP par la remise de sa carte Teliya santé et ses empreintes biométriques.
        </p>
        <p>
            Teliya Santé conserve les données à caractère personnel des utilisateurs pour la durée d’utilisation de ses services et ceux de ses partenaires (professionnels de santé et assureurs). Au-delà de la durée de conservation, les données personnelles des utilisations seront archivées puis supprimées après une période de cinq (5) années. Les titulaires peuvent réactiver leur DMP à tout moment avant la suppression totale.
        </p>
        <p>
            <strong>Après avoir pris connaissance des informations et conditions citées ci-dessus, j'autorise Teliya santé à faire la collecte et le traitement de mes données personnelles.</strong>
        </p>
    </div>
    <div mat-dialog-actions align="end">
        <button class="mat-raised-button" color="accent" (click)="close()">Fermer</button>
    </div>
</div>
