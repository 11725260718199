import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  jwt: any;
  headers: any;


  constructor(private http: HttpClient, private global: GlobalService) {
    this.jwt = localStorage.getItem('token');
    this.headers = new HttpHeaders({
      'Context-Type': 'application/json',
      Authorization: 'Bearer ' + this.jwt,
    });
  }

  getProduct(product) {
    let prod = {
      name: product,
    };
    return this.http.post(this.global.host + 'get-product', prod, {
      headers: this.headers,
    });
  }
  saveOrder(data) {
    return this.http.post(this.global.host + 'saveorder', data, {
      headers: this.headers,
    });
  }

  addRecharge(data) {
    return this.http.post(this.global.host + 'addRecharge', data, {
      headers: this.headers,
    });
  }

  initPayment(order) {
    return this.http.post(this.global.host + 'startpayment', order);
  }
  checkOmTransactionStatus(ref_order) {
    return this.http.post(
      this.global.host + 'checkOmTransactionStatus',
      ref_order
    );
  }

  getCardGrabPoints(is_grab_point: boolean) {
    return this.http.get(this.global.host2 + 'card-grab-points?is_grab_point='+ is_grab_point);
  }
  getCardGrabPointsCg(is_grab_point: boolean) {
    return this.http.get(this.global.urlCg + 'card-grab-points?is_grab_point='+ is_grab_point);
  }

  verifyOrigin(ref_order) {
    let orderref = {
      ref_order: ref_order,
    };
    return this.http.post(this.global.host + 'verifyOrigin', orderref);
  }

  getStoredData(ref_order) {
    return this.http.post(
      this.global.host + 'people-order-stored-data',
      ref_order
    );
  }
  getStoredInsuranceData(ref_insurance) {
    return this.http.post(
      this.global.host + 'people-insurance-stored-data',
      ref_insurance
    );
  }

  sendFinalOrderMail(email: any, message: any, donnees) {
    let data ={
      "email" : email,
      "message" : message,
      "data" : donnees
    }
    return this.http.post(
      this.global.host + 'send-order-final-mail',
      data
    );
  }
  sendFinalInsuranceMail(email: any, message: any, donnees) {
    let data ={
      "email" : email,
      "message" : message,
      "data" : donnees
    }
    return this.http.post(
      this.global.host + 'send-insurance-final-mail',
      data
    );
  }

  editOrder(data) {}

  saveadhesion(data) {
    return this.http.post(this.global.host + 'save_adhesion', data);
  }

  sendingMail(data) {
    return this.http.post(this.global.host + 'send-email', data);
  }

  checkInsured(numero: any) {
    return this.http.post(this.global.host2 + 'auth/card/verify', numero);
  }

  visadata(params: any) {
    return this.http.post(this.global.host + 'visadata', params);
  }
  sendInsuranceRequest(insurance, person, family, medicalRecords) {
    let data= {
      insurance: insurance,
      person: person,
      family: family,
      medical: medicalRecords,
      from: "website"
    }
    return this.http.post(this.global.host + 'apply-for-health-insurance', data);
  }
  getPartners() {
    return this.http.get(this.global.host2 + 'card-grab-points');
  }
}
