import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from "@auth0/angular-jwt";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  host:string ="http://127.0.0.1:8000/api/auth";
  jwt: string;
  username: string;
  first_name: string;
  last_name: string;
  email: string;
  //roles: any;
  roles: Array<string>;


  constructor(private http: HttpClient) {

   }

  login(data){
    return this.http.post(this.host+'/login', data);
  }

  register(data){
    return this.http.post(this.host+'/register', data);
  }

  logout(){
    let headers = new HttpHeaders({'Authorization': 'Bearer ' +this.jwt});
    this.http.get(this.host+'/logout', {headers: headers} );
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    this.jwt = undefined;
    this.roles = undefined;  
    this.username = undefined;
    this.first_name = undefined;
    this.last_name = undefined;
    this.email = undefined;
  }

  saveJwtToken(jwt: string){
    localStorage.setItem('token', jwt);
    this.jwt = jwt;
  
  }



  loardJwtToken() {
    this.jwt = localStorage.getItem('token');
    this.last_name = localStorage.getItem('username');
  }

public currentUser(){
  let token = localStorage.getItem('token');
  if(!token)
    return null

  return localStorage.getItem('username');
}

  isLoggedIn(){
   
    const helper = new JwtHelperService();
    let token = localStorage.getItem('token');
    
    if(!token)
      return false;

    let expireDate = helper.getTokenExpirationDate(token);
    let isExp = helper.isTokenExpired(token);

    return !isExp;
  }


  saveOrder(){

  }


}
