import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css']
})
export class LoadingComponent implements OnInit {

  show: boolean;

  constructor( private svcGlobal: GlobalService) { }

  ngOnInit(): void {

    this.svcGlobal.showLoad.subscribe(
      (val: boolean) => {
        this.show = val;
      }
    );
  }

}
