<div class="page-title-overlap stylish-color-dark pt-4 posi" >
    <div class="container d-lg-flex justify-content-between">
      <div class="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center justify-content-lg-start">
            <li class="breadcrumb-item"><a class="text-nowrap"routerLink="/home"><i class="fa fa-home"></i>Acceuil</a></li>
            <li class="breadcrumb-item text-nowrap active" aria-current="page">Professionnels de santé</li>
            <li class="breadcrumb-item text-nowrap active" aria-current="page">Entre vous et nous</li>
          </ol>
        </nav>
      </div>
      <div class="order-lg-1 pr-lg-4 text-center text-lg-left">
        <h1 class="h3 text-light mb-0">Entre vous et nous</h1>
      </div>
    </div>
</div>

<section class="backcolor">

  <div class="container mt-5">
    <h2 class="size-30 pt-5">Mieux connaitre le DMP</h2>
    <p class="size-18">Le Dossier Médical Partagé (DMP) est un service qui vous permet d'accéder simplement et rapidement aux données de santé de votre patiant : traitements, antécédents, etc. C'est un résumé du parcours patients qui consttitue un outil essentiel à la coordination des professionnels de santé. </p>
    <p class="size-18">Concrètement il arrive très souvent que des patients viennent sans leurs prescriptions en cours, le fait d'avoir un accès au DMP permet d'avoir accès à la fois à ses pathologies mais aussi à leurs traitements et à leur historique de remboursement, ce qui permet d'éviter des redondances thérapeutiques ou des infractions médicamenteuses qui peuvent être assez fréquentes.</p>
    <p class="size-18">L'accès au DMP est très simple, il se fait à travers le logiciel Téliya santé, il est sécurisé par la carte biométrique détenu par le patient. En une connection, en quelques secondes on a accès à son DMP. C'est un vrai gain de temps.</p>


      <section class="m-5">
        <h2 class="text-center size-22">Les avantages du DMP</h2>

        <div class="row text-center justify-content-center align-items-center">
          <div class="col-lg-4">
            <img class="bd-placeholder-img rounded-circle" width="140" height="140" src="../../../../assets/img/dmp2.jpeg" alt="">
            <!-- <svg class="bd-placeholder-img rounded-circle" width="140" height="140" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: 140x140" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#777"/><text x="50%" y="50%" fill="#777" dy=".3em">140x140</text></svg> -->
            <h2 class="font-weight-bold size-20">Un suivit médical plus effice</h2>

          </div><!-- /.col-lg-4 -->
          <div class="col-lg-4">
            <img class="bd-placeholder-img rounded-circle" width="140" height="140" src="../../../../assets/img/dmp1.jpeg" alt="">
            <!-- <svg class="bd-placeholder-img rounded-circle" width="140" height="140" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: 140x140" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#777"/><text x="50%" y="50%" fill="#777" dy=".3em">140x140</text></svg> -->

            <h2 class="font-weight-bold size-20">Des soins coordonnées simplifiés</h2>

          </div><!-- /.col-lg-4 -->
          <div class="col-lg-4">
            <img class="bd-placeholder-img rounded-circle" width="140" height="140" src="../../../../assets/img/dmp3.jpeg" alt="">
            <!-- <svg class="bd-placeholder-img rounded-circle" width="140" height="140" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: 140x140" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#777"/><text x="50%" y="50%" fill="#777" dy=".3em">140x140</text></svg> -->

            <h2 class="font-weight-bold size-20">Une aide au diagnostic</h2>


          </div><!-- /.col-lg-4 -->
        </div><!-- /.row -->

      </section>

    <div class="section-working text-center mt-3">
      <h2 class="size-30">Téliya santé, comment ça marche ?</h2>

      <div class="row mt-3">

        <div class="col-sm-4 mb-4">
          <h3 class="text-left size-17">Identifiez le patient lors de la consultation</h3>

          <p class="text-left pl-3 size-15">- Connectez-vous depuis votre logiciel; <br>
          - Scanner la carte teliya santé sur le lecteur de carte ; <br>
        - Vérifier l'identité du patient ; <br></p>
        </div>

        <div class="col-sm-4 mb-4">

          <h3 class="text-left size-17">Consultez et Alimentez son dossier</h3>

          <p class="text-left pl-3 size-15">- Accéder le DMP pour votre consultation ; <br>
          - Alimentez le DMP ; <br>
        - Emettre une ordonnance électronique ; <br></p>
        </div>

        <div class="col-sm-4 mb-4 ">
          <h3 class="text-left size-17">Envoyez la fiche de soins</h3>

          <!-- <p class="text-left pl-3 size-15">- Connectez-vous depuis votre logiciel; <br>
          - Scanner la carte teliya santé sur le lecteur de carte ; <br>
        - Vérifier l'identité du patient ; <br></p> -->
        </div>

      </div>

      <hr class="my-5">
      <div class="section-temoin">

        <h1 class="font-weight-bold text-center my-5">Ils l'utilisent, ce qu'ils en disent</h1>

        <div class="row featurette">
          <div class="col-md-7 order-md-2">
            <h2 class="featurette-heading">Témoignages</h2>
          </div>
          <div class="col-md-5 order-md-1">
            <img src="../../../../assets/img/img-slide-2.png" class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" width="500" height="500" alt="">

          </div>
        </div>



      </div>

      <hr class="my-5">

      <div class="partenair">
        <h1 class="font-weight-bold text-center my-5 text-center">Nos partenaires</h1>
        <div class="row text-center justify-content-center align-items-center">
          <div class="col-sm-4  mb-4">
            <div class="card" style="width: 18rem;">
              <img src="../../../../assets/img/logoSOGAM.png"  alt="SOGAM">


            </div>
          </div>
          <div class="col-sm-4 mb-4">
            <div class="card" style="width: 18rem;">
              <img src="../../../../assets/img/nsia.jpeg"  alt="NSIA">

            </div>
          </div>

          <div class="col-sm-4 mb-4">
            <div class="card" style="width: 18rem;">
              <img src="../../../../assets/img/logopremium.png" alt="PREMIUM">

            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</section>

