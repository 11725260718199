<div class="page-title-overlap stylish-color-dark pt-4 posi">
  <div class="container d-lg-flex justify-content-between">
    <div class="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center justify-content-lg-start">
          <li class="breadcrumb-item"><a class="text-nowrap" routerLink="/home"><i class="fa fa-home"></i>Accueil</a>
          </li>
          <li class="breadcrumb-item text-nowrap active" aria-current="page">Commande</li>
          <li class="breadcrumb-item text-nowrap active" aria-current="page">Status</li>
        </ol>
      </nav>
    </div>
    <div class="order-lg-1 pr-lg-4 text-center text-lg-left">
      <h1 class="h3 text-light mb-0">Statut de Paiement</h1>
    </div>
  </div>
</div>

<section class="backcolor">

  <div class="container pb-5 mt-5">
    <div class="row">
      <div class="pull-right" *ngIf="ref_order">
        <!-- text-white bg-success -->
        <p > REF: {{ref_order}} </p>
      </div>
    </div>
    <div class="row bg-white"
      *ngIf=" (!invalidReference && payment_details && payment_details?.status_pay!==null && payment_details?.status_pay !='SUCCESS'
       && payment_details?.status_pay !='ACCEPT') || payment_details?.status_pay===null"
    >
      <div class="col-8 offset-2" *ngIf="!invalidReference && showResult">
        <h1 class="text-center mt-3 mb-3 text-danger ">Désolé! Le paiement n'a pas été effectué.</h1>
        <p
          class="text-center"
          *ngIf="payment_details?.status_pay ==='DECLINE'
            || payment_details?.status_pay ==='CANCEL'
            || payment_details?.status_pay ==='ERROR'"
        >
          <strong>Raison: </strong>
          <span *ngIf="payment_details?.status_pay ==='DECLINE'">
            les informations de la carte VISA sont incorrectes.
          </span>
          <span *ngIf="payment_details?.status_pay ==='CANCEL'">
            vous avez annulé le paiement.
          </span>
          <span *ngIf="payment_details?.status_pay ==='ERROR'">
            une erreur s'est produite lors du paiement.
          </span>
        </p>
        <p class="text-center">
          Rendez-vous à <strong>{{ payment_details?.grab_point }}</strong> pour finaliser votre  commande et effectuer le paiement. <br>
          Votre numéro de commande est le <strong>{{ payment_details?. transaction_number}}</strong>. Il sera utilisé pour la suite du processus.
        </p>
      </div>
    </div>
    <div class="row bg-white" *ngIf="invalidReference && showResult">
      <div class="col-8 offset-2">
        <h1 class="text-center mt-3 mb-3 text-danger ">Désolé, cette référence ne correspond à aucune transaction</h1>
      </div>
    </div>
    <div class="row"
      *ngIf="payment_details && (payment_details?.status_pay =='SUCCESS' || payment_details?.status_pay =='ACCEPT')">
      <div class="col-8 offset-2" *ngIf="showResult">
        <h1 class="text-center mt-3 mb-3 text-success ">Félicitations! Paiement effectué.</h1>
        <p *ngIf="payment_details?.grab_point && payment_details?.quantite">
          Votre commande a été prise en compte. Teliya Santé vous remercie et vous appellera dans un bref délai pour
          vous faire part de la disponibilité de votre carte de Santé. <br>
          <strong>Votre Santé, Notre priorité!</strong>
        </p>
        <p *ngIf="payment_details?.grab_point && !payment_details?.quantite">
          Votre demande d'assurance a été prise en compte. Teliya Santé vous remercie et vous appellera dans un bref délai pour
          vous faire part de la disponibilité de votre carte de Santé. <br>
          <strong>Votre Santé, Notre priorité!</strong>
        </p>
      </div>
    </div>
    <div class="row"
      *ngIf="payment_details && (payment_details?.status_pay =='SUCCESS' || payment_details?.status_pay =='ACCEPT')"
    >
      <div class="col-md-5 col-sm-5 offset-md-2 col-xs-12 pt-5"
        *ngIf="(payment_details?.grab_point || payment_details?.quantite) && showResult">
        <div>
          <div class="card">
            <h1 class="card-header text-center">Détails de la transaction</h1>
            <div class="card-body">
              <p class="card-text">Moyen de paiement : {{moyenPaiement}}</p>
              <p class="card-text" *ngIf="payment_details?.txn_id != null">ID Transaction : {{payment_details?.txn_id}}
              </p>
              <p class="card-text">Montant : {{payment_details?.montant}} GNF</p>
              <p class="card-text">Statut : {{payment_details?.status}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-sm-6 offset-md-3 col-xs-12 pt-5"
        *ngIf="(!payment_details?.grab_point && !payment_details?.quantite) && showResult">
        <div>
          <div class="card">
            <h1 class="card-header text-center">Détails de la transaction</h1>
            <div class="card-body">
              <p class="card-text">Moyen de paiement : {{moyenPaiement}} </p>
              <p class="card-text" *ngIf="payment_details?.txn_id != null">ID Transaction : {{payment_details?.txn_id}}
              </p>
              <p class="card-text">Montant : {{payment_details?.montant}} GNF</p>
              <p class="card-text">Statut : {{payment_details?.status}}</p>
              <strong class="float-right">Nouveau solde: {{payment_details?.montant+payment_details?.old_amount}} GNF
              </strong>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-sm-4 col-xs-12 pt-5" *ngIf="(payment_details?.status_pay =='SUCCESS' || payment_details?.status_pay =='ACCEPT') && showResult">
        <div class="card" *ngIf="payment_details?.grab_point">
          <h4 class="card-header text-center">Lieu de retrait de la carte</h4>
          <div class="card-body">
            <h2 class="card-text text-center">{{payment_details?.grab_point}}</h2>
          </div>
        </div>
      </div>
    </div>

  </div>
</section>
