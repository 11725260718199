<div>
    <div class="add-title">
      <h2 class="primary">Changer le mot de passe</h2>
    </div>
    <div>
      <form [formGroup]="addCusForm"> 
      
                <mat-form-field>
                    <input matInput type="password" placeholder="Mot de passe courant" formControlName="holdPassword" required>
                </mat-form-field>
         
              <mat-form-field >
                <input matInput type="password" placeholder="Nouveau mot de passe" formControlName="newPassword" required>               
              </mat-form-field>
       
            <mat-form-field >
              <input matInput type="password" placeholder="Confirmation" formControlName="confPassword" required>
            </mat-form-field>
      
        <div class="btn-sec">
          <button mat-raised-button type="submit" color="primary" class="mr-3" >Enregister</button>
          <button mat-raised-button type="button" color="warn"  mat-dialog-close>Annuler</button>
        </div>
  
      </form>
    </div>
  </div>
  