import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderService } from 'src/app/services/order.service';
import { UUID } from 'angular2-uuid';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EditDataComponent } from '../edit-data/edit-data.component';
import { GlobalService } from 'src/app/services/global.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { DateAdapter } from '@angular/material/core';
import { OrderConditionsComponent } from 'src/app/pages/order-conditions/order-conditions.component';
import { MatCheckboxChange } from '@angular/material/checkbox';
import Swal from 'sweetalert2';
declare var $;


interface Country {
  name: string;
  dial_code: string;
  code: string;
}


export class MyTel {
  constructor(
    public area: string,
    public exchange: string,
    public subscriber: string,
  ) { }
}


@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css'],
  providers: [DatePipe]
})
export class CheckoutComponent implements OnInit {
  @ViewChild('stepper', { static: false }) stepper: MatStepper;

  public loading = false;
  imageUrl: string = null;
  defaultIndice = 0;
  transaction_number: string = null;
  base64imageUrl: string = null;
  ref_order: any = null;
  all_stored_people: any;
  defaultIndex: any = 0;
  saved: boolean = false;
  stored_order: any;
  stored_people: any;
  stored_ctry;
  resumeOrder: boolean = false;
  allowPayment: boolean = false;
  showPaymentStep: boolean = false;
  cashPayment: boolean = false;
  addedMainPerson: boolean = false;
  showSizeError: boolean = false;
  alreadyPayed: boolean = false;
  montant = 0;
  om_order: any;
  stored_phone_number: any;
  stored_grabPoint;
  grabPoints: any;
  place: any = {};
  isLinear = true;
  // firstFormGroup: FormGroup;
  // secondFormGroup: FormGroup;
  // thirstFormGroup: FormGroup;
  // endFormGroup: FormGroup;

  firstFormGroup = new FormGroup({
    countryCtrl: new FormControl('', Validators.required)
  });
  secondFormGroup = new FormGroup({
    firstCtrl: new FormControl('', [Validators.required, Validators.maxLength(100)]),
    lastCtrl: new FormControl('', [Validators.required, Validators.maxLength(100)]),
    emailCtrl: new FormControl('', [Validators.email, Validators.required]),
    phoneCtrl: new FormControl('', Validators.required),
    photoCtrl: new FormControl(''),
    photoCtrl2: new FormControl(''),
    genderCtrl: new FormControl('', Validators.required),
    matrimonialCtrl: new FormControl(null),
    birthdayCtrl: new FormControl('', [Validators.required,]),
    addressCtrl: new FormControl('', [Validators.maxLength(200)]),

  });
  grabFormGroup = new FormGroup({
    grabPoint: new FormControl('', Validators.required)
  });


  thirstFormGroup = new FormGroup({
    gsanginCtrl: new FormControl(''),
    tensionCtrl: new FormControl(''),
    diabeteCtrl: new FormControl(''),
    hepatieCtrl: new FormControl(''),
    odiseasesCtrl: new FormControl(''),
    allergyCtrl: new FormControl('')
  });


  ratioGroup: FormGroup;
  insuranceFormGroup: FormGroup;
  stevy: boolean = false;
  choice_de: boolean = true;
  choice_one: boolean = false;
  select_insurance: boolean = false;
  add_medical_record: boolean = false;
  quest: any;
  currentyInsurance: any;
  data_: any = {};
  currentCountry: any;
  clients: any[] = [];
  nbre_de_carte: any;
  add: boolean = false;
  end: boolean = false;
  seen: boolean = false;
  productPrice: any;
  private productName: string = "Carte de Santé Teliya";
  order: any[] = [];
  todayDate = new Date();
  maxDate: string;
  savedOrder: any;
  orderId: number = 0;
  api_token: any = [];


  // @Output()
  // selectionChange: EventEmitter<MatSelectChange>

  @Output()
  // change: EventEmitter<MatRadioChange>

  GS: any = [
    { value: "A+" },
    { value: "A-" },
    { value: "B+" },
    { value: "B-" },
    { value: "AB+" },
    { value: "AB-" },
    { value: "O+" },
    { value: "O-" }

  ];

  // INSURANCE: any = [
  //   { value: "SOGAM" },
  //   { value: "UGAR" },
  //   { value: "NSIA" },
  //   { value: "LALA NAH" },
  //   { value: "PREMIUM" }
  // ]

  HE: any = [
    { value: "A" },
    { value: "B" },
    { value: "C" },
    { value: "D" },
    { value: "E" },
    { value: "Aucun" }
  ];

  Genre: any = [
    { value: "Homme" },
    { value: "Femme" }
  ];
  Matrimonial: any = [
    { value: "Marié(e)" },
    { value: "Célibataire" }
  ];

  maxAvailableDate: Date;

  code: any;
  endSave: boolean = true;
  review: boolean = false;

photo: string = null;
  constructor(private _formBuilder: FormBuilder,
    private OrderService: OrderService,
    private datePipe: DatePipe,
    private matDialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private dateAdapter: DateAdapter<Date>,
    private globalSvc: GlobalService,
    private router: Router,
  ) {
    this.globalSvc.setTitle('Je commande');
    this.dateAdapter.setLocale('fr');
  }



  async ngOnInit() {
    this.globalSvc.fixDisplay();
    this.setMaxAvailableDate();
    this.getProduct(this.productName);
    this.getcardGrabPoints();
    if (this.activatedRoute.snapshot.params.id) {
      this.ref_order = this.activatedRoute.snapshot.params.id;
      this.getStoredData(this.ref_order);
      this.defaultIndex = this.add_medical_record ? 3 : 2;

    }




    this.data_.clients = [];
    this.nbre_de_carte = this.clients.length ? this.clients.length : 0;

    this.ratioGroup = this._formBuilder.group({
    });
  }
  getProduct(product) {
    this.OrderService.getProduct(product).subscribe(resp => {
      console.log(resp);
      if (resp['product'] !== null) {
        let prod = resp['product'];
        this.productPrice = prod.price;
        console.log(this.productPrice);
      }
    },
      async error => {
        await this.toastr.error(
          '',
          "Impossible de charger les données. Veuillez réessayer",
          {
            timeOut: 4000,
            tapToDismiss: true,
            progressBar: true,
          }
        );
        console.log(error)
      }
    );
  }

  selectChangeHandleCountry(event) {
    this.code = event.dial_code;
    this.currentCountry = event.name;
  }

  setMaxAvailableDate() {
    this.maxDate = this.datePipe.transform(this.todayDate, 'MM/dd/yyyy');
    const currentYear = new Date().getFullYear();
    const currentMonth = +this.maxDate.substring(0, 2);
    const currentDay = +this.maxDate.substring(3, 5);
    this.maxAvailableDate = new Date(currentYear, currentMonth - 1, currentDay);
  }

  onSaveOrder(values?: any) {
    if (this.ref_order) {
      this.add = false;
      this.end = true;
      this.review = true;
      this.isLinear = false;
      this.showPaymentStep = true;
      this.endSave = false;
      for (let person of this.all_stored_people) {
        const order = {
          index: this.clients.length ? this.clients.length : 0,
          id: person.id,
          country: person.country,
          first_name: person.first_name,
          last_name: person.last_name,
          email: person.email,
          tel: person.phone,
          genre: person.gender,
          birthday: person.birth_day,
          address: person.address,
          is_selected: person.is_selected,
          matrimonial: person.matrimonial,
          grab_point: person.grab_point,
          dateCommand: new Date()

        }
        this.saved = true;
        console.log(order)
        this.clients.push(order);
      }
    } else {
      this.add = true;
      this.end = true;
      this.endSave = false;

      const order = {

        index: this.clients.length ? this.clients.length : 0,
        country: this.currentCountry,
        first_name: this.secondFormGroup.get('firstCtrl').value,
        last_name: this.secondFormGroup.get('lastCtrl').value,
        email: this.secondFormGroup.get('emailCtrl').value,
        tel: this.secondFormGroup.get('phoneCtrl').value,
        genre: this.secondFormGroup.get('genderCtrl').value,
        photo: this.secondFormGroup.get('photoCtrl').value,
        photoBase64: this.photo,
        is_selected: (this.clients.length === 0 )? true : false,
        matrimonial: this.secondFormGroup.get('matrimonialCtrl').value,
        birthday: this.secondFormGroup.get('birthdayCtrl').value,
        address: this.secondFormGroup.get('addressCtrl').value,
        grab_point: this.grabFormGroup.get('grabPoint').value,
        g_sanguin: this.thirstFormGroup.get('gsanginCtrl').value,
        tensionCtrl: this.thirstFormGroup.get('tensionCtrl').value,
        diabeteCtrl: this.thirstFormGroup.get('diabeteCtrl').value,
        hepatie: this.thirstFormGroup.get('hepatieCtrl').value,
        other_maladie: this.thirstFormGroup.get('odiseasesCtrl').value,
        allergie: this.thirstFormGroup.get('allergyCtrl').value,
        imageUrl: this.imageUrl,
        dateCommand: new Date()
      }
      this.saved = false;

      console.log(order)
      this.clients.push(order);
      this.secondFormGroup.reset();
      this.imageUrl = null;
      this.photo = null;
      // console.log('====================================');
      // console.log(this.clients);
      //console.log('====================================');
      //this.stepper.reset();

    }
  }

  addCart() {
    this.stepper.reset();
    this.add = false;
    this.endSave = true;
    this.seen = false;
    this.add_medical_record = false;
    this.addedMainPerson = true;
  }

  saveOrder() {
    // this.loading = true;
    this.globalSvc.showNgxLoader();
    this.globalSvc.fixDisplay();
    this.saved = true;
    this.add = false;
    this.clients[0].grab_point = this.grabFormGroup.get('grabPoint').value;
    this.data_.clients = this.clients;
    // this.data_.montant = this.clients.length * 1000;
    console.log(this.clients);
    console.log(this.productPrice);

    this.data_.montant = this.clients.length * this.productPrice;
    this.data_.quantity = this.clients.length;
    this.data_.grab_point = this.clients[0].grab_point;
    this.data_.from = "website";
    if (this.ref_order) {
      this.data_.ref = this.ref_order;
    }
    this.data_.date = new Date();
    console.log(this.data_);

    this.OrderService.saveOrder(this.data_).subscribe(
      (res) => {
        if (res['data']) {
          this.showPaymentStep = true;
          console.log(res);
          this.savedOrder = res['data'];
          this.transaction_number = this.savedOrder.transaction_number;
          this.saved = true;
          this.review = true;
        } else {

        }
        // this.loading = false;
        this.globalSvc.spinner.hide();

        //console.log(this.savedOrder);
      }, async err => {
        console.log(err)
        this.saved = false;
        this.showPaymentStep = false;
        // this.loading = false
        this.globalSvc.spinner.hide();
        await this.toastr.error(
          '',
          "Oops! Une erreur est survenue, veuillez réessayer",
          {
            timeOut: 4000,
            tapToDismiss: true,
            progressBar: true,
          }
        );
      }
    );
  }
  clickEventHandler(ev) {
    this.select_insurance = true;
    this.stevy = true;
  }


  insuranceChange(ins) {
    this.currentyInsurance = ins;
    // console.log(this.currentyInsurance);
  }



  onChoiceTensionChange(event) {

  }

  onChoiceDiabeteChange(event) {

  }

  onChoiceGenreChange(event) {

  }

  public payement() {
    if (this.ref_order) {
      this.om_order = {
        montant: this.data_.montant,
        order_id: this.ref_order,
        person: this.clients[0] ? this.clients[0] : null
      }
    } else {
      this.om_order = {
        montant: this.savedOrder.montant,
        order_id: this.savedOrder.ref_order,
        person: this.clients[0] ? this.clients[0] : null
      }
    }
    // this.loading = true;

    this.globalSvc.showNgxLoader();
    this.OrderService.initPayment(this.om_order).subscribe(async resp => {
      console.log(resp);
      if (resp['data'] !== "FAILED") {
        window.location.href = resp['data'];
      } else {
        await this.toastr.warning(
          '',
          "Echec de paiement. Veuillez réessayer!",
          {
            timeOut: 4000,
            tapToDismiss: true,
            progressBar: false,
          }
        );

        this.globalSvc.spinner.hide();
      }

    }, async error => {
      // this.loading = false;
      this.globalSvc.spinner.hide();
      await this.toastr.error(
        '',
        "Oops! Une erreur est survenue, veuillez réessayer",
        {
          timeOut: 4000,
          tapToDismiss: true,
          progressBar: true,
        }
      );
      console.log(error)
    });

  }

  public update(index, item) {



    const dialogConfig = new MatDialogConfig();
    // The user can't close the dialog by clicking outside its body
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      id: 1,
      title: "Edition"
    }
    dialogConfig.height = "350px";
    dialogConfig.width = "600px";
    dialogConfig.autoFocus = true;
    dialogConfig.data = item

    const dialogRef = this.matDialog.open(EditDataComponent, dialogConfig);


    // dialogRef.afterClosed().subscribe( result => {

    // });


    dialogRef.afterClosed().subscribe(
      data => {
        // console.log(data);
        // console.log(this.clients);

        // this.clients.map(cl => {
        //   console.log(cl.index == data.index);

        //   if(cl.index == data.index) {
        //     console.log(cl);
        //     cl = null
        //     // cl = data;
        //     console.log('trouve');

        //   }
        // });

        if(data){
          this.clients[data.index] = data;
        }

        // console.log(this.clients);
        // this.saveOrder();

        // this.clients=data;
      }

    );

  }

  payementVisa() {
    this.globalSvc.showNgxLoader();
    // window.location.href ='https://visa.teliya.net/sa-wm/payment_form.php?data=' + this.savedOrder.ref_order;
    let paramss = {
      // profile_id : "C05EF21D-A208-4ED9-B5A1-E62679B87612", // dev
      profile_id: "3DC5E00C-E138-4C24-B706-8E99B33B9D1B", //prod
      // access_key : "1f9d96dcbcbd39599d9a7e3bedf35747",  // dev
      access_key: "f98f9065463735f9bae5c69b481d9733", // prod
      transaction_uuid: "61042ddd80f59",
      signed_date_time: "2021-07-30T16:50:37Z",
      signed_field_names: "profile_id,access_key,transaction_uuid,signed_field_names,unsigned_field_names,signed_date_time,locale,transaction_type,reference_number,auth_trans_ref_no,amount,currency,merchant_descriptor,override_custom_cancel_page,override_custom_receipt_page",
      unsigned_field_names: "signature,bill_to_forename,bill_to_surname,bill_to_email,bill_to_phone,bill_to_address_line1,bill_to_address_line2,bill_to_address_city,bill_to_address_state,bill_to_address_country,bill_to_address_postal_code,customer_ip_address,merchant_defined_data1,merchant_defined_data2,merchant_defined_data3,merchant_defined_data4",
      transaction_type: "sale",
      reference_number: this.savedOrder.ref_order,
      auth_trans_ref_no: this.savedOrder.ref_order,
      amount: this.savedOrder.montant,
      // currency: "GHS", //dev
      currency: "GNF", // prod
      locale: "en-us",
      merchant_descriptor: "Swen",
      bill_to_forename: "Noreal",
      bill_to_surname: "Name",
      bill_to_email: "null@cybersource.com",
      bill_to_phone: "+662-2962-000",
      bill_to_address_line1: "1295 Charleston Rd",
      bill_to_address_line2: "1295 Charleston Rd",
      bill_to_address_city: "Mountain View",
      bill_to_address_state: "CA",
      bill_to_address_country: "US",
      bill_to_address_postal_code: "94043",
      // override_custom_cancel_page : "http://localhost/cybersource_secure_acceptance_UAT_Swen/php/sa-wm/customresponse.php", // dev
      // override_custom_receipt_page : "http://localhost/cybersource_secure_acceptance_UAT_Swen/php/sa-wm/customresponse.php", // dev
      override_custom_cancel_page: this.globalSvc.eco_visa_notif_url,  // prod
      override_custom_receipt_page: this.globalSvc.eco_visa_notif_url, // prod
      customer_ip_address: "::1",
      line_item_count: "2",
      merchant_defined_data1: "MDD#1",
      merchant_defined_data2: "MDD#2",
      merchant_defined_data3: "MDD#3",
      merchant_defined_data4: "MDD#4",
      signature: "uvSvinvz2Q9a+CoYdBGOUNTn9bv1U0lOD98kIXjR0Ws=",

      // données pour la notification par mail de teliya admin
      transaction_number: this.transaction_number,
      quantity: this.clients.length,
      montant: this.productPrice,
      person: this.clients[0].first_name+" "+this.clients[0].last_name,
      email: this.clients[0].email,
      phone: this.clients[0].tel,
      grab_point: this.savedOrder.grab_point,
      isOrder: true

    }

    this.OrderService.visadata(paramss).subscribe(resp => {
      // console.log(resp);
      paramss.transaction_uuid = resp['uuid'];
      paramss.signed_date_time = resp['datetime'];
      paramss.signature = resp['signature'];
      // this.paramss.reference_number = resp['ref'];
      // this.paramss.auth_trans_ref_no = resp['auth'];
      console.log(paramss);
      $.redirect("https://secureacceptance.cybersource.com/pay", paramss, 'POST');
      this.globalSvc.spinner.hide();
    }, async error => {
      console.log(error);
      this.globalSvc.spinner.hide();
    });
  }

  async getStoredData(ref_order) {
    let data = {
      ref_order: ref_order
    };
    // this.loading = true;
    this.globalSvc.showNgxLoader();
    this.OrderService.getStoredData(data).subscribe(
      (res) => {
        console.log(res['data']);
        if (res['data'].length > 0) {
          this.stored_order = res['data'];

          this.transaction_number = this.stored_order[0].transaction_number;
          // console.log(this.stored_order[0].transaction_number);
          this.alreadyPayed = (this.stored_order[0].status_pay === "SUCCESS" || this.stored_order[0].status_pay === "ACCEPT")? true : false;
          this.ref_order = this.stored_order[0].ref_order;
          this.savedOrder = this.stored_order[0];
          this.stored_people = res['data'][0].people[0];
          this.all_stored_people = res['data'][0].people;
          this.onSaveOrder(this.all_stored_people);

          // console.log(this.stored_order);
          // console.log(this.stored_people.last_name);
          // console.log(this.stored_order[0].grab_point);
          // this.stored_ctry = this.Country.find(({ name }) => name === this.stored_people.country);
          // if (this.stored_ctry) {
          //   this.code = this.stored_ctry.dial_code;
          //   this.currentCountry = this.stored_ctry.name;
          //   if (this.stored_people.phone) {
          //     this.stored_phone_number = this.stored_people.phone.substring(this.code.length, this.stored_people.phone.length);
          //     // console.log(this.stored_phone_number);
          //   }
          // }else{
          // }
          this.stored_phone_number = this.stored_people.phone;
          this.stored_grabPoint = this.stored_order[0]?.grab_point;
          // console.log(this.stored_grabPoint);

          // this.firstFormGroup = new FormGroup({
          //   countryCtrl: new FormControl(this.stored_ctry)
          // });
          this.secondFormGroup = new FormGroup({
            firstCtrl: new FormControl(this.stored_people.first_name),
            lastCtrl: new FormControl(this.stored_people.last_name),
            emailCtrl: new FormControl(this.stored_people.email),
            phoneCtrl: new FormControl(this.stored_phone_number),
            genderCtrl: new FormControl(this.stored_people.gender),
            birthdayCtrl: new FormControl(this.stored_people.birth_day),
            addressCtrl: new FormControl(this.stored_people.address),

          });
          this.grabFormGroup = new FormGroup({
            grabPoint: new FormControl(this.stored_grabPoint.name)
          });
        } else {

        }
        // this.loading = false;
        this.globalSvc.spinner.hide();
      },
      (error) => {
        // this.loading = false;
        this.globalSvc.spinner.hide();
        // console.log(error);
      }
    );
  }
  getcardGrabPoints() {
    let isgrabpoint = true;
    this.OrderService.getCardGrabPoints(isgrabpoint).subscribe(
      (res) => {
        this.grabPoints = res['data'];
        // console.log(res['data']);
      },
      async (error) => {
        // console.log(error);
          await this.toastr.error(
            '',
            "Impossible de récupérer les points de retrait. Veuillez réessayer",
            {
              timeOut: 4000,
              tapToDismiss: true,
              progressBar: true,
            }
          );
          console.log(error)
      }
    );
  }
  showSpinner() {
    this.loading = true;
    setTimeout(() => { this.loading = false; }, 30000);
  }

  resetForms() {
    Swal.fire({
      title: 'Annulation de commande',
      text: 'Voulez vous annuler votre commande',
      position: 'center',
      showCancelButton: true,
      // cancelButtonColor: '#c7181e',
      cancelButtonText: 'Non',
      confirmButtonText: 'Oui',
      confirmButtonColor: '#c7181e',
      icon: 'question'
    }).then((result)=>{
        if(result.value){
          // console.log("oui");
          this.firstFormGroup.reset();
          this.secondFormGroup.reset();
          this.thirstFormGroup.reset();
          this.grabFormGroup.reset();
          this.clients = [];
          this.place = {};
          this.stepper.reset();
        }
      }
    );

    // if (this.clients.length !== 0) {
    //   this.end = true
    //   this.isLinear = false;
    //   this.fillForms();
    //   this.goToPayment();
    //   this.resumeOrder = true;
    // }
  }

  fillForms() {
    let posi = this.clients.length - 1;
    this.stored_phone_number = this.clients[posi].tel;
    // this.firstFormGroup = new FormGroup({
    //   countryCtrl: new FormControl(this.stored_ctry)
    // });
    this.secondFormGroup = new FormGroup({
      firstCtrl: new FormControl(this.clients[posi].first_name),
      lastCtrl: new FormControl(this.clients[posi].last_name),
      emailCtrl: new FormControl(this.clients[posi].email),
      phoneCtrl: new FormControl(this.stored_phone_number),
      genderCtrl: new FormControl(this.clients[posi].genre),
      matrimonialCtrl: new FormControl(this.clients[posi].matrimonial),
      birthdayCtrl: new FormControl(this.clients[posi].birthday),
      addressCtrl: new FormControl(this.clients[posi].address),

    });
    this.grabFormGroup = new FormGroup({
      grabPoint: new FormControl(this.clients[posi].grab_point),
    });
    this.thirstFormGroup = new FormGroup({
      gsanginCtrl: new FormControl(this.clients[posi].g_sanguin),
      diabeteCtrl: new FormControl(this.clients[posi].diabeteCtrl),
      tensionCtrl: new FormControl(this.clients[posi].tensionCtrl),
      hepatieCtrl: new FormControl(this.clients[posi].hepatie),
      odiseasesCtrl: new FormControl(this.clients[posi].other_maladie),
      allergyCtrl: new FormControl(this.clients[posi].allergie),

    });
  }
  goToPayment() {
    this.defaultIndex = this.add_medical_record === true ? 3 : 2;
  }
  showPayment() {
    this.cashPayment = false;
    this.allowPayment = true;
  }
  showCashPayment() {
    this.cashPayment = true;
    this.allowPayment = false;
  }

  onChange(event) {
    const reader = new FileReader();
      /*Maximum allowed size in bytes
      4MB Example
      Change first operand(multiplier) for your needs*/
      const maxAllowedSize = 4 * 1024 * 1024;
      if (event.target.files && event.target.files.length > 0) {
        if (event.target.files[0].size > maxAllowedSize) {
          this.imageUrl = null;
          this.showSizeError = true;
          // Here you can ask your users to load correct file
          // console.log("Image trop large");

        }else{
          this.showSizeError = false;
          const file = event.target.files[0];
          reader.readAsDataURL(file);
          reader.onload = (e: any) => {
            this.imageUrl = e.target.result;
            this.base64imageUrl = this.imageUrl;
            // console.log(this.imageUrl);

            // this.secondFormGroup.get('photoCtrl').setValue({
            //   // fileName: file.name,
            //   fileName: this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm') + '-'
            //     + this.secondFormGroup.get('firstCtrl').value + '-'
            //     + this.secondFormGroup.get('lastCtrl').value,
            //   fileType: file.type,
            //   fileExtension: file.name.substr(file.name.lastIndexOf('.') + 1),
            //   value: (<string>reader.result).split(',')[1]
            // });
          };
        }
    }
  }
  getPlace(place) {
    this.place = place;
    // console.log(this.place);

  }
  openDialog1() {
    const dialogConfig = new MatDialogConfig();
    // The user can't close the dialog by clicking outside its body
    dialogConfig.disableClose = false;
    dialogConfig.data = {
      id: 1,
      title: "Edition"
    }
    dialogConfig.height = "350px";
    dialogConfig.width = "600px";
    dialogConfig.autoFocus = false;

    const dialogRef = this.matDialog.open(OrderConditionsComponent, dialogConfig);

  }
  onCheckboxChange(event: MatCheckboxChange) {
    if (event.checked) {
      this.seen = event.checked;
    } else {
      this.seen = false;
    }
  }
  onCheckboxChange2(event: MatCheckboxChange) {
    if (event.checked) {
      this.add_medical_record = event.checked;
    } else {
      this.add_medical_record = false;
    }
  }

  sendFinalMessage() {
    this.globalSvc.showNgxLoader();
    let message = "Bonjour <span style=\"color: green\">" + this.clients[0].first_name + "</span>, <br> Votre numéro de commande est le : <strong>" +
      this.transaction_number + "</strong>. <br><br>Rendez-vous à '<strong>" +
      this.clients[0].grab_point + "</strong>' pour effectuer le paiement  et finaliser votre commande.";
    let email = this.clients[0].email;
    let data = {
      transaction_number: this.transaction_number,
      quantity: this.clients.length,
      montant: this.productPrice,
      client: this.clients[0].first_name+" "+this.clients[0].last_name,
      email: this.clients[0].email,
      phone: this.clients[0].tel,
      grab_point: this.savedOrder.grab_point
    }
    this.OrderService.sendFinalOrderMail(email, message, data).subscribe((data) => {
      console.log(data);
      this.globalSvc.spinner.hide();
      this.router.navigateByUrl('/order-confirmation');
    },
      (error) => {
        console.log(error);
        this.globalSvc.spinner.hide();
        this.router.navigateByUrl('/order-confirmation');
      }
    );
  }

  fileChanged(e) {
    const file = e.target.files[0];
    this.getBase64(file);
  }
  getBase64(file) {
    const reader = new FileReader();
    if(file){
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.photo = reader.result as string;
      };
      reader.onerror = (error) => {
        console.log('Error: ', error);
      };
    }
  }
  switchAddress(){
   (this.defaultIndice < (this.clients.length - 1)) ? this.defaultIndice ++ : this.defaultIndice = 0;
   for(let client of this.clients){
     console.log(client);
     client.is_selected = false;
   }

   this.clients[this.defaultIndice].is_selected = true;
  }

}
