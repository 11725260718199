<div class="container py-5 mb-lg-3">
  <!-- <iframe width="100%" height="800px" frameBorder="0" [src]="urlSafe | safe: 'resourceUrl'"></iframe> -->
  <div class="row">

    <div class="col-md-4" style="background: burlywood;">

      <div>
        <div style="text-align: center;">
          <h3 style="font-size: 16px; font-weight: bold; margin-bottom: 5px;">COMMANDE</h3>
          <h4 style="font-size: 16px;  margin-bottom: 5px;">Carte de sante Teliya</h4>
        </div>
        <hr>
        <div>
          <div style="margin-bottom: 10px;">
            <span style="min-width: 100px; font-size: 16px; display: inline-block;">Reference : </span> 
            <span style="font-size: 16px; font-weight: bold; display: inline-block;">54898DD8DSHHWE6H</span>
          </div>
          <div style="margin-bottom: 10px;">
            <span style="min-width: 100px; font-size: 16px; display: inline-block;">Quantite : </span>
            <span style="font-size: 16px; font-weight: bold; display: inline-block;">1</span>
          </div>
          <div style="margin-bottom: 10px;">
            <span style="min-width: 100px; font-size: 16px; display: inline-block;">Prix : </span> 
            <span style="font-size: 16px; font-weight: bold; display: inline-block;">10 000 GNF</span>
          </div>
          <div style="margin-bottom: 10px;">
            <span style="min-width: 100px; font-size: 16px; display: inline-block;">Total : </span> 
            <span style="font-size: 16px; font-weight: bold; display: inline-block;">10 000 GNF</span>
          </div>
        </div>
      </div>

      <!-- <div>
        <div style="text-align: center;">
          <h3 style="font-size: 16px; font-weight: bold; margin-bottom: 5px;">RECHARGE</h3>
          <h4 style="font-size: 16px;  margin-bottom: 5px;">Carte de sante Teliya</h4>
        </div>
        <hr>
        <div>
          <div style="margin-bottom: 10px;">
            <span style="min-width: 100px; font-size: 16px; display: inline-block;">Reference : </span> 
            <span style="font-size: 16px; font-weight: bold; display: inline-block;">54898DD8DSHHWE6H</span>
          </div>
          <div style="margin-bottom: 10px;">
            <span style="min-width: 100px; font-size: 16px; display: inline-block;">Montant : </span> 
            <span style="font-size: 16px; font-weight: bold; display: inline-block;">10 000 GNF</span>
          </div>
        </div>
      </div> -->

    </div>

    <div class="col-md-8">
      <div>
        <h2>Information de Facturation</h2>
      </div>
      <form action="">
        <div class="row">
          <div class="form-group col-md-6">
            <label> Nom </label>
            <input type="text" class="form-control" formControlName="name" >
          </div>
          <div class="form-group col-md-6">
            <label> Prenoms </label>
            <input type="text" class="form-control" formControlName="prenoms" >
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="form-group col-md-6">
            <label> Adresse </label>
            <input type="text" class="form-control" formControlName="address" >
          </div>
          <div class="form-group col-md-6">
            <label> Email </label>
            <input type="text" class="form-control" formControlName="email" >
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="form-group col-md-6">
            <label> Pays </label>
            <input type="text" class="form-control" formControlName="name" >
          </div>
          <div class="form-group col-md-6">
            <label> Etat </label>
            <input type="text" class="form-control" formControlName="prenoms" >
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="form-group col-md-6">
            <label> Ville </label>
            <input type="text" class="form-control" formControlName="ville" >
          </div>
          <div class="form-group col-md-6">
            <label> Code Postal </label>
            <input type="text" class="form-control" formControlName="codepostal" >
          </div>
        </div>
      </form>
    </div>

    
    
  </div>
</div>