<!--Intro Section-->
<section class="view intro-2">
  <div class="mask rgba-gradient">
    <div class="container h-100 d-flex justify-content-center align-items-center">
      <div class="row flex-center pt-1">
        <div class="col-md-6 text-center text-md-left margins">

        </div>
        <div class="col-md-6 wow fadeInRight d-flex justify-content-center" data-wow-delay="0.3s">

        </div>

      </div>
      <div class="row">
        <div class="col-12">
          <h1 class="text-center pt-4" style="color: #033B55; font-size: 38px; font-weight: bold;">
            CARTE DE SANTE TELIYA
          </h1>
          <!-- <p class="text-center" style="color: white;">
            La carte Teliya santé est une carte biométirique rechargeable valable partout en Guinée. <br>
            Pratique et sécurisée, elle permet de garder une mémoire de votre santé et de financer vos soins de santé.
          </p> -->
        </div>
      </div>

      <!-- <p style="padding-top: 300px;">

        <a class="btn btn-success wow fadeInLeft text-white mt-2"  routerLink="/commander"
          data-wow-delay="0.3s">
          <i class="fa fa fa-id-card-o pr-2" aria-hidden="true"></i> Je commande ma carte
        </a>
      </p> -->
    </div>

  </div>

</section>

<section class="backcolor">
  <div>
    <div class="container">
      <div class="row text-center pt-4">
        <div class="col-md-5 col-lg-4 col-sm-12 mb-4">
          <img src="../../../../assets/img/carte-santé.png" width="300" height="175" alt="image carte de santé teliya">
        </div>
        <div class="col-md-6 col-sm-12 col-lg-7 offset-md-1">
          <p class="text-center mt-1">
            La carte de santé Teliya est une carte biométrique rechargeable. <br>
            Pratique et sécurisée, elle permet de garder une mémoire de votre santé et de financer vos soins de santé.
          </p>
          <p class="mt-2">

            <a class="btn btn-success wow fadeInLeft text-white mt-2" routerLink="/commander" data-wow-delay="0.3s">
              <i class="fa fa fa-id-card-o pr-2" aria-hidden="true"></i> Je commande ma carte
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>

  <section id="about-5" class="pt-100 about-section division"
    style="background-image: url(../../../../assets/img/carte2.png); background-repeat: no-repeat;
    background-position: center center; background-size: cover ; height: 500px;">
    <div class="container">
      <div class="row d-flex align-items-center">


        <div class="col-sm-12 col-md-7 col-xl-7 pt-4">
          <div class="row" >
            <div class="col-md-6">

              <div class="sbox-7 icon-xs wow fadeInUp" data-wow-delay="0.4s">
                <span class="fa fa-id-card fa-2x pr-2 bg" aria-hidden="true" ></span>
                <p class="size-18 pt-1 text-col">Créer son dossier médical partagé </p>
              </div>

            </div>
            <div class="col-md-6">

                        <div class="sbox-7 icon-xs wow fadeInUp" data-wow-delay="0.4s">
                          <span class="fa fa-medkit fa-2x pr-2 bg" aria-hidden="true"></span>

                          <p class="size-18 pt-1">Souscrire à une assurance maladie</p>

                        </div>

            </div>

            <div class="col-md-6">

              <div class="sbox-7 icon-xs wow fadeInUp" data-wow-delay="0.4s">
                <span class="fa fa-folder-open-o fa-2x pr-2 bg" aria-hidden="true"></span>
                <p class="size-18 pt-1">Payer ses prestations directement avec sa carte</p>

              </div>

            </div>

            <div class="col-md-6">

              <div class="sbox-7 icon-xs wow fadeInUp" data-wow-delay="0.4s">
                <span class="fa fa-shield fa-2x pr-2 bg" aria-hidden="true"></span>
                <p class="size-18 pt-1">Sécuriser ses données de santé</p>

              </div>

            </div>

          </div>
        </div>






      </div> <!-- End row -->
    </div> <!-- End container -->
  </section> <!-- END ABOUT-5 -->





  <!-- <div style="background-image: url(../../../../assets/img/row-bgimage-4.jpg); height: 230px; color: white; text-align: center;">

  <div class="container text-center">
    <div class="mt-3">
      <h2 class="text-center size-30 pt-3">Rechargeable</h2>
    </div>
  <p class="text-center size-18">Faites financer vos frais de santé sans frais en rechargeant votre carte dépuis l'étranger et à travers Orange money Guinée. <br> Remettez simplement votre numéro de carte.</p>
  <a routerLink="/recharger" class="text-center btn btn-success btn-lg bot" role="button" aria-pressed="true">Recharger ma carte</a>

  </div>
  </div> -->
  <div class="pt-5" style="background-color: #f8f9fb; color: #f7f8fb">

  </div>
  <div style="background-image: url(../../../../assets/img/carte3.png); background-size: cover; background-position: center center;">
    <div class="container section-couveture ">
      <!-- <h2 class="text-center size-30 pt-3">Couverture des soins de santé</h2> -->
      <h2 class="text-center size-33 pt-3" style="color: #033b55; font-size: 38px;">Le saviez vous?</h2>
      <hr>
      <br>


      <p >Une chute, Une électrocution, une brulure,... </p><br>
      <p >Les accidents de la vie courante sont quotidiens,
        souvent graves et imprévisibles.</p><br>

      <p  >Souscrivez à une police d'assurance maladie en
        étant pris en charge dans les meilleurs hôpitaux </p><br>
      <p > Retrouvez toutes vos informations de remboursement
        ( Plafond, historique, Taux de couverture, ...) directement sur votre carte.</p><br>
      <p class="tite">
        <a class="btn btn-success wow fadeInLeft text-white mt-2" routerLink="/insurance"
          data-wow-delay="0.3s">
          <i class="fa fa fa-id-card-o pr-2" aria-hidden="true"></i> Souscrire à une assurance
        </a>
      </p>
      <br>

    </div>
  </div>

  <div
    style="background-color: #033b55; color: white; ">
    <div class="container section-couveture ">
      <div class="row text-center">
        <div class="col-sm-10 offset-sm-1">
          <h2 class="text-center size-33" style="font-size: 38px; padding-top: 30px; padding-bottom: 20px;">
            Votre portemonnaie santé
          </h2>
          <p class="pt5" >
            La carte teliya santé intègre un portemonnaie électronique permettant de mieux
            gérer vos frais de santé. <br>
            A travers ce système rechargeable avec VISA et Orange Money Guinée, recevez de
            l'argent directement sur votre carte pour payer vos soins de santé.
          </p>
          <p class="tite text-center">
            <a class="btn btn-success wow fadeInLeft text-white mt-2" routerLink="/recharger" data-wow-delay="0.3s">
              <i class="fa fa fa-id-card-o pr-2" aria-hidden="true"></i> Je recharge ma carte
            </a>
          </p>
          <br>
        </div>
      </div>


    </div>
  </div>


  <div style="background-color: #f8f9fb; color: black;">
    <div class="container section-dmp">

      <h2 class="text-center size-33 pt-3" style="color: #033b55; font-size: 38px;">Dossier médical partagé</h2>

   <p class="mb-2 line mb-4">C'est un espace protégé, gratuit , sécurisé et non obligatoire, le DMP vous permet de disposer en ligne des informations sur votre situation médicale. Des données qui peuvent s'avérer très utiles pour les différents professionels de santé qui vous suivent, ou en cas d'urgence. <br>
Le DMP est le seul service qui vous permet de retrouver dans un même endroit :</p>


<div class="row">
  <!--Grid column-->
  <div class="col-md-4">

    <div class="sbox-7 icon-xs wow fadeInUp" data-wow-delay="0.4s">
      <span class="fa fa-medkit fa-2x pr-2 bg" aria-hidden="true" ></span>
      <p class="pt-2 text-col">Vos antécédents médicaux (allergies, pathologies, ...)</p>
    </div>

  </div>
  <div class="col-md-4">

    <div class="sbox-7 icon-xs wow fadeInUp" data-wow-delay="0.4s">
       <span class="fa fa-list-alt fa-2x pr-2 bg" aria-hidden="true"></span>

       <p class=" pt-2 text-col">Votre historique de soins automatiquement alimenté</p>

    </div>

  </div>

  <div class="col-md-4">

    <div class="sbox-7 icon-xs wow fadeInUp" data-wow-delay="0.4s">
      <span class="fa fa-files-o fa-2x pr-2 bg" aria-hidden="true"></span>
      <p class="text-col pt-2">Vos résultats d'examens ( radios, analyses biologiques)</p>

    </div>

  </div>

  <div class="col-md-4">

    <div class="sbox-7 icon-xs wow fadeInUp" data-wow-delay="0.4s">
      <span class="fa fa-street-view fa-2x pr-2 bg" aria-hidden="true"></span>
      <p class="text-col pt-2"> Les coordonnées de vos proches à prevenir en cas d'urgence</p>

    </div>

  </div>


  <div class="col-md-4">

    <div class="sbox-7 icon-xs wow fadeInUp" data-wow-delay="0.4s">
      <span class="fa fa-pie-chart fa-2x pr-2 bg" aria-hidden="true"></span>
      <p class=" text-col pt-2">Vos comptes rendus d'hospitalisation</p>

    </div>

  </div>




</div>

<div class="row">
<div class="col-12">
  <p class="line mt-3 mb-4">
    Vous contrôlez l'accès à votre DMP grâce à vos empreintes digitales. A part vous, seuls les
    professionnels de santé autorisés (votre médecin traitant, médecin conseil, ...) peuvent le consulter.
  </p>
</div>
</div>



</div>
</div>


  <div
    style="background-image: url(../../../../assets/img/carte6-prim.png); background-size: cover; background-position: center center; color: black;">
    <!-- <div style="height: 40px;"></div> -->
    <div class="row" style="background-color: white; opacity: 0.8;">



      <!-- TEXT BLOCK -->
      <div class="col-12">
        <div class="txt-block pc-30 wow fadeInUp" data-wow-delay="0.4s">

          <!-- Section ID -->
          <h2 class="text-center size-33"
            style="font-size: 38px; padding-top: 15px; line-height: 1.1em; color: #033b55;">L'application Mobile
            Teliya Santé</h2>

          <p class="text-center pt-1">
            Consultez & renseignez votre dossier médical partagé <br>
            Accédez aux meilleures offres d'assurance maladie <br>
            Consultez toutes les informations relatives à votre assurance <br>
            Gérez votre portemonnaie santé
          </p>
          <p class="tite text-center">
            <a class="btn wow fadeInLeft text-whitemt-1" style="background-color: #033b55; color: white;" href="https://play.google.com/store/apps/details?id=health.teliya.net" target="_blank" data-wow-delay="0.3s">
              Découvrir l'application
            </a>
          </p>

        </div>

        <!-- END TEXT BLOCK -->




      </div>

    </div>

  </div>

</section>
