import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-form-array-exemple',
  templateUrl: './form-array-exemple.component.html',
  styleUrls: ['./form-array-exemple.component.css']
})
export class FormArrayExempleComponent implements OnInit {
  form= this.fb.group({
    lessons: this.fb.array([])
  });
  constructor(private fb:FormBuilder) { }

  ngOnInit(): void {
  }

  get lessons(){
    return this.form.controls['lessons'] as FormArray;
  }

  addLesson(){
    const lessonForm = this.fb.group({
      title: ['', Validators.required],
      level: ['beginner', Validators.required]
    });
    this.lessons.push(lessonForm);

  }

  deleteLesson(lessonIndex: number){
    this.lessons.removeAt(lessonIndex);
  }
}
