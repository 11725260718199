<div class="page-title-overlap stylish-color-img posi">
  <div class="container d-lg-flex justify-content-between pt-3">
    <div class="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center justify-content-lg-start pb-2">
          <li class="breadcrumb-item"><a class="text-nowrap" routerLink="/home" ><i
                class="fa fa-home"></i>Acceuil</a></li>
                <li class="breadcrumb-item text-nowrap active" aria-current="page">Particuliers</li>
                <li class="breadcrumb-item text-nowrap active" aria-current="page" >Recharger</li>
        </ol>
      </nav>
    </div>
    <div class="order-lg-1 pr-lg-4 text-center text-lg-left">
      <h1 class="h3 text-light mb-0"></h1>
    </div>
  </div>
  <div class="row">
    <div class="col-md-8 offset-md-2">
      <h1 class="text-center mb-2 pt-2" style="color: #033B55; font-size: 38px; font-weight: bold;">
        Rechargez votre carte de santé Teliya
      </h1>
      <p class="text-center mb-2" style="color: white;">
        Et payez vos prestations de santé sans frais
      </p>
    </div>
  </div>
</div>


<div style="background-color: #f8f9fb; padding-top: 40px;">
  <div class="container">


    <div class="row text-center pt-3">
      <div class="col-sm-4">
        <p>
          <span class="text-white bg-success rounded-circle h1" style="font-size: 3rem; font-weight: bold; padding: 20px; padding-top: 3px; padding-bottom: 3px;">1</span>
        </p>
        <p class="pt5">
          Entrer votre numéro de carte
        </p>
      </div>
      <div class="col-sm-4">
        <p>
          <span class="text-white bg-success rounded-circle h1" style="font-size: 3rem; font-weight: bold; padding: 20px; padding-top: 3px; padding-bottom: 3px;">2</span>
        </p>
        <p class="pt5" >
          Entrer le montant de la recharge
        </p>
      </div>
      <div class="col-sm-4">
        <p>
          <span class="text-white bg-success rounded-circle h1" style="font-size: 3rem; font-weight: bold; padding: 20px; padding-top: 3px; padding-bottom: 3px;">3</span>
        </p>
        <p class=" pt5">
          Procéder au paiement avec VISA ou Orange Money Guinée
        </p>
      </div>
    </div>
  </div>
</div>

<section class="backcolor" style="background-color: #f8f9fb;">
  <div class="container">

    <div class="row featurette pt-5" *ngIf="!isInsured">
      <div class="col-md-7 order-md-2">
        <h2 class="featurette-heading pt-4" style="font-size: 1.8em;">Entrez votre numéro de carte Teliya</h2>
        <form #f="ngForm" (ngSubmit)="searchById(f)">

          <div class="form-group">
            <input style="width: 300px;" class="form-control" type="text" ngModel placeholder="Numero de la carte"
              name="numero" minlength="8" maxlength="12" required>
            <span style="color: red;" *ngIf="message">{{message}}</span>

          </div>



          <div class="mt-2">

            <button [disabled]="f.invalid" type="submit" class="btn ml-2" style="background-color: #033b55; color:white">Rechercher</button>
          </div>
        </form>


      </div>
      <div class="col-md-5 order-md-1 mb-5">
        <img src="../../../../assets/img/card-number.png" alt="modele de carte" width="400" height="200">
      </div>
    </div>
    <div class="row featurette pt-5" *ngIf="isInsured">
      <div class="col-md-7 order-md-2" *ngIf="!showPaymentMode">
        <h2 class="featurette-heading">Entrez le montant de la Recharge</h2>
        <form #amountForm="ngForm" (ngSubmit)="setAmount(amountForm)">

          <div class="form-group">
            <input style="width: 300px;" class="form-control" type="number" ngModel placeholder="Montant de la recharge"
              name="amount" required>
            <span style="color: red;" *ngIf="message">{{message}}</span>

          </div>



          <div class="mt-2">
            <button [disabled]="amountForm.invalid" type="submit" class="btn btn-success ml-2">Valider</button>
          </div>
        </form>


      </div>
      <div class="col-md-7 order-md-2 mb-1" *ngIf="showPaymentMode">
        <!-- <h2 class="featurette-heading">Mode de payement</h2> -->
        <ng-template #customLoadingTemplate>
          <div class="custom-class">

            <h3> paiement en cours...</h3>

          </div>
        </ng-template>

        <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '4px' }" [template]="customLoadingTemplate">
        </ngx-loading>
        <div class="mt-2 text-center">

          <p class="" >Sélectionnez un mode de paiement</p>


          <button type="button" (click)="payement()" class="mr-3"
            style="background-image: url(../../../../assets/img/orange.png) !important;background-size: cover; width: 98px; height: 75px;"></button>

          <button type="button" (click)="payementVisa()" class="mr-3"
            style="background-image: url(../../../../assets/img/card-visa.png) !important;background-size: cover; width: 98px; height: 75px;"></button>

        </div>

      </div>
      <div class="col-md-5 order-md-1 mb-5">
        <h2 class="featurette-heading col-sm-10 offset-sm-1 text-center mt-2">Informations personnelles</h2>
        <label class="col-sm-5 offset-sm-1">N°: </label>
        <span class="col-sm-5">{{ insured?.numero }} </span>

        <label class="col-sm-5 offset-sm-1">Nom: </label>
        <span class="col-sm-5">{{ insured?.lastname }} </span>

        <label class="col-sm-5 offset-sm-1">Prénom(s): </label>
        <span class="col-sm-5">{{ insured?.firstname }} </span>
        <label class="col-sm-5 offset-sm-1">Solde actuel: </label>
        <span class="col-sm-5">{{ insured?.card?.thebalance }}GNF </span>
        <div *ngIf="showPaymentMode">
          <label class="col-sm-5 offset-sm-1">Montant à recharger: </label>
          <span class="col-sm-5">{{ amount }}GNF </span>
        </div>
      </div>
    </div>
  </div>


</section>

<div style="background-color: #f8f9fb;">
  <div class="container">
    <h2 class="size-33" style="font-size: 38px; font-weight: bold;  color: #033b55;">
      <span style="color: green;">Informations</span>  Utiles
    </h2>

    <div class="row">
      <div class="col-12">
        <p class="pt1">
          <strong>1.</strong>
           <span class="text-center">
              Lors du processus de paiement, vous pourrez choisir de payer par carte de
              crédit (VISA) ou par Orange Money Guinée
            </span>  <br>

            <strong>2.</strong>
           <span class="text-center">
              Aucun frais n'est prélévé lors du paiement de vos soins
            </span><br>

          <strong>3.</strong>
           <span class="text-center">
              Votre carte vous permet de payer rapidement vos soins de santé
            </span><br>

            <strong>4.</strong>
           <span class="text-center">
              Télécharger l'application mobile pour mieux gérer votre porte-monnaie santé
            </span><br>

            <strong>5.</strong>
           <span class="text-center">
              Consulter notre <a routerLink="/our-partners">réseau de soins</a> pour connaitre nos partenaires
            </span><br>
        </p>
      </div>
    </div>
  </div>
</div>

<!-- type="ball-scale-multiple" -->


