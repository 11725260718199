<div class="page-title-overlap stylish-color-dark pt-2 posi">
  <div class="container d-lg-flex justify-content-between">
    <div class="order-lg-2 mb-2 mb-lg-0">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center justify-content-lg-start">
          <li class="breadcrumb-item"><a class="text-nowrap" routerLink="/home"><i class="fa fa-home"></i>Acceuil</a>
          </li>
          <li class="breadcrumb-item text-nowrap active" aria-current="page">Professionnels de santé</li>
          <li class="breadcrumb-item text-nowrap active" aria-current="page">Adhérer au reseau</li>
        </ol>
      </nav>
    </div>
    <div class="order-lg-1 pr-lg-4 text-center text-lg-left">
      <!-- <h1 class="h3 text-light mb-0">Adhésion</h1> -->
    </div>
  </div>
</div>

<section class="view intro-2">
  <div class="col-10 offset-1" *ngIf="showDescription">
    <h2 class="text-center size-25">Adhérer au Réseau de soins Teliya Santé</h2>
    <p class="text-center">
      L’adhésion est ouverte aux <br>
    </p>
    <div>
      <ul>
        <li>
          Professionnels de santé agrées : médecins, pharmaciens, sages-femmes, chirurgiens-
          dentistes, ...
        </li>
        <li>
          structures de soins agrées : cliniques, pharmacies, laboratoires, cabinets de soins,.....
        </li>
      </ul>
    </div>
    <div class="text-center mt-5 pb-5">
      <button mat-raised-button color="primary"
        (click)="canShowDescription(false)"
      >Continuer</button>
    </div>
    <div class="text-center mt-3 pb-2">

    </div>
  </div>
  <div class="container mt-5 pb-5" *ngIf="!showDescription">
    <h4 class="text-center size-23">TRANSMETTEZ-NOUS VOTRE DEMANDE VIA LE FORMULAIRE CI-DESSOUS</h4>
    <mat-vertical-stepper  [linear]="isLinear">
      <mat-step [stepControl]="form_adhere" label="Informations sur la structure">
        <form [formGroup]="form_adhere" class="example-form">
          <!-- <h2 class="text-center size-25">Formulaire d'adhésion au réseau de soins </h2> -->

          <mat-form-field class="example-full-width">

            <mat-label>Structure </mat-label>
            <mat-select  name="strucCtrl" formControlName="strucCtrl" required>
              <mat-option *ngFor="let he of structure" [value]="he.value">
                {{he.value}}
              </mat-option>
            </mat-select>

          </mat-form-field>
          <mat-form-field class="example-full-width ">
            <input matInput placeholder="Dénomination" name="denomCtrl" formControlName="denomCtrl" required>
          </mat-form-field>

          <mat-form-field class="example-full-width">
            <input matInput placeholder="Numéro registre du commerce" name="rccm" formControlName="rccmCtrl" required>
          </mat-form-field>

          <!-- <mat-form-field class="example-full-width">
          <input matInput placeholder="Sous le numéro " name="nulber_rccm" formControlName="number_rccmCtrl" required>
          </mat-form-field> -->
          <mat-form-field class="example-full-width">
            <input matInput placeholder="Adresse " name="address" formControlName="address" required>
          </mat-form-field>
          <mat-form-field class="example-full-width">
            <input matInput type="number" placeholder="n° téléphone de l'entreprise " name="contact" formControlName="contact" minlength="9" maxlength="12" required>
          </mat-form-field>

          <mat-form-field class="example-full-width">
            <mat-label>En patenariat avec </mat-label>
            <!-- <mat-select required name="partenatCtrl" formControlName="partenatCtrl">
              <mat-option *ngFor="let he of insurance" [value]="he.value">
                {{he.value}}
              </mat-option>
            </mat-select> -->

            <mat-select multiple name="partenatCtrl" formControlName="partenatCtrl">
              <mat-option *ngFor="let he of insurance" [value]="he.value">
                {{he.value}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="example-full-width">
            <mat-checkbox name="addOther" (change)="onAddPartner($event)" [(checked)]="otherPart"> Autres partenaires, A préciser:</mat-checkbox>
            <input matInput placeholder="précisez les en les séparant par des virgules ' , ' " name="other"
              formControlName="otherPartnersCtrl" *ngIf="otherPart"><hr>
          </div>

          <div class="mt-2">
            <button class="ml-2 mb-1" mat-raised-button color="primary" (click)="scrollToTop()" matStepperNext>Suivant</button>
          </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="representative_form" label="Informations personnelles">
        <form [formGroup]="representative_form" class="example-form">
          <mat-form-field class="example-full-width">
            <input matInput placeholder="Nom" name="firstName" formControlName="firstCtrl" required>
          </mat-form-field>
          <mat-form-field class="example-full-width">
            <input matInput placeholder="Prénom" name="lastName" formControlName="lastCtrl" required>
          </mat-form-field>

          <mat-form-field class="example-full-width">
            <input matInput type="number" placeholder="n° de téléphone " name="phone" formControlName="phone" minlength="9" maxlength="12" required>
          </mat-form-field>
          <mat-form-field class="example-full-width">
            <input matInput placeholder="Email" name="email" formControlName="email"
              pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}" required>
          </mat-form-field>
          <mat-form-field class="example-full-width">
            <input matInput placeholder="Agissant en qualité de " name="aggissant" formControlName="aggissant" required>
          </mat-form-field>

          <mat-checkbox name="seen" [(checked)]="seen" (change)="onCheckboxChange($event)"> <a routerLink="/conditions" target="_blank" class="white-t">J'ai
              lu et j'accepte les conditions</a></mat-checkbox>

          <div class="mt-3 mb-3">
            <button class="mr-2 mb-1" mat-raised-button color="accent" (click)="scrollToTop()" matStepperPrevious>Précédent</button>
            <button mat-raised-button color="primary" [disabled]="!form_adhere.valid || !representative_form.valid || !seen"
               (click)="saveAdderer()">Envoyer</button>

          </div>

        </form>
      </mat-step>
    </mat-vertical-stepper>

  </div>
</section>
