import { Component, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-healthcare-network',
  templateUrl: './healthcare-network.component.html',
  styleUrls: ['./healthcare-network.component.css']
})
export class HealthcareNetworkComponent implements OnInit {
  partners = [];
  partners_ = [];
  constructor(
    private globalSvc: GlobalService,
    private orderService: OrderService,
    private router: Router
    ) { 
      this.globalSvc.setTitle('Réseau de Soins');
    }

  ngOnInit(): void {
    this.globalSvc.fixDisplay();
    this.getPartnersList();
  }
  getPartnersList(){
    this.globalSvc.showNgxLoader();
    let isgrabpoint = false;
    this.orderService.getCardGrabPoints(isgrabpoint).subscribe(
      (result)=>{
        this.partners = this.partners_ = result['data'];
        this.globalSvc.spinner.hide();
        console.log(result);
      },
      (error)=>{
        this.globalSvc.spinner.hide();
        console.log(error);
      }
    )
  }

}
