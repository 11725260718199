import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  message: string;
  form: FormGroup;
  data : any;
  loading = false;

  constructor(private fb: FormBuilder,
              private authService: AuthService,
              private router: Router,
              private route: ActivatedRoute,
              private globalSvc: GlobalService,
              ) { }

  ngOnInit(): void {
    this.globalSvc.fixDisplay();
    this.authService.loardJwtToken();

       this.form = this.fb.group({
        email: new FormControl('', Validators.required),
        password: new FormControl('', Validators.required)
       });
  }




  onLogin(){
    this.loading = true;
    this.authService.login(this.form.getRawValue())
    .subscribe(resp =>{
      if(resp['isAuth']){
        let returnUrl = this.route.snapshot.queryParamMap.get('returnUrl');
        this.authService.first_name = resp['user'].first_name;
        this.authService.last_name = resp['user'].last_name;
        this.authService.email = resp['user'].email;
        //this.authService.roles = resp['user']['roles'][0].name;
        this.authService.saveJwtToken(resp['access_token']);
        localStorage.setItem('username', resp['user'].last_name);
        this.loading = false;
        this.router.navigate([returnUrl || '/home']);
      } else{
        this.loading = false;
      }

    }, error=>{

      if (error.status==403){
        this.message = error.error.message;
        this.loading = false;
      }else{
        console.log(error);

      }


    })

  }
}
