import { Component, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-offerpro',
  templateUrl: './offerpro.component.html',
  styleUrls: ['./offerpro.component.css']
})
export class OfferproComponent implements OnInit {

  constructor(
    private globalSvc: GlobalService,
    private router: Router
  ) { 
    this.globalSvc.setTitle('Professionnel de Santé');
  }

  ngOnInit(): void {
    this.globalSvc.fixDisplay();
  }

}
