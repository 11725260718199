import { Component, OnInit } from '@angular/core';
import { MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-order-conditions',
  templateUrl: './order-conditions.component.html',
  styleUrls: ['./order-conditions.component.css']
})
export class OrderConditionsComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<OrderConditionsComponent>) { }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
  }

}
