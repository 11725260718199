import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Event, NavigationEnd, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from 'src/app/services/global.service';
import { OrderService } from 'src/app/services/order.service';
declare var $;

@Component({
  selector: 'app-recharger',
  templateUrl: './recharger.component.html',
  styleUrls: ['./recharger.component.css']
})
export class RechargerComponent implements OnInit {

  step1:number;
  numero: string;
  insured: any;
  amount : number = 0;
  om_order: any;
  message: string;
  isInsured = false;
  loading = false;
  showPaymentMode = false;


  constructor(
      private OrderService: OrderService,
      private toastr : ToastrService,
      private globalSvc: GlobalService,
      private spinner: NgxSpinnerService,
      private router: Router
  ) {
    this.globalSvc.setTitle('Recharge de carte');
  }

  ngOnInit(): void {
    this.globalSvc.fixDisplay();
  }


  searchById(f: NgForm){
    this.showNgxLoader();
    let datas = {
      number: f.value['numero'],
      sourcePlatform: 'website'
    }

     this.OrderService.checkInsured(datas).subscribe(async data => {
       this.spinner.hide();
      if(data['data'] !== null){
        f.reset();
        console.log(data);
        this.insured = data['data'];
        if(this.insured.card_id != null){
          this.isInsured = true;
        }else{
          await  this.toastr.info('', "Aucune correspondance pour ce numéro" ,{
            timeOut: 3000,
            tapToDismiss: true,
            progressBar: false,
          });
          f.reset();
        }
      }
      else{
        await  this.toastr.info('', "Aucune correspondance pour ce numéro" ,{
          timeOut: 3000,
          tapToDismiss: true,
          progressBar: false,
        });
        f.reset();

      }
    },
    error => {
       this.spinner.hide();
       console.log(error);
      }
    );
  }
  setAmount(amountForm: NgForm){
    if(amountForm.value['amount'] > 0){
      this.amount = amountForm.value['amount'];
      console.log(this.amount);
      this.message = null;
      amountForm.reset();
      this.showPaymentMode = true;
    }
    else{
      this.message = "le montant doit être supérieur à 0";
    }
  }

  payement(){
    this.showNgxLoader();
      this.om_order = {
        montant : this.amount,
        last_balance : this.insured.card.thebalance,
        isRecharge : true,
        insured_id: this.insured.id,
        from : "website"
      }
    // this.loading = true;
    this.OrderService.initPayment(this.om_order).subscribe(resp =>{
      this.spinner.hide();
      //console.log(resp);
      // this.showSpinner();
      window.location.href = resp['data'];

    }, async error => {
      // this.loading = false;
      this.spinner.hide();
      await  this.toastr.error('', "Echec de l'opération, vérifier votre connexion" ,{
        timeOut: 4000,
        tapToDismiss: true,
        progressBar: true
      });
      // alert('Erreur de payemnt');
      console.error(error)
    });
  }
  payementVisa(){
    // this.loading =true;
    this.showNgxLoader();
    let recharge = {
      'amount': this.amount,
      'last_balance' : this.insured.card.thebalance,
      'insured_id' : this.insured.id
    }
    this.OrderService.addRecharge(recharge).subscribe(resp =>{
      //console.log(resp);
      // this.showSpinner();
      let response = resp['recharge'];

      let paramss = {
        // profile_id : "C05EF21D-A208-4ED9-B5A1-E62679B87612", // dev
        profile_id : "3DC5E00C-E138-4C24-B706-8E99B33B9D1B", //prod
        // access_key : "1f9d96dcbcbd39599d9a7e3bedf35747",  // dev
        access_key : "f98f9065463735f9bae5c69b481d9733", // prod
        transaction_uuid : "61042ddd80f59",
        signed_date_time : "2021-07-30T16:50:37Z",
        signed_field_names : "profile_id,access_key,transaction_uuid,signed_field_names,unsigned_field_names,signed_date_time,locale,transaction_type,reference_number,auth_trans_ref_no,amount,currency,merchant_descriptor,override_custom_cancel_page,override_custom_receipt_page",
        unsigned_field_names : "signature,bill_to_forename,bill_to_surname,bill_to_email,bill_to_phone,bill_to_address_line1,bill_to_address_line2,bill_to_address_city,bill_to_address_state,bill_to_address_country,bill_to_address_postal_code,customer_ip_address,merchant_defined_data1,merchant_defined_data2,merchant_defined_data3,merchant_defined_data4",
        transaction_type : "sale",
        reference_number : response.ref_recharge,
        auth_trans_ref_no : response.ref_recharge,
        amount: this.amount,
        // currency: "GHS", //dev
        currency: "GNF", // prod
        locale : "en-us",
        merchant_descriptor : "Swen",
        bill_to_forename : "Noreal",
        bill_to_surname : "Name",
        bill_to_email : "null@cybersource.com",
        bill_to_phone : "+662-2962-000",
        bill_to_address_line1 : "1295 Charleston Rd",
        bill_to_address_line2 : "1295 Charleston Rd",
        bill_to_address_city : "Mountain View",
        bill_to_address_state : "CA",
        bill_to_address_country: "US",
        bill_to_address_postal_code : "94043",
        // override_custom_cancel_page : "http://localhost/cybersource_secure_acceptance_UAT_Swen/php/sa-wm/customresponse.php", // dev
        // override_custom_receipt_page : "http://localhost/cybersource_secure_acceptance_UAT_Swen/php/sa-wm/customresponse.php", // dev
        override_custom_cancel_page : this.globalSvc.eco_visa_notif_url,  // prod
        override_custom_receipt_page : this.globalSvc.eco_visa_notif_url, // prod
        customer_ip_address : "::1",
        line_item_count : "2",
        merchant_defined_data1 : "MDD#1",
        merchant_defined_data2 : "MDD#2",
        merchant_defined_data3 : "MDD#3",
        merchant_defined_data4 : "MDD#4",
        signature : "uvSvinvz2Q9a+CoYdBGOUNTn9bv1U0lOD98kIXjR0Ws="
      }

      this.OrderService.visadata(paramss).subscribe(resp =>{
        this.spinner.hide();
        console.log(resp);
        paramss.transaction_uuid = resp['uuid'];
        paramss.signed_date_time = resp['datetime'];
        paramss.signature = resp['signature'];
        // this.paramss.reference_number = resp['ref'];
        // this.paramss.auth_trans_ref_no = resp['auth'];
        console.log(paramss);

        $.redirect("https://secureacceptance.cybersource.com/pay", paramss, 'POST');
      }, async error => {
        console.error(error)
      });

      // window.location.href ='https://visa.teliya.net/sa-wm/payment_form.php?data=' + response.ref_order;

    }, async error => {
      // this.loading = false;
      this.spinner.hide();
      await  this.toastr.error('', "Echec de l'opération, vérifier votre connexion" ,{
        timeOut: 4000,
        tapToDismiss: true,
        progressBar: true
      });
      // alert('Erreur de payemnt');
      console.error(error)
    });

  }

  // showSpinner() {
  //   this.loading = true;
  //   setTimeout(() => { this.loading = false; }, 30000);
  // }
  showNgxLoader(){
    this.spinner.show();
    setTimeout(()=>{
      this.spinner.hide();
    },30000);
  }

}
