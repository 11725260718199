import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from 'src/app/services/global.service';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  form: FormGroup;

  constructor(private formBuilder: FormBuilder,
    private send : OrderService,
    private toastr: ToastrService,
    private globalSvc: GlobalService,
    ) { 
      this.globalSvc.setTitle('Contact');
    }

  ngOnInit(): void {
    this.globalSvc.fixDisplay();

    this.form = this.formBuilder.group({
      nameCtrl: new FormControl('', [Validators.required]),
      emailCtrl: new FormControl('', [Validators.required]),
      subjectCtrl: new FormControl('', [Validators.required]),
      messageCtrl: new FormControl('', [Validators.required]),
    });
  }

  sendMail(){
    console.log('sending mail');

    const emailData = {

      name: this.form.get("nameCtrl").value,
      email:this.form.get("emailCtrl").value,
      subject: this.form.get("subjectCtrl").value,
      message: this.form.get("messageCtrl").value,
     }
     this.globalSvc.showNgxLoader();

    this.send.sendingMail(emailData).subscribe(resp =>
      {
        this.form.reset();
        console.log(resp);
        this.globalSvc.spinner.hide();
        this.toastr.success('SUCCESS', `Votre mail a été envoyé avec success`.toUpperCase(),{
          timeOut: 5000,
          progressBar: true
        });


      },
      (error)=>{
        this.globalSvc.spinner.hide();
        this.toastr.error('', `Echec de l'opération`,{
          timeOut: 5000,
          progressBar: true
        });
      }
    );

  }

}
