<div class="streak streak-photo streak-md" style="background-image: url('https://mdbootstrap.com/img/Photos/Others/images/92.jpg');">
    <div class="flex-center view rgba-indigo-light">
      <div class="container py-3">

        <!-- Section: Features v.4 -->
        <section class="wow fadeIn" data-wow-delay="0.2s">

          <!-- Section heading -->
         

          <!-- Grid row -->
          <div class="row mb-5">

            <!-- Grid column -->
            <div class="col-md-12 col-lg-6">

              <!-- Grid row -->
              <div class="row mb-5">
                <div class="col-2">
                  <a type="button" class="btn-floating white btn-lg my-0">
                    <i class="fa fa-rocket blue-text" aria-hidden="true"></i>
                  </a>
                </div>
                <div class="col-9 pl-5">
                  <h4 class="font-weight-bold white-text">Livraison rapide et gratuite</h4>
                  <p class="white-text">Livraison gratuite pour toutes les commandes </p>
                </div>
              </div>
              <!-- Grid row -->

              <!-- Grid row -->
              <div class="row mb-5">
                <div class="col-2">
                  <a type="button" class="btn-floating white btn-lg my-0">
                    <i class="fa fa-usd blue-text" aria-hidden="true"></i>
                  </a>
                </div>
                <div class="col-9 pl-5">
                  <h4 class="font-weight-bold white-text">Garantie de remboursement</h4>
                  <p class="white-text">Nous retournons l'argent dans les 30 jours.</p>
                </div>
              </div>
              <!-- Grid row -->

              <!-- Grid row -->
   

            </div>
            <!-- Grid column -->

            <!-- Grid column -->
            <div class="col-md-12 col-lg-6">

              <!-- Grid row -->
              <div class="row mb-5">
                <div class="col-2">
                  <a type="button" class="btn-floating white btn-lg my-0">
                    <i class="fa fa-headphones blue-text" aria-hidden="true"></i>
                  </a>
                </div>
                <div class="col-9 pl-5">
                  <h4 class="font-weight-bold white-text">24/7 service client</h4>
                  <p class="white-text">Support client amical 24/7.
                  </p>
                </div>
              </div>
              <!-- Grid row -->

              <!-- Grid row -->
              <div class="row mb-5">
                <div class="col-2">
                  <a type="button" class="btn-floating white btn-lg my-0">
                    <i class="fa fa-id-card-o  blue-text" aria-hidden="true"></i>
                  </a>
                </div>
                <div class="col-9 pl-5">
                  <h4 class="font-weight-bold white-text">Paiement en ligne sécurisé</h4>
                  <p class="white-text">Nous possédons un certificat SSL / Secure.
                  </p>
                </div>
              </div>
              <!-- Grid row -->

        

            </div>
            <!-- Grid column -->

          </div>
          <!-- Grid row -->

        </section>
        <!-- Section: Features v.4 -->
      </div> 
    </div>
  </div>
