<div class="ml-3 mt-2 mb-2">
  <h3>Add Course Lessons:</h3>
  <div class="add-lessons-form" [formGroup]="form">
    <ng-container formArrayName="lessons">
      <ng-container *ngFor="let lessonForm of lessons.controls; let i = index">
        <div class="lesson-form-row" [formGroup]="lessonForm">
          <mat-form-field appearance="fill">
            <input matInput formControlName="title" placeholder="Lesson title">
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-select formControlName="level" placeholder="Lesson level">
              <mat-option value="beginner">Beginner</mat-option>
              <mat-option value="intermediate">Intermediate</mat-option>
              <mat-option value="advanced">Advanced</mat-option>
            </mat-select>
          </mat-form-field>
          <i class="fa fa-trash ml-1" (click)="deleteLesson(i)" color="primary" style="cursor: pointer;"></i>
        </div>
      </ng-container>
    </ng-container>
    <button mat-mini-fab (click)="addLesson()">
      <i class="fa fa-plus"></i>
    </button>
  </div>
</div>
