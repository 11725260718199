<div class="page-title-overlap stylish-color-img posi">
  <div class="container d-lg-flex justify-content-between pt-3">
    <div class="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center justify-content-lg-start pb-2">
          <li class="breadcrumb-item"><a class="text-nowrap" routerLink="/home" style="color: white"><i
                class="fa fa-home"></i>Acceuil</a></li>
          <li class="breadcrumb-item text-nowrap active" aria-current="page">Particuliers</li>
          <li class="breadcrumb-item text-nowrap active" aria-current="page" style="color: white;">Commande</li>
        </ol>
      </nav>
    </div>
    <div class="order-lg-1 pr-lg-4 text-center text-lg-left">
      <h1 class="h3 text-light mb-0"></h1>
    </div>
  </div>
  <div class="">
    <div class="col-md-8 offset-md-2">
      <h1 class="text-center mb-2 pt-4" style="color: #033B55; font-size: 38px; font-weight: bold;">
        DEVENEZ ACTEUR DE VOTRE SANTE
      </h1>
      <p class="text-center" style="color: white;">
        Commandez votre carte de santé Teliya <br> Et faites la livrer
        gratuitement chez l'un de nos partenaires
      </p>
    </div>
  </div>
</div>

<section id="about-5" class=" about-section division">

  <!-- End container -->
</section>

<div class="pt-3" style="background-color:#f8f9fb;
  ">

</div>

<section class="backcolor">
  <div class="p-4">

    <div *ngIf="nbre_de_carte>=0 && !alreadyPayed" class="pull-right mt-1">

      <p class="pl-3 h3"> <span class="text-white bg-danger rounded-circle pl-1  pr-1 h4">{{clients?.length ?
          clients?.length : 0}}</span> carte(s)</p>
    </div>


    <div *ngIf="select_insurance" [formGroup]="insuranceFormGroup">



      <div class="mt-2">
        <button mat-raised-button color="primary" (click)="clickEventHandler($event)">Suivant</button>
      </div>
    </div>


    <mat-horizontal-stepper [linear]="isLinear" #stepper="matHorizontalStepper" [selectedIndex]="defaultIndex"
      *ngIf="!alreadyPayed">
      <mat-step [completed]="clients?.length > 0" *ngIf="this.ref_order === null && !showPaymentStep">
        <form [formGroup]="secondFormGroup" class="">
          <ng-template matStepLabel title="Données personelles">Données personelles</ng-template>
          <div class="row p-0">
            <div class="col-md-6 p-0">
              <div class="row p-0">
                <div class="col-md-5 col-xs-12">
                  <mat-form-field class="example-full-width">
                    <input matInput placeholder="Nom" formControlName="lastCtrl" name="second" required>
                  </mat-form-field>
                  <mat-form-field class="example-full-width ">
                    <input matInput placeholder="Prénom(s)" name="first" formControlName="firstCtrl" required>
                  </mat-form-field>

                  <mat-form-field class="example-full-width">
                    <input matInput [matDatepicker]="picker" [max]="maxAvailableDate" placeholder="Date de naissance"
                      formControlName="birthdayCtrl" required>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
                  <mat-form-field class="example-full-width">
                    <mat-label>Genre</mat-label>
                    <mat-select required name="genre" formControlName="genderCtrl">
                      <mat-option *ngFor="let gr of Genre" [value]="gr.value">
                        {{gr.value}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                </div>
                <div class="col-md-5 col-xs-12">
                  <mat-form-field class="example-full-width">
                    <mat-label>N° téléphone</mat-label>
                    <input matInput placeholder="n° téléphone" formControlName="phoneCtrl" placeholder="123456789"
                      type="number" minlength="9" maxlength="12" required>
                  </mat-form-field>

                  <mat-form-field class="example-full-width">
                    <input matInput placeholder="email" formControlName="emailCtrl"
                      pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}" required>
                  </mat-form-field>

                  <mat-form-field class="example-full-width">
                    <input matInput placeholder="Addresse" formControlName="addressCtrl" minlength="2">
                  </mat-form-field>

                  <div class="text-center">
                    <label for="file-upload" class="custom-file-upload">
                      <img *ngIf="photo" [src]="photo" class="img-radius mb-0 photo" alt="Photo">
                      <i *ngIf="!photo" class="fa fa-camera camera-icon fa-2x" aria-hidden="true"></i>
                    </label>
                    <div *ngIf="!photo">
                      <small>Selectionner la photo ou vous pouvez choisir de la prendre dans un point de
                        retrait. Elle sera utilisée pour l'impression de la carte.</small>
                    </div>
                    <input id="file-upload" (change)="fileChanged($event)" name="photo" type="file" />
                  </div>
                  <hr>
                  <div class="pull-right">
                    <!-- <button class="mr-2 p-0"  mat-stroked-button color="warn" *ngIf="!review" (click)="resetForms()">Annuler</button> -->
                    <button [disabled]="!secondFormGroup.valid" class="p-0" mat-raised-button color="primary"
                      (click)="onSaveOrder()">Ajouter</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="col-12 table-responsive" style="min-height: 130px !important;">
                <table *ngIf="clients?.length >0" class="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Nom</th>
                      <th scope="col">Prenom</th>
                      <th scope="col">Contact</th>
                      <th scope="col">Email</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let client of clients; let i = index">
                      <th scope="row">{{i +1}}</th>
                      <td>{{client?.last_name}}</td>
                      <td>{{client?.first_name}}</td>
                      <td>{{client?.tel}}</td>
                      <td>{{client?.email}}</td>
                      <!-- <td class="text-center">
                        <i class="fa fa-edit"></i>
                      </td> -->
                    </tr>
                  </tbody>
                </table>
              </div>
              <div *ngIf="clients.length > 0" class="pt-4">
                <hr>
                <div class="mt-1 pull-right">
                  <button class="mr-2 mb-1" mat-raised-button color="warn" *ngIf="!review"
                    (click)="resetForms()">Annuler</button>

                  <button class="ml-2 mb-1" mat-raised-button color="primary" *ngIf="!review && !addedMainPerson"
                    matStepperNext>Suivant</button>
                  <button class="ml-2 mb-1" mat-raised-button color="primary"
                    *ngIf="endSave && !resumeOrder && addedMainPerson" matStepperNext>Suivant</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </mat-step>
      <!-- <mat-step [stepControl]="thirstFormGroup" optional *ngIf="add_medical_record && this.ref_order === null && !showPaymentStep">
        <form [formGroup]="thirstFormGroup" class="example-form">
          <ng-template matStepLabel>Dossier médical</ng-template>

          <mat-form-field class="example-full-width ">
            <mat-label>Groupe sanguin</mat-label>
            <mat-select formControlName="gsanginCtrl">
              <mat-option *ngFor="let gs of GS" [value]="gs.value">
                {{gs.value}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="example-full-width ">

            <mat-radio-group aria-label="Select an option" (change)="onChoiceTensionChange($event)"
              formControlName="tensionCtrl">
              <mat-label class="mr-2">Tension artérielle : </mat-label>
              <mat-radio-button class="mr-2" value="Hypo" class="md-primary">Hypo</mat-radio-button>
              <mat-radio-button class="mr-2" value="Hyper" class="md-primary">Hyper</mat-radio-button>
              <mat-radio-button class="ml-2" value="Normale"> Normale </mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="example-full-width mt-2 ">

            <mat-radio-group aria-label="Select an option" class="example-full-width "
              (change)="onChoiceDiabeteChange($event)" name="diabete" formControlName="diabeteCtrl">
              <mat-label class="mr-2">Diabétique : </mat-label>
              <mat-radio-button class="mr-2" value="Oui" class="md-primary">Oui</mat-radio-button>
              <mat-radio-button class="mr-2" value="Non" class="md-primary">Non</mat-radio-button>

            </mat-radio-group>
          </div>

          <mat-form-field class="example-full-width mt-2">
            <mat-label>Hepatite</mat-label>
            <mat-select formControlName="hepatieCtrl">
              <mat-option *ngFor="let he of HE" [value]="he.value">
                {{he.value}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="example-full-width">
            <input matInput placeholder="Autre maladie" formControlName="odiseasesCtrl">
          </mat-form-field>
          <mat-form-field class="example-full-width">
            <textarea matInput placeholder="Allergie" formControlName="allergyCtrl"></textarea>
          </mat-form-field>
          <div class="mt-2">

            <button class="mr-2 mb-1" mat-raised-button color="warn" *ngIf="!review"
              (click)="resetForms()">Annuler</button>
            <button class="mr-2 mb-1" mat-raised-button color="accent" *ngIf="!review"
              matStepperPrevious>Précédent</button>
            <button
              class="ml-2 mb-1" mat-raised-button color="primary" *ngIf="!review" matStepperNext
            >Suivant</button>
          </div>
        </form>
      </mat-step> -->
      <mat-step [stepControl]="grabFormGroup" *ngIf="this.ref_order === null && !showPaymentStep && !addedMainPerson">
        <ng-template matStepLabel>Point de Retrait</ng-template>
        <form [formGroup]="grabFormGroup" class="example-form">
          <mat-form-field appearance="fill" class="example-full-width">
            <mat-label>Lieu de Retrait</mat-label>
            <mat-select formControlName="grabPoint">
              <mat-option *ngFor="let place of grabPoints" placeholder="Lieu de retrait de la carte"
                value="{{place?.name}}" (click)="getPlace(place)">{{place?.name}} - ({{place?.address}})</mat-option>
              <!-- <mat-option value="option">Kipé</mat-option> -->
            </mat-select>
          </mat-form-field>
          <div class="row mb-2" *ngIf="place?.length !==0">
            <div class="col-sm-6 col-xs-12">
              Adresse: {{ place?.address }}
            </div>
            <div class="col-sm-6 col-xs-12">
              Contact: {{ place?.phone }}
            </div>
          </div><br>
          <div class="row">
            <div class="col-12">
              <mat-checkbox name="seen" [(checked)]="seen" (change)="onCheckboxChange($event)">
                J'ai lu et j'accepte
                <a (click)="openDialog1()">
                  <span style="color: blue;">les conditions</span>
                </a>
              </mat-checkbox>

            </div>
          </div>
          <div class="mt-2">
            <button class="mr-2 mb-1" mat-raised-button color="warn" *ngIf="!review"
              (click)="resetForms()">Annuler</button>
            <button class="mr-2 mb-1" mat-raised-button color="accent" *ngIf="!review"
              matStepperPrevious>Précédent</button>
            <button class="ml-2 mb-1" mat-raised-button color="primary" [disabled]="!grabFormGroup.valid || !seen"
              matStepperNext>Suivant</button>
          </div>
        </form>

      </mat-step>
      <mat-step optional>
        <ng-template matStepLabel>Resumé</ng-template>
        <div class="row">
          <div class="col-12 table-responsive">
            <table *ngIf="clients?.length >0" class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Nom</th>
                  <th scope="col">Prenom</th>
                  <th scope="col">Naissance</th>
                  <th scope="col">Genre</th>
                  <th scope="col">Contact</th>
                  <th scope="col">Email</th>
                  <td class="text-center">Action</td>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let client of clients; let i = index">
                  <th scope="row">{{i +1}}</th>
                  <td>{{client?.last_name}}</td>
                  <td>{{client?.first_name}}</td>
                  <td>{{client.birthday | date: "dd/MM/yyyy"}}</td>
                  <td>{{client?.genre}}</td>
                  <td>{{client?.tel}}</td>
                  <td>{{client?.email}}</td>
                  <!-- <td>{{client?.address}}</td> -->
                  <td class="text-center">
                    <i (click)="update(i, client)" *ngIf="!saved" style="color: #033B55;" class="fa fa-edit"></i>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-md-6">
          </div>
          <div class="col-md-6">
            <div class="table-responsive">
              <table class="table">
                <tbody>
                  <tr>
                    <th style="width:50%"> Prix de la carte:</th>
                    <td>{{ (productPrice ? productPrice : 0) | number }} GNF</td>
                  </tr>
                  <tr>
                    <th>Nombre de carte(s): </th>
                    <td>{{ clients?.length ? clients?.length : 0 }} </td>
                  </tr>
                  <tr>
                    <th>Total:</th>
                    <td><strong>{{ (productPrice && clients?.length ? productPrice*clients?.length : 0) | number }}
                        GNF</strong></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="row">

          <div class="col-sm-8">
            En cliquant sur le boutton <span class="ma-couleur">Valider</span> nous vous enverrons les informations de la commande sur:
            email: <span class="ma-couleur">{{this.clients[defaultIndice]?.email}}</span> ,  Téléphone: <span class="ma-couleur">{{this.clients[defaultIndice]?.tel}}</span> <br>
            <div class="text-center" *ngIf="clients?.length > 1">
              Cliquez ici <i class="fa fa-exchange ma-couleur as_link" (click)="switchAddress()"></i> pour changer.
            </div>
          </div>
        </div>
        <div class="mt-3 pull-right">
          <button class="ml-2 mb-1" mat-raised-button color="accent" *ngIf="!review"
            matStepperPrevious>Précédent</button>
          <!-- <button class="ml-2 mb-1" mat-raised-button color="primary" (click)="addCart()" *ngIf="add">Ajouter une nouvelle
            Carte</button> -->
          <button class="ml-2 mb-1" mat-raised-button color="primary" (click)="saveOrder()" [disabled]="saved"
            *ngIf="end">Valider</button>
          <button class="ml-2 mb-1" mat-raised-button color="primary" *ngIf="review" matStepperNext>Suivant</button>
        </div>

      </mat-step>
      <mat-step *ngIf="showPaymentStep">
        <ng-template matStepLabel>Paiement</ng-template>
        <div>
          <h3>Informations sur la commande</h3>
          Prix de la carte: {{ (productPrice ? productPrice : 0) | number }} GNF <br>
          Nombre de carte(s): {{ clients?.length ? clients?.length : 0 }} <br>
          Montant total: <strong>{{ (productPrice && clients?.length ? productPrice*clients?.length : 0) | number }}
            GNF</strong>
        </div>
        <div *ngIf="review">
          Quel type de paiement voulez-vous effectuer?
        </div>
        <div class="mt-2">
          <button class="ml-2 mb-1" mat-raised-button color="primary" (click)="showPayment()" *ngIf="review">Paiement en
            ligne</button>
          <button class="ml-2 mb-1" mat-raised-button color="primary" (click)="showCashPayment()"
            *ngIf="review">Paiement en espèce</button>
        </div>
        <div class="mt-2 text-center" *ngIf="allowPayment">

          <p class="" style="font-size: 20px; font-weight: bold;">Mode de payement</p>


          <button type="button" (click)="payement()" class="mr-3"
            style="background-image: url(../../../../assets/img/orange.png) !important;background-size: cover; width: 98px; height: 75px;"></button>

          <button type="button" (click)="payementVisa()" class="mr-3"
            style="background-image: url(../../../../assets/img/card-visa.png) !important;background-size: cover; width: 98px; height: 75px;"></button>

        </div>
        <div class="mt-2 text-center" *ngIf="cashPayment">

          <p class="" style="font-size: 20px; font-weight: bold;">Paiement en espèce</p>
          <div>
            Vous avez choisi d'effectuer le paiement en espèce. <br>
            Rendez-vous dans le point de retrait
            que vous avez choisi(<strong>{{ clients[0]?.grab_point }}</strong>) pour finaliser votre commande. <br>
            Votre numéro de commande est le <strong>{{ transaction_number }}</strong>; il vous sera demandé pour
            la suite du processus.
          </div>
          <div>
            <button mat-raised-button (click)="sendFinalMessage()">Terminer</button>
          </div>
        </div>

      </mat-step>
    </mat-horizontal-stepper>
    <div *ngIf="alreadyPayed">
      <h1 class="text-center pt-5">
        <!-- Vous avez déjà finalisé votre commande et effectué le paiement. Contactez nous via le 625.000.005 ou nous envoyer un email à <span style="color: blue;">contact@teliya.net</span> pour tout besoin. -->
        Bravo! votre commande a été finalisée. <br>
      </h1>
      <p class="text-center">Pour tout besoin contactez nous au 625 000 005 ou sur <span
          style="color: blue;">contact@teliya.net</span></p>
    </div>

  </div>
</section>
<div class="container">

  <div class="text-center mb-2 ">

    <!--Grid row-->
    <div class="row pt-3">

      <!--Grid column-->
      <div class="col-md-3 mb-4">
        <span> <img class="bd-placeholder-img" width="70" height="60" src="../../../../assets/img/commande-bas-1.png"
            alt=""></span>
        <div class="text-center">
          <h4 class="font-weight-bold mb-2 size-20">Meilleure offre d'assurance maladie</h4>
        </div>
      </div>
      <!--Grid column-->

      <!--Grid column-->
      <div class="col-md-3 mb-4">
        <span> <img class="bd-placeholder-img" width="70" height="60" src="../../../../assets/img/commande-bas-2.png"
            alt=""></span>
        <div class="text-center">
          <h4 class="font-weight-bold mb-2 size-20">Création de votre dossier médical partagé</h4>

        </div>
      </div>
      <!--Grid column-->

      <!--Grid column-->
      <div class="col-md-3 mb-4">
        <span> <img class="bd-placeholder-img" width="70" height="60" src="../../../../assets/img/commande-bas-3.png"
            alt=""></span>
        <div class="text-center">
          <h4 class="font-weight-bold size-20 mb-2">Un portemonnaie de santé intégré</h4>
        </div>
      </div>
      <!--Grid column-->

      <!--Grid column-->
      <div class="col-md-3 mb-4">
        <span> <img class="bd-placeholder-img" width="70" height="60" src="../../../../assets/img/commande-bas-4.png"
            alt=""></span>
        <div class="text-center">
          <h4 class="font-weight-bold size-20 mb-2">Vos données de santé sont sécurisées</h4>

        </div>
      </div>
      <!--Grid column-->

    </div>
    <!--Grid row-->
  </div>



</div>
