import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-all-insurance-offers',
  templateUrl: './all-insurance-offers.component.html',
  styleUrls: ['./all-insurance-offers.component.css']
})
export class AllInsuranceOffersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
