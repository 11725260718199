import { Component, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-offerpart',
  templateUrl: './offerpart.component.html',
  styleUrls: ['./offerpart.component.css']
})
export class OfferpartComponent implements OnInit {

  constructor(
    private globalSvc: GlobalService,
    private router: Router
  ) { 
    // this.router.events.subscribe((event: Event) => {
    //   if (event instanceof NavigationEnd) {
        this.globalSvc.setTitle('La Carte de Santé');
    //   }
    // });
  }

  ngOnInit(): void {
    this.globalSvc.fixDisplay();
  }

}
