<div class="container">
  <div>
    <div class="pt-3 pb-3">
      <h2 class="text-center" *ngIf="hasCard === null && !alreadyPayed">
        En seulement 2 minutes, réunissons les informations nécessaires
        pour trouver une assurance maladie adaptée à vos besoins
      </h2>
      <div class="text-center" *ngIf="!alreadyPayed && ref_insurance === null">
        <mat-radio-group aria-label="">
          <mat-label class="mb-2">Avez vous une carte de santé Teliya?</mat-label><br>
          <mat-radio-button value="hasCard" (click)="setHasCard(true)">Oui
          </mat-radio-button>
          <mat-radio-button value="hasNoCard" (click)="setHasCard(false)">Non
          </mat-radio-button>
        </mat-radio-group>
      </div>


      <div class="text-center" *ngIf="hasCard && !hideCardForm && !alreadyPayed">
        <form [formGroup]="findCardFormGroup" class="">

          <mat-form-field class="">
            <mat-label>Numéro de la carte Teliya</mat-label>
            <input matInput placeholder="Entrez le numero de la carte" name="cardNumber" formControlName="cardNumber"
              minlength="8" maxlength="12" required>
          </mat-form-field>
          <div class="mt-2">
            <button class="ml-2 mb-1" mat-raised-button color="primary" (click)="verifyCardNumber()"
              [disabled]="findCardFormGroup.invalid">Valider</button>
          </div>
        </form>
      </div>

      <mat-horizontal-stepper *ngIf="this.hasCard !== null && (!this.hasCard  || this.cardFound) && !alreadyPayed"
        [selectedIndex]="defaultIndex" #stepper [linear]="isLinear"
      >
        <mat-step [completed]="person" label="Membres" *ngIf="this.ref_insurance === null && !showPaymentStep">

          <ng-template matStepLabel>Données personelles</ng-template>
          <div class="row p-0">
            <div class="col-md-6 p-0">
              <form [formGroup]="firstFormGroup" class="" *ngIf="!person">
                <div class="row p-0">
                  <div class="col-md-8 offset-md-2 col-xs-12">
                    <h4 class="text-center">
                      Personne principale
                    </h4>
                  </div>
                </div>
                <div class="row p-0">
                  <div class="col-md-5 col-xs-12">

                    <mat-form-field class="example-full-width ">
                      <input matInput placeholder="Prénom(s)" name="first" formControlName="firstname" required>
                    </mat-form-field>

                    <mat-form-field class="example-full-width">
                      <input matInput placeholder="Nom" formControlName="lastname" name="second" required>
                    </mat-form-field>

                    <mat-form-field class="example-full-width">
                      <mat-label>Genre</mat-label>
                      <mat-select required name="genre" formControlName="gender">
                        <mat-option *ngFor="let gr of Genre" [value]="gr.value">
                          {{gr.value}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field class="example-full-width">
                      <mat-label>Date de naissance</mat-label>
                      <input matInput [matDatepicker]="picker" [max]="maxAvailableDate" placeholder="JJ/MM/AAAA"
                        formControlName="birthday" required>
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                  </div>
                  <div class="col-md-5 col-xs-12">
                    <mat-form-field class="example-full-width">
                      <input matInput type="number" placeholder="n° téléphone" formControlName="phone" required
                        minlength="9" maxlength="12">
                    </mat-form-field>

                    <mat-form-field class="example-full-width">
                      <input matInput placeholder="email" formControlName="email"
                        pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}" required>
                    </mat-form-field>

                    <mat-form-field class="example-full-width">
                      <input matInput placeholder="Adresse" formControlName="address">
                    </mat-form-field>

                    <div class="mt-2">
                      <button class="ml-2 mb-1" [disabled]="firstFormGroup.invalid" mat-raised-button color="primary"
                        (click)="addMainPerson()" *ngIf="!person">Ajouter</button>
                    </div>
                  </div>
                </div>
              </form>
              <form [formGroup]="familyFormGroup" class="" *ngIf="person">
                <div class="row p-0">
                  <div class="col-md-8 offset-md-2 col-xs-12">
                    <h4 class="text-center">
                      Membre(s) bénéficiaire(s)
                    </h4>
                  </div>
                </div>
                <div class="row p-0">
                  <div class="col-md-5 col-xs-12">
                    <mat-form-field class="example-full-width" *ngIf="person">
                      <mat-label>Relation</mat-label>
                      <mat-select required name="relation" formControlName="relationship">
                        <mat-option *ngFor="let gr of RELATION" [value]="gr.value">
                          {{gr.value}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field class="example-full-width ">
                      <input matInput placeholder="Prénom(s)" name="first" formControlName="firstname" required>
                    </mat-form-field>

                    <mat-form-field class="example-full-width">
                      <input matInput placeholder="Nom" formControlName="lastname" name="second" required>
                    </mat-form-field>

                  </div>
                  <div class="col-md-5 col-xs-12">
                    <mat-form-field class="example-full-width">
                      <mat-label>Genre</mat-label>
                      <mat-select required name="genre" formControlName="gender">
                        <mat-option *ngFor="let gr of Genre" [value]="gr.value">
                          {{gr.value}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field class="example-full-width">
                      <mat-label>Date de naissance</mat-label>
                      <input matInput [matDatepicker]="picker" [max]="maxAvailableDate" placeholder="JJ/MM/AAAA"
                        formControlName="birthday" required>
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field class="example-full-width">
                      <input matInput type="number" placeholder="n° téléphone" formControlName="phone" minlength="9"
                        maxlength="12">
                    </mat-form-field>

                    <div class="mt-2">
                      <button class="ml-2 mb-1" [disabled]="familyFormGroup.invalid" mat-raised-button color="primary"
                        (click)="addFamilyMember()">Ajouter</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="col-md-6">
              <div class="col-12 table-responsive" style="min-height: 130px !important;">
                <table *ngIf="person || family?.length > 0" class="table table-striped">
                  <thead>
                    <tr class="text-center">
                      <th scope="col">#</th>
                      <th scope="col">Nom</th>
                      <th scope="col">Prenom</th>
                      <th scope="col">Contact</th>
                      <th scope="col">Email</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngIf="person" class="text-center">
                      <th scope="row">1</th>
                      <td>{{person?.lastname}}</td>
                      <td>{{person?.firstname}}</td>
                      <td>{{person?.phone}}</td>
                      <td>{{person?.email}}</td>

                    </tr>
                    <tr *ngFor="let client of family; let i = index" class="text-center">
                      <th scope="row">{{i +2}}</th>
                      <td>{{client?.lastname}}</td>
                      <td>{{client?.firstname}}</td>
                      <td>{{client?.phone}}</td>
                      <td>{{client?.email ? client?.email : '---' }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div *ngIf="person || family.length > 0" class="pt-4">
                <hr>
                <div class="mt-1 pull-right">
                  <button class="mr-2 mb-1" mat-raised-button color="warn">Annuler</button>
                  <button class="ml-2 mb-1" mat-raised-button color="primary" matStepperNext>Suivant</button>
                </div>
              </div>

            </div>
          </div>
        </mat-step>
        <mat-step [stepControl]="grabFormGroup" *ngIf="this.ref_insurance === null && !showPaymentStep">
          <ng-template matStepLabel>Point de Retrait</ng-template>
          <form [formGroup]="grabFormGroup" class="example-form">
            <mat-form-field appearance="fill" class="example-full-width">
              <mat-label>Lieu de Retrait</mat-label>
              <mat-select formControlName="grabPoint">
                <mat-option *ngFor="let place of grabPoints" placeholder="Lieu de retrait de la carte"
                  value="{{place?.name}}" (click)="getPlace(place)">{{place?.name}} - ({{place?.address}})</mat-option>
                <!-- <mat-option value="option">Kipé</mat-option> -->
              </mat-select>
            </mat-form-field>
            <div class="row mb-2" *ngIf="place?.length !==0">
              <div class="col-sm-6 col-xs-12">
                Adresse: {{ place?.address }}
              </div>
              <div class="col-sm-6 col-xs-12">
                Contact: {{ place?.phone }}
              </div>
            </div><br>
            <div class="row">
              <div class="col-12">
                <mat-checkbox name="seen" [(checked)]="seen" (change)="onCheckboxChange1($event)">
                  J'ai lu et j'accepte
                  <a (click)="openDialog1()">
                    <span style="color: blue">les conditions</span>
                  </a>
                </mat-checkbox>

              </div>
            </div>
            <div class="mt-2">

              <button class="mr-2 mb-1" mat-raised-button color="accent" matStepperPrevious>Précédent</button>
              <button class="ml-2 mb-1" mat-raised-button color="primary" [disabled]="!grabFormGroup.valid || !seen"
               matStepperNext>Suivant</button>
            </div>
          </form>
        </mat-step>
        <!-- dossier médical -->
        <mat-step label="Questionnaire médical" *ngIf="this.ref_insurance === null && !showPaymentStep" optional>
          <!-- <div class="ml-1 text-center"> -->
          <div class="row p-0">
            <div class="col-md-6 p-0">
              <div class="row p-0">
                <div class="col-md-12 col-xs-12">
                  <mat-radio-group aria-label="">
                    <div class="row p-0">
                      <div class="col-md-6">
                        <mat-radio-button value="fillOnline" (click)="setFillMethod(true)" checked>Remplir le
                          questionnaire maintenant
                        </mat-radio-button>
                      </div>
                      <div class="col-md-6">
                        <mat-radio-button value="fillAtGrab" (click)="setFillMethod(false)">Remplir le questionnaire
                          au point de prestation
                        </mat-radio-button>
                      </div>
                    </div>
                  </mat-radio-group>
                </div>
              </div>
            </div>
          </div>


          <!-- </div> -->
          <div *ngIf="fillMedicalRecord">
            <div class="row">
              <div class="col-12 table-responsive">
                <table *ngIf="person || family?.length >0" class="table table-striped">
                  <thead>
                    <tr class="text-center">
                      <th scope="col">#</th>
                      <th scope="col">Nom</th>
                      <th scope="col">Prenom</th>
                      <th scope="col">Naissance</th>
                      <!-- <th scope="col">Genre</th>
                      <th scope="col">Contact</th>
                      <th scope="col">Email</th> -->
                      <td class="text-center">Action</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngIf="person" class="text-center">
                      <th scope="row"> <i *ngIf="selectedMember === -1" style="color: green;" class="fa fa-asterisk"
                          aria-hidden="true"></i> 1</th>
                      <td>{{person?.lastname}}</td>
                      <td>{{person?.firstname}}</td>
                      <td>{{person.birthday | date: "dd/MM/yyyy"}}</td>
                      <!-- <td>{{person?.gender}}</td>
                      <td>{{person?.phone}}</td>
                      <td>{{person?.email}}</td>
                      <td>{{person?.address}}</td> -->
                      <td class="text-center">
                        <i (click)="setConcernedMedRecord(-1,'principal')" style="color: blue;"
                          *ngIf="!saved && !person?.hasMedical" class="fa fa-medkit" aria-hidden="true"></i>
                        <i *ngIf="!saved && person?.hasMedical" (click)="setConcernedMedRecord(-1,'principal')" style="color: green;" class="fa fa-check"
                          aria-hidden="true"></i>
                      </td>
                    </tr>
                    <tr *ngFor="let item of family; let i = index" class="text-center">
                      <th scope="row"><i *ngIf="selectedMember === i" style="color: green;" class="fa fa-asterisk"
                          aria-hidden="true"></i> {{i+2}}</th>
                      <td>{{item?.lastname}}</td>
                      <td>{{item?.firstname}}</td>
                      <td>{{item.birthday | date: "dd/MM/yyyy"}}</td>
                      <!-- <td>{{item?.gender}}</td>
                      <td>{{item?.phone}}</td>
                      <td>{{item?.email ? item?.email : '---' }}</td>
                      <td>{{item?.address}}</td> -->
                      <td class="text-center">
                        <i (click)="setConcernedMedRecord(i,item?.relationship)" style="color: blue;"
                          *ngIf="!saved && !item?.hasMedical" class="fa fa-medkit" aria-hidden="true"></i>
                        <i *ngIf="!saved && item?.hasMedical" (click)="setConcernedMedRecord(i,item?.relationship)" class="fa fa-check" aria-hidden="true"
                          style="color: green;"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <hr>


           <mat-accordion *ngIf="!hideMedRecordForm">
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Traitement & suivi médical
                  </mat-panel-title>
                  <mat-panel-description>
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="row p-0">
                  <div class="col-md-6">
                    <form [formGroup]="traitementSuiviFormGroup" class="">

                      <mat-form-field class="example-full-width">
                        <mat-label>Dernière consultation de votre médecin traitant ?</mat-label>
                        <input matInput [matDatepicker]="picker2" [max]="maxAvailableDate" placeholder="Date de la dernière consultation"
                          formControlName="lastMeetPhysician">
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                      </mat-form-field>

                      <mat-form-field class="example-full-width ">
                        <mat-label>Pour quelle raison?</mat-label>
                        <input matInput placeholder="Raison de la visite" name="reason" formControlName="reasonMeetPhysician">
                      </mat-form-field>
                    </form>
                    <form [formGroup]="traitementSuiviFormGroup2" class="">
                      <mat-form-field class="example-full-width ">
                        <mat-label>Quelle a été votre dernière vaccination ?</mat-label>
                        <input matInput placeholder="Dernière vaccination" name="vaccin" formControlName="lastVaccin">
                      </mat-form-field>
                      <mat-form-field class="example-full-width">
                        <mat-label> A quelle date? </mat-label>
                        <input matInput [matDatepicker]="picker4" [max]="maxAvailableDate" placeholder="Date de vaccination"
                          formControlName="vaccinationDate">
                        <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                        <mat-datepicker #picker4></mat-datepicker>
                      </mat-form-field>
                      <!-- <div class="mt-2">
                        <button class="mr-2 mb-1" mat-raised-button color="accent" (click)="scrollToTop()"
                          matStepperPrevious>Précédent</button>
                        <button class="ml-2 mb-1" mat-raised-button color="primary" (click)="nextStep()">Suivant</button>
                      </div> -->
                    </form>
                  </div>
                  <div class="col-md-6">
                    <form [formGroup]="traitementSuiviFormGroup1" class="">
                      <mat-form-field class="example-full-width">
                        <mat-label>Votre dernière consultation d'un dentiste ? </mat-label>
                        <input matInput [matDatepicker]="picker3" [max]="maxAvailableDate"
                          placeholder="Date de la dernière consultation" formControlName="lastMeetDentist">
                        <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                        <mat-datepicker #picker3></mat-datepicker>
                      </mat-form-field>

                      <mat-form-field class="example-full-width">
                        <mat-label>Pour quelle raison?</mat-label>
                        <mat-select name="raison" formControlName="reasonMeetDentist">
                          <mat-option value="Urgence">Urgence</mat-option>
                          <mat-option value="Soins dentaires">Soins dentaires</mat-option>
                          <mat-option value="Contrôle">Contrôle</mat-option>
                        </mat-select>
                      </mat-form-field>
                      <div class="row">
                        <div class="col-sm-6">
                          <mat-label>Avez-vous eu un contrôle de votre vue au cours des 2 dernières années ?</mat-label>
                          <br>
                        </div>
                        <div class="col-sm-6">
                          <mat-radio-group aria-label="" formControlName="hadEyesightControlLast2Years">
                            <mat-radio-button value="true">Oui
                            </mat-radio-button>
                            <mat-radio-button value="false">Non
                            </mat-radio-button>
                          </mat-radio-group>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-6">
                          <mat-label>Avez-vous eu un test de l’audition au cours des 2 dernières années?</mat-label><br>
                        </div>
                        <div class="col-sm-6">
                          <mat-radio-group aria-label="" formControlName="hadHearingTestLast2Years">
                            <mat-radio-button value="true">Oui
                            </mat-radio-button>
                            <mat-radio-button value="false">Non
                            </mat-radio-button>
                          </mat-radio-group>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </mat-expansion-panel>
              <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Etat Actuel
                  </mat-panel-title>
                  <mat-panel-description>
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <form [formGroup]="currentHealthStateFormGroup" class="">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="row mb-2">
                        <div class="col-sm-6">
                          <mat-label>Avez-vous une hypertension artérielle ? </mat-label><br>
                        </div>
                        <div class="col-sm-6">
                          <mat-radio-group aria-label="" formControlName="arterialHypertension">
                            <mat-radio-button value="Oui">Oui
                            </mat-radio-button>
                            <mat-radio-button value="Non">Non
                            </mat-radio-button>
                            <mat-radio-button value="Ne sais pas">Ne sais pas
                            </mat-radio-button>
                          </mat-radio-group><br>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-sm-6">
                          <mat-label>Avez-vous de l’artérite ?</mat-label><br>
                        </div>
                        <div class="col-sm-6">
                          <mat-radio-group aria-label="" formControlName="arthritis">
                            <mat-radio-button value="Oui">Oui
                            </mat-radio-button>
                            <mat-radio-button value="Non">Non
                            </mat-radio-button>
                            <mat-radio-button value="Ne sais pas">Ne sais pas
                            </mat-radio-button>
                          </mat-radio-group><br>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-sm-6">
                          <mat-label>Avez-vous un diabète ?</mat-label><br>
                        </div>
                        <div class="col-sm-6">
                          <mat-radio-group aria-label="" formControlName="diabetes">
                            <mat-radio-button value="Oui">Oui
                            </mat-radio-button>
                            <mat-radio-button value="Non">Non
                            </mat-radio-button>
                            <mat-radio-button value="Ne sais pas">Ne sais pas
                            </mat-radio-button>
                          </mat-radio-group><br>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="row mb-2">
                        <div class="col-sm-6">
                          <mat-label>Avez-vous une Hépatite ?</mat-label><br>
                        </div>
                        <div class="col-sm-6">
                          <mat-radio-group aria-label="" formControlName="hepatitis">
                            <mat-radio-button value="Oui" (click)="hepatitisState(true)">Oui
                            </mat-radio-button>
                            <mat-radio-button value="Non" (click)="hepatitisState(false)">Non
                            </mat-radio-button>
                            <mat-radio-button value="Ne sais pas" (click)="hepatitisState(false)">Ne sais pas
                            </mat-radio-button>
                          </mat-radio-group><br>
                        </div>
                      </div>
                      <div class="row mb-2" *ngIf="hep">
                        <div class="col-sm-6">
                          <mat-label>Si Oui, Préciser ?</mat-label><br>
                        </div>
                        <div class="col-sm-6">
                          <mat-radio-group aria-label="" formControlName="hepatitisType">
                            <mat-radio-button value="A">A
                            </mat-radio-button>
                            <mat-radio-button value="B">B
                            </mat-radio-button>
                            <mat-radio-button value="C">C
                            </mat-radio-button>
                            <mat-radio-button value="D">D
                            </mat-radio-button>
                            <mat-radio-button value="E">E
                            </mat-radio-button>
                          </mat-radio-group><br>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-sm-6">
                          <mat-label>Consommez-vous du vin, de la bière ou toute autre boisson alcoolisée même
                            occasionnellement
                            ?</mat-label><br>
                        </div>
                        <div class="col-sm-6">
                          <mat-radio-group aria-label="" formControlName="drinkBeerOrWine">
                            <mat-radio-button value="Oui">Oui
                            </mat-radio-button>
                            <mat-radio-button value="Non">Non
                            </mat-radio-button>
                          </mat-radio-group><br>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-sm-6">
                          <mat-label>Alimentation : suivez-vous un régime particulier ? </mat-label><br>
                        </div>
                        <div class="col-sm-6">
                          <mat-radio-group aria-label="" formControlName="particularDiet">
                            <mat-radio-button value="Oui">Oui
                            </mat-radio-button>
                            <mat-radio-button value="Non">Non
                            </mat-radio-button>
                          </mat-radio-group><br>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </mat-expansion-panel>
              <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Antécédents Familiaux
                  </mat-panel-title>
                  <mat-panel-description>
                  </mat-panel-description>
                </mat-expansion-panel-header>

                <form [formGroup]="familyHistoryFormGroup" class="">
                  <div class="row">
                    <div class="col-12 text-center">
                      <p>Un membre de votre famille a déjà eu:</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="row mb-2">
                        <div class="col-sm-6">
                          <p>Un infarctus du myocarde ou une mort subite?</p>
                        </div>
                        <div class="col-sm-6">
                          <mat-checkbox class="example-margin mr-2" formControlName="hadFatherInfarctionOrSuddenDeath55">
                            Père
                          </mat-checkbox>
                          <mat-checkbox class="example-margin mr-2" formControlName="hadMotherInfarctionOrSuddenDeath65">
                            Mère
                          </mat-checkbox>
                          <mat-checkbox class="example-margin mr-2" formControlName="hadBrotherInfarctionOrSuddenDeath55">
                            Frère</mat-checkbox>
                          <mat-checkbox class="example-margin mr-2" formControlName="hadSisterInfarctionOrSuddenDeath65">
                            Soeur
                          </mat-checkbox>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-sm-6">
                          <p>Un Accident Vasculaire cérébrale (AVC) avant l'âge de 45ans?</p>
                        </div>
                        <div class="col-sm-6">
                          <mat-checkbox class="example-margin mr-2" formControlName="hadFatherAVCBefore45">Père
                          </mat-checkbox>
                          <mat-checkbox class="example-margin mr-2" formControlName="hadMotherAVCBefore45">Mère
                          </mat-checkbox>
                          <mat-checkbox class="example-margin mr-2" formControlName="hadBrotherAVCBefore45">Frère
                          </mat-checkbox>
                          <mat-checkbox class="example-margin mr-2" formControlName="hadSisterAVCBefore45">Soeur
                          </mat-checkbox>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-sm-6">
                          <p>Un problème de Tension artérielle?</p>
                        </div>
                        <div class="col-sm-6">
                          <mat-radio-group aria-label="" formControlName="arterialTensionType">
                            <mat-radio-button value="Hyper">Hyper
                            </mat-radio-button>
                            <mat-radio-button value="Hypo">Hypo
                            </mat-radio-button>
                          </mat-radio-group><br>
                          <mat-checkbox class="example-margin mr-2" formControlName="hasFatherArterialTension">Père
                          </mat-checkbox>
                          <mat-checkbox class="example-margin mr-2" formControlName="hasMotherArterialTension">Mère
                          </mat-checkbox>
                          <mat-checkbox class="example-margin mr-2" formControlName="hasBrotherArterialTension">Frère
                          </mat-checkbox>
                          <mat-checkbox class="example-margin mr-2" formControlName="hasSisterArterialTension">Soeur
                          </mat-checkbox>
                        </div>
                      </div>

                    </div>

                    <div class="col-md-6">
                      <div class="row mb-2">
                        <div class="col-sm-6">
                          <p>Du diabète?</p>
                        </div>
                        <div class="col-sm-6">
                          <mat-checkbox class="example-margin mr-2" formControlName="hasFatherDiabetes">Père</mat-checkbox>
                          <mat-checkbox class="example-margin mr-2" formControlName="hasMotherDiabetes">Mère</mat-checkbox>
                          <mat-checkbox class="example-margin mr-2" formControlName="hasBrotherDiabetes">Frère
                          </mat-checkbox>
                          <mat-checkbox class="example-margin mr-2" formControlName="hasSisterDiabetes">Soeur</mat-checkbox>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-sm-6">
                          <p> Une dépression?</p>
                        </div>
                        <div class="col-sm-6">
                          <mat-checkbox class="example-margin mr-2" formControlName="hadFatherDepression">Père
                          </mat-checkbox>
                          <mat-checkbox class="example-margin mr-2" formControlName="hadMotherDepression">Mère
                          </mat-checkbox>
                          <mat-checkbox class="example-margin mr-2" formControlName="hadBrotherDepression">Frère
                          </mat-checkbox>
                          <mat-checkbox class="example-margin mr-2" formControlName="hadSisterDepression">Soeur
                          </mat-checkbox>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-sm-6">
                          <p> Un cancer?</p>
                        </div>
                        <div class="col-sm-6">
                          <mat-checkbox class="example-margin mr-2" formControlName="hadFatherCancerOrOtheDiseases">Père
                          </mat-checkbox>
                          <mat-checkbox class="example-margin mr-2" formControlName="hadMotherCancerOrOtheDiseases">Mère
                          </mat-checkbox>
                          <mat-checkbox class="example-margin mr-2" formControlName="hadBrotherCancerOrOtheDiseases">Frère
                          </mat-checkbox>
                          <mat-checkbox class="example-margin mr-2" formControlName="hadSisterCancerOrOtheDiseases">Soeur
                          </mat-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </mat-expansion-panel>
              <div class="mt-2">
                <!-- <button class="mr-2 mb-1" mat-raised-button (click)="previousStep()"
                  color="accent">Précédent</button> -->
                <button class="mb-1" (click)="addMedicalRecord()"  mat-raised-button color="primary"
                  [disabled]="!this.traitementSuiviFormGroup.dirty && !this.currentHealthStateFormGroup.dirty && !this.familyHistoryFormGroup.dirty"
                >Valider</button>
              </div>
            </mat-accordion>
          </div>
          <div class="mt-3" class="pull-right">
            <button class="mr-2 mb-1" mat-raised-button color="accent" (click)="scrollToTop()"
              matStepperPrevious>Précédent</button>
            <button class="ml-2 mb-1" mat-raised-button color="primary" (click)="scrollToTop()"
              matStepperNext [disabled]="!canGoToResume"
            >Suivant</button>
          </div>
        </mat-step>


        <mat-step>
          <ng-template matStepLabel>Resumé</ng-template>
          <div class="row">
            <div class="col-12 table-responsive">
              <table *ngIf="person || family?.length >0" class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Nom</th>
                    <th scope="col">Prenom</th>
                    <th scope="col">Naissance</th>
                    <!-- <th scope="col">Genre</th>
                    <th scope="col">Contact</th>
                    <th scope="col">Email</th> -->
                    <td class="text-center">Action</td>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngIf="person">
                    <th scope="row">1</th>
                    <td>{{person?.lastname}}</td>
                    <td>{{person?.firstname}}</td>
                    <td>{{person.birthday | date: "dd/MM/yyyy"}}</td>
                    <!-- <td>{{person?.gender}}</td>
                    <td>{{person?.phone}}</td>
                    <td>{{person?.email}}</td>
                    <td>{{person?.address}}</td> -->
                    <td class="text-center">
                      <i (click)="update(-1, person)" style="color: blue;" *ngIf="!saved" class="fa fa-edit"></i>
                    </td>
                  </tr>
                  <tr *ngFor="let item of family; let i = index">
                    <th scope="row">{{i +2}}</th>
                    <td>{{item?.lastname}}</td>
                    <td>{{item?.firstname}}</td>
                    <td>{{item.birthday | date: "dd/MM/yyyy"}}</td>
                    <!-- <td>{{item?.gender}}</td>
                    <td>{{item?.phone}}</td>
                    <td>{{item?.email}}</td>
                    <td>{{item?.address}}</td> -->
                    <td class="text-center">
                      <i (click)="update(i, item)" style="color: blue;" *ngIf="!saved" class="fa fa-edit"></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-md-6 offset-md-6 col-xs-12">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Nombre d'élément(s)</th>
                      <th>Prix Unitaire</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Assurance</td>
                      <td>{{numberOfPerson}} personne(s)</td>
                      <td>{{insuranceFees | number}}</td>
                      <td>{{(numberOfPerson * insuranceFees) | number}}</td>
                    </tr>
                    <tr>
                      <td>Carte</td>
                      <td>{{ numberOfCard}}</td>
                      <td>{{cardPrice | number}}</td>
                      <td>{{(numberOfCard * cardPrice) | number}}</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td><strong>Total</strong></td>
                      <td><strong>{{totalAmount | number}} {{globalSvc?.currency}}</strong></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="mt-2" *ngIf="!end">

            <button class="ml-2 mb-1" mat-raised-button color="accent" (click)="scrollToTop()" matStepperPrevious
            >Précédent</button>
            <button class="ml-2 mb-1" mat-raised-button color="primary" (click)="saveData()"
              [disabled]="saved">Valider</button>

          </div>
          <div class="mt-2 pull-right" *ngIf="end">

            <button class="ml-2 mb-1" mat-raised-button color="primary" (click)="scrollToTop()"
              matStepperNext>Suivant</button>

          </div>

        </mat-step>
        <mat-step label="Paiement" *ngIf="showPaymentStep">
          <div class="row">
            <h3>Recap tarifaire</h3>
            <div class="col-sm-12">
              <table id="tbPartners" class="table table-hover" style="margin-top: 20px;">
                <thead>
                  <tr>
                    <th></th>
                    <th>Nombre d'élément(s)</th>
                    <th>Prix Unitaire</th>
                    <th>Total</th>
                    <!-- <th>Contact</th>
                      <th>Statut</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Assurance</td>
                    <td>{{numberOfPerson}} personne(s)</td>
                    <td>{{insuranceFees | number}}</td>
                    <td>{{(numberOfPerson * insuranceFees) | number}}</td>
                  </tr>
                  <tr>
                    <td>Carte</td>
                    <td>{{ numberOfCard}}</td>
                    <td>{{cardPrice | number}}</td>
                    <td>{{(numberOfCard * cardPrice) | number}}</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td><strong>Total</strong></td>
                    <td><strong>{{totalAmount | number}}</strong></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- <div class="row">
              <div class="col-sm-6">
                Frais d'assurance par personne: <strong>{{insuranceFees | number}} {{globalSvc?.currency}}</strong>
                Nombre de personnes: {{numberOfPerson}}
              </div>
              <div class="col-sm-6">
                Prix de la carte: {{cardPrice | number}} {{globalSvc?.currency}} <br>
                Nombre de carte(s): {{ numberOfCard}} <br>
              </div>
            </div> -->
            <!-- <hr> -->
            <!-- Montant total à payer: <strong>{{totalAmount | number}} {{globalSvc?.currency}}</strong> -->
          </div>
          <div>
            Quel type de paiement voulez-vous effectuer?
          </div>
          <div class="row mt-2">
            <div class="col-sm-4 col-xs-6 offset-sm-1">

            </div>
            <div class="col-sm-4 col-xs-6 offset-sm-2">

            </div>
            <button class="ml-2 mb-1" mat-raised-button color="primary" (click)="showPayment()">
              Paiement en ligne
            </button>
            <button class="ml-2 mb-1" mat-raised-button color="primary" (click)="showCashPayment()">
              Paiement en espèce
            </button>
          </div>
          <div class="mt-2 text-center" *ngIf="allowPayment">

            <p class="" style="font-size: 20px; font-weight: bold;">Mode de payement</p>


            <button type="button" (click)="payement()" class="mr-3"
              style="background-image: url(../../../../assets/img/orange.png) !important;background-size: cover; width: 98px; height: 75px;"></button>

            <button type="button" (click)="payementVisa()" class="mr-3"
              style="background-image: url(../../../../assets/img/card-visa.png) !important;background-size: cover; width: 98px; height: 75px;"></button>

          </div>
          <div class="mt-2 text-center" *ngIf="cashPayment">

            <p class="" style="font-size: 20px; font-weight: bold;">Paiement en espèce</p>
            <div>
              Vous avez choisi d'effectuer le paiement en espèce. Rendez-vous dans le point de retrait
              que vous avez choisi pour finaliser votre commande.
              Votre numéro de Transaction est le <strong>{{transaction_number}}</strong>; il vous sera demandé pour
              la suite du processus.
            </div>
            <div>
              <button mat-raised-button (click)="sendFinalMessage()">Terminer</button>
              <!-- <button mat-raised-button routerLink="/insurance-request-notification">Terminer</button> -->
            </div>
          </div>
        </mat-step>

      </mat-horizontal-stepper>
      <div *ngIf="alreadyPayed">
        <h1 class="text-center pt-5">
          Vous avez déjà finalisé votre demande d'assurance et effectué le paiement. Contactez nous via le 625.000.005 ou nous envoyer un email à <span style="color: blue;">contact@teliya.net</span> pour tout besoin.
        </h1>
      </div>
    </div>
  </div>
</div>
