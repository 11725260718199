<div class="panel panel-default m-5">
  <section class="content">
    <h2 mat-dialog-title class="uppercase" style="text-align: center;">POLITIQUE DE CONFIDENTIALITE</h2>
    <div style="max-height: 300px; overflow: auto;">
      <mat-dialog-content class="mat-typography">
        <p>
          L’application Mobile Teliya Santé est éditée par <strong>Gamma Concept S.A</strong>. société anonyme,
          immatriculée au
          registre
          de commerce et du crédit mobilier de Kaloum sous le numéro RCCM/GC-KAL/05755/2004 dont le siège social
          est situé à Taouyah, Commune de Ratoma Conakry Guinée.
          L’application propose des services destinés principalement aux détenteurs de la carte de santé Teliya.<br>
        </p>
        <h4>
          <strong><u>Responsables du traitement de données</u> </strong>
        </h4>
        <p>
          Gamma Concept SA est responsable du traitement des données relatives à la Solution Teliya Santé au titre de la
          création
          de leur compte et de l’utilisation de la solution Teliya et des services. <br>
        </p>
        <p>
          Les services de la carte Teliya santé comprennent des fonctionnalités permettant aux professionnels de santé
          et aux
          organismes payeurs d’accéder aux données de l’utilisateur et/ou de collecter des données directement auprès
          des utilisateurs et dans ce cadre l’utilisateur est informé que : <br>
        </p>
        <ul>
          <li>
            Chaque professionnel de santé est responsable de traitement des données relatives aux utilisateurs dans le
            cadre de la gestion de la prise de rendez-vous et de la délivrance des services de soins de santé ;
          </li>
          <li>
            Chaque centre de santé ou chaque structure de soins est responsable de traitement des données relatives aux
            utilisateurs dans le cadre de la gestion de la prise de rendez-vous et de la délivrance des services de
            soins de santé ;
          </li>
          <li>
            Chaque organisme payeur est responsable de traitement des données relatives aux utilisateurs patients dans
            le cadre de la gestion de son assurance maladie ;
          </li>
        </ul>
        <p>
          Gamma Concept S.A intervient pour le compte du professionnel de santé, du centre de santé,
          de la structure de soin ou de l’organisme payeur en qualité de sous-traitant. <br>
        </p>

        <h4>
          <strong><u>Finalités du traitement de données</u> </strong>
        </h4>
        <p>
          Teliya santé utilise les données des utilisateurs principalement pour les finalités suivantes : <br>
        </p>
        <ul>
          <li>
            La création d’un compte utilisateur,
          </li>
          <li>
            La gestion de la facturation et le cas échéant du paiement des services ;
          </li>
          <li>
            La personnalisation des services sélectionnés par l’utilisateur ;
          </li>
          <li>
            La prévention et la gestion de la fraude ;
          </li>
          <li>
            L’évaluation de la satisfaction des utilisateurs au titre de l’utilisation des services ;
          </li>
          <li>
            L’amélioration de l’utilisation des services et de la solution ;
          </li>
          <li>
            La réalisation d’actions de prospection commerciale conformes aux dispositions légales et réglementaires ;
          </li>
        </ul>
        <p>
          Le traitement des données de l’utilisateur est nécessaire pour répondre à ces finalités. A défaut du
          traitement de ses données, l’utilisateur ne pourra créer de compte personnel via l’application mobile
          ni utiliser ou souscrire aux services Teliya santé. <br>
        </p>
        <h4>
          <strong><u>Bases légales des traitements de données personnelles</u></strong>
        </h4>
        <p>
          Selon la finalité, le traitement de vos données est fondé : <br>
        </p>
        <ul>
          <li>Sur le consentement (communication commerciale et gestion fichier client),</li>
          <li>Sur l’exécution de contrat (gestion de la carte de santé Teliya, conditions générales),</li>
          <li>Sur l’intérêt légitime de Gamma Concept SA (répondre aux candidatures ou aux formulaires de contact ou
            mesurer la fréquentation du site)</li>
          <li>Sur le respect d’une obligation légale (gérer les demandes des personnes liées à l’exercice de leurs
            droits)</li>
        </ul>
        <h4>
          <strong><u>Données collectées</u></strong>
        </h4>
        <p>
          Les données collectées par Gamma Concept SA sont : <br>
        </p>
        <ul>
          <li>Les données administratives de l’utilisateur (nom, prénom, date de naissance, téléphone, mail, photo),
          </li>
          <li>Les données administratives des proches rattachés au compte de l’utilisateur et à sa carte de santé,</li>
          <li>Les données de connexion de l'utilisateur,</li>
          <li>Les données de santé de l’utilisateur patient (historique de soins, historique des rendez-vous,
            ordonnances),</li>
          <li>Les données de paiement de l'utilisateur.</li>
          <li>Les données d’assurance maladie</li>
        </ul>
        <p>
          A défaut du traitement de ses données, l’utilisateur ne pourra souscrire aux services Teliya santé et
          créer un compte via l’application mobile Teliya Santé. <br>
        </p>
        <p>
          Le consentement exprès de l’utilisateur à la collecte et au traitement de ses données de santé est donnée
          aux stades de création de son compte personnel et de souscription à la carte de santé. <br>
        </p>
        <p>
          Les données de santé des utilisateurs sont traitées par les professionnels de santé et des organismes payeurs
          en vertu de leur consentement exprès.
        </p>
        <h4>
          <strong><u>Accès aux données personnelles</u></strong>
        </h4>
        <p>
          Les Données des Utilisateurs sont destinées : <br>
        </p>
        <ul>
          <li>
            Aux membres du personnel de Gamma Concept SA,
            spécifiquement habilités, dans le strict respect de leurs missions ;
          </li>
          <li>
            Aux membres du personnel des prestataires techniques spécifiquement
            habilités, dans le strict respect de leurs missions uniquement à des fins de gestion technique ;
          </li>
          <li>
            Aux personnes habilitées au titre des tiers autorisés par la loi.
          </li>
          <li>
            Aux organismes payeurs, leur accès à vos données se font sur la base de
            contrats signés faisant mention des obligations leur incombant en matière de
            protection de la sécurité et de la confidentialité des données.
          </li>
        </ul>
        <p>
          Dans le respect des règles relatives au secret médical et au partage des données de santé
          couvertes par le secret médical, les informations concernant la santé des utilisateurs
          sont destinées exclusivement : <br>
        </p>
        <ul>
          <li>
            Aux médecins et aux professionnels choisis par l’utilisateur pour lui délivrer un service ;
          </li>
          <li>
            Aux organismes payeurs, spécifiquement habilités dans le strict respect de leurs missions ;
          </li>
          <li>
            Avec son consentement, au médecin traitant déclaré par l’utilisateur.
          </li>
        </ul>
        <p>
          Les responsables de traitement garantissent chacun pour ce qui le concerne que les données
          de l’utilisateur ne seront transmises à aucun tiers non autorisé, sans son accord. <br>
        </p>

        <h4>
          <strong><u>Durée de conservation</u></strong>
        </h4>
        <p>
          Nous conservons les données personnelles pendant une durée n’excédant pas celle nécessaire
          à la réalisation des finalités pour lesquelles elles ont été collectées ou traitées et ce
          dans le respect de la règlementation actuelle. <br>
        </p>
        <h4>
          <strong><u>Droit des personnes</u></strong>
        </h4>
        <p>
          Conformément à la règlementation, l’utilisateur est titulaire d'un droit d'accès, de rectification,
          d’opposition, de suppression des données à caractère personnel le concernant, qu’il peut exercer
          auprès de Gamma Concept SA par courrier électronique à l'adresse électronique suivante : contact@teliya.net
          <br>
        </p>
        <p>
          L’utilisateur est également titulaire des droits suivants qu’il exerce suivant les mêmes modalités : <br>
        </p>
        <ul>
          <li>
            Un droit à l’effacement (droit à l’oubli) ;
          </li>
          <li>
            Un droit de solliciter une limitation du traitement des données le concernant ;
          </li>
          <li>
            Un droit d’opposition au regard du traitement des données personnelles le concernant;
          </li>
          <li>
            Un droit à la portabilité des Données dont le traitement est fondé sur le consentement ou l’exécution des
            CGU (Conditions Générales d'Utilisation);
          </li>
        </ul>
        <p>
          L’utilisateur reconnaît qu’il peut, à tout moment, retirer son consentement et s’opposer au traitement
          de ses données en demandant la clôture de son compte suivant les mêmes modalités.
          <br>
        </p>
        <p>
          L’utilisateur peut enfin formuler des directives relatives à la conservation, à l’effacement et à la
          communication de ses données à caractère personnel après son décès via l’adresse susmentionnée.
          <br>
        </p>
        <h4>
          <strong><u>Traitement des données des utilisateurs mineurs</u></strong>
        </h4>
        <p>
          Les utilisateurs majeurs peuvent déclarer des bénéficiaires mineurs, sous leur responsabilité propre
          et exclusive et leur communiquer les modalités d’accès aux services, mis en œuvre avec leur consentement
          sous la responsabilité et le contrôle des utilisateurs titulaires de l’autorité parentale. <br>
        </p>
        <p>
          Les utilisateurs mineurs déclarés en qualité de bénéficiaires et rattachés au compte personnel de
          l’utilisateur titulaire de l’autorité parentale sont informés que les titulaires de l’autorité parentale
          peuvent accéder à leurs données, y compris leurs données de santé depuis leur compte personnel.
        </p>
      </mat-dialog-content>

    </div>
  </section>
</div>
