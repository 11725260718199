<div class="panel panel-default m-5">
  <section class="content" >
      <h2 mat-dialog-title class="uppercase" style="text-align: center;">CONDITIONS GENERALES DU RESEAU DE SOINS</h2>
      <div style="max-height: 300px; overflow: auto;">
        <mat-dialog-content  class="mat-typography">

          <h3> CONDITIONS GENERALES</h3>

            <!-- <h4 style = "font-weight: bold;"> Préambule </h4> -->
            <p>
              Teliya santé est un produit de Gamma Concept S.A sise à Taouyah dans la commune de Ratoma et enregistrée sous le numéro RCCM/GC-KAL/05755/2004. Gamma Concept S.A est et demeure propriétaire de l’ensemble des droits de propriété intellectuelle et/ou industrielle relatifs à la solution mise à disposition du professionnel de santé. <br>
              Les relations entre Gamma Concept S.A et le professionnel de santé dans le cadre de l’utilisation de la solution Teliya Santé sont régies par le contrat de licence.<br>
              Ces présentes conditions générales sont susceptibles de changer, Gamma Concept S.A s’engage à notifier le professionnel de santé et à les publier directement sur le site web www.teliya.net. Par ailleurs, le professionnel de santé s’engage à les consulter et à les respecter.<br>
            </p>

            <h4 style = "font-weight: bold;"> ADHESION AU RESEAU DE SOINS </h4>
            <p>Cette demande d’adhésion ne vaut pas acceptation pour adhérer au réseau de soins Teliya. Gamma Concept S.A dispose d’un délai maximal de 7 jours ouvrés pour étudier cette demande d’adhésion et prendre sa décision. Passé ce délai, la demande sera considérée comme acceptée.</p>
            <!-- <p>Cette demande d’adhésion ne vaut pas acceptation pour participer au réseau. Gamma Concept S.A dispose d’un délai maximal de 3 jours ouvrés pour étudier la demande d’adhésion et prendre sa décision. Passé ce délai, la demande sera considérée comme acceptée, le professionnel de santé pourra contacter Gamma Concept SA pour demander l’installation du matériel.</p>
            <p>L’adhésion est valable pour la même durée que celle du contrat de licence.</p> -->

            <h4 style = "font-weight: bold;"> ENGAGEMENT ET RESPONSABILITES </h4>
            <p>
              Chaque partie s’interdit de prendre un engagement au nom et/ou pour le compte de l'autre. Il est précisé que chacune des parties demeure seule responsable de ses actes, allégations, engagements, prestations, produits et personnels. <br>
              Le professionnel de santé qui adhère au réseau de soins Teliya Santé s’engage à :
            </p>
            <p>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Entretenir le matériel, propriété de Gamma Concept S.A, qui lui a été remis dans le cadre de son adhésion au réseau de soins ;<br>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Suivre les formations mises à sa disposition via la plateforme de formation et/ou des séances de formation à distance ou en présentiel ; <br>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Communiquer toutes difficultés à Gamma Concept SA afin de permettre leur prise en compte le plus rapidement possible, participant ainsi à la qualité du service ;<br>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Communiquer les informations demandées ou nécessaires à l’exécution du service ;<br>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Mettre en œuvre tous les moyens dont elle dispose pour collaborer avec Gamma Concept S.A dans le cadre de l’exécution de ses obligations ; <br>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Assurer la disponibilité, la coopération et la compétence de son personnel utilisant la solution. <br>
            </p>
            <p>
              Gamma Concept S.A pourra prendre toute mesure d'évolution technique susceptible d'améliorer la solution et les services, à tout moment. De ce fait, elle s’engage à :
            </p>
            <p>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- faire ses meilleurs efforts pour tenir compte des réserves émises par le professionnel de santé, dans les limites des contraintes techniques de la solution <br>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- suivre et appliquer dans les meilleurs délais toutes les instructions que pourrait lui adresser le professionnel de santé en sa qualité de responsable de traitement.<br>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- remettre au professionnel de santé tout document, et lui transmettre toute information technique, commerciale ou administrative susceptible de permettre ou de faciliter l’exploitation de la solution.<br>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- fournir des prestations de maintenance comprenant : une prestation d’assistance utilisateur (configuration et paramétrage initial de son accès à la Solution) et une prestation de maintenance corrective (correction de défauts et/ou bugs)<br>
            </p>
            <p>
              Sont expressément exclus des prestations de maintenance ci-avant présentées : le matériel, les consommables et la réparation de dommages résultant notamment d’un accident, d’une négligence, d’une malveillance, d’une utilisation impropre, d’un défaut du réseau électrique, d’une intervention pratiquée sur le matériel support. <br>
              L’équipe de Gamma Concept S.A a la possibilité d’intervenir par télémaintenance en se connectant à distance à la plateforme et est également disponible aux heures de travail pour toute question et/ou demande sur l’adresse mail contact@teliya.net . <br>
            </p>
            <p>
              <strong>Le professionnel de santé souhaite adhérer au réseau de soins TELIYA SANTE aux conditions mentionnées dans cette demande</strong>.
              Son adhésion ne le décharge en rien de ses responsabilités vis-à-vis des patients, ni de ses obligations déontologiques.
            </p>

            <!-- <h4 style = "font-weight: bold;"> 3-	Etendue des droits d’utilisation </h4>
            <p> Le Service doit être utilisé : </p>
            <p>•	Conformément aux stipulations du présent contrat ;</p>
            <p>•	Pour les seuls besoins du client détenteur de la licence ou de ses affiliés, par ses salariés, à l’exclusion de tout tiers à son groupe ;</p>
            <p>•	Par un personnel autorisé qualifié qui aura préalablement suivi une formation adaptée à l’utilisation afin d’en obtenir les résultats désirés. </p>
            <p>L’utilisateur doit disposer d’un droit personnel d’utilisation de Teliya Santé  non-transférable. Toute utilisation non expressément autorisée par Teliya santé, constitue une contrefaçon qui sera sanctionnée. </p>
            <p>Il est notamment interdit de procéder à :</p>
            <p>•	Toute représentation, diffusion ou commercialisation de Teliya santé, que ce soit à titre gracieux ou onéreux ; </p>
            <p>•	Toute forme d'utilisation du service ou de la documentation fournies de quelque façon que ce soit aux fins de conception, réalisation, diffusion ou commercialisation d’un service ou d’un logiciel similaire, équivalent ou de substitution . </p>

            <h4 style = "font-weight: bold;"> 4-	Exploitation des données </h4>
            <p>L’utilisateur est seul responsable de la qualité, de la pertinence des données et contenus qu’il transmet aux fins d’utilisation. Il garantit en outre être titulaire des droits de propriété intellectuelle lui permettant d’utiliser les données et contenus. Plus généralement, l’utilisateur est seul responsable des contenus et messages diffusés et/ou téléchargés via Teliya santé. Il demeure le seul propriétaire des données qu’il a rentré ou saisi via les outils mis à disposition par Gamma concept SA. </p>
            <p>En conséquence le Gamma Concept SA dégage toute responsabilité en cas de non-conformité des données et/ou des contenus aux lois et règlements, à l’ordre public. L’utilisateur garantit Gamma Concept SA contre tout préjudice qui résulterait de sa mise en cause par un tiers pour une violation de cette garantie. </p>

            <h4 style = "font-weight: bold;"> 5-	Protection des données personnelles </h4>
            <p>Le Logiciel permet, de par sa conception, de stocker, traiter et communiquer des données personnelles en toute sécurité. Il revient au responsable du traitement de vérifier la licéité et la légitimité de ses collectes et traitement de données personnelles selon la législation applicable. </p>

            <h4 style = "font-weight: bold;">  6-	Responsabilité de l'utilisateur </h4>
            <p> L'utilisateur est seul responsable de la préservation de ses données. Gamma Concept SA décline toute responsabilité quant à la perte ou le vol du Logiciel, de ces composants ou des données de l'utilisateur. </p>

            <h4 style = "font-weight: bold;"> 7-	Assistance </h4>
            <p> Pour permettre la réalisation des prestations d’assistance, l’utilisateur s’engage notamment à : </p>
            <p> •	Se reporter à la documentation avant chaque demande d’intervention ; </p>
            <p> •	Mettre à disposition de Gamma concept SA toute information nécessaire demandée pour la compréhension et la résolution des anomalies et des dysfonctionnements rencontrés ; </p>
            <p> •	 Faciliter l’accès du personnel de Gamma concept SA à toutes ses installations si cela était nécessaire et à leur assurer un libre accès aux locaux ainsi qu’à lui indiquer un correspondant adéquat ; </p>
            <p> •	Installer et administrer ses équipements et applications non fournis par Gamma Concept SA, ainsi que ses réseaux. </p>

            <h4 style = "font-weight: bold;"> 8-	Sécurité des données </h4>
            <p>Chacune des parties s’engagent à mettre en œuvre les moyens techniques appropriés pour assurer la sécurité des données. Gamma Concept SA mettra en place les mesures techniques et organisationnelles de nature à empêcher tout accès ou utilisations fraudeuses des données et à prévenir toutes pertes, altérations et destructions des données. </p>

            <h4 style = "font-weight: bold;"> 9-	Propriété </h4>
            <p> L’utilisateur s’interdit de reproduire tout élément du logiciel, par quelque moyen que ce soit, sous quelque forme que ce soit et sur quelque support que ce soit hors documentation visant à la formation des utilisateurs du client disposant d’une licence. </p>

            <hr />
            <p>Version 1.0 <br/>
            Publié le 01/02/2021</p> -->

        </mat-dialog-content>

        </div>
  </section>
</div>
