import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from 'src/app/services/global.service';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-om-check-status',
  templateUrl: './om-check-status.component.html',
  styleUrls: ['./om-check-status.component.css']
})
export class OmCheckStatusComponent implements OnInit {
  ref_order: any;
  // ref_pattern='TELIYA-OM814687_0053851';
  payment_details: any;
  total_amount: any;
  message: any = "";
  invalidReference: boolean = false;
  moyenPaiement: string = "";
  // loading: boolean = false;
  showResult: boolean = false;
  constructor(
    private activatedRoute: ActivatedRoute,
    private OrderService: OrderService,
    private _formBuilder: FormBuilder,
    private toastr: ToastrService,
    private globalSvc: GlobalService,
  ) {
    this.globalSvc.setTitle('Vérification de paiement');
  }

  ngOnInit(): void {
    this.globalSvc.fixDisplay();
    this.ref_order = this.activatedRoute.snapshot.params.id;
    console.log(this.ref_order);

    // this.showSpinner();
    this.globalSvc.showNgxLoader();
    this.OrderService.verifyOrigin(this.ref_order).subscribe(
      async (res) =>
      {
        console.log(res);

        let order = res['order'];
        let recharge = res['recharge'];
        let insurance = res['insurance'];


        if (order != null)
        {
          this.payment_details = order;
          if(order.status_pay != null)
          {
            this.payment_details = order;
            this.setTransactionType(this.payment_details);
            console.log(this.payment_details);

          }else
          {
            if(order.pay_token != null) {
              this.getTransactionStatus(this.ref_order);
            }
            console.log(this.payment_details);

          }
          // if(order.pay_token != null) {
          //   //orange
          //   if(order.status_pay == null) {
          //     //request to om
          //   } else if(order.status_pay == 'SUCCESS') {
          //     //display message ok;

          //   } else {
          //     //display message failed;
          //   }
        } else if(recharge != null )
        {
          this.payment_details = recharge;
          if(recharge.status_pay != null)
          {
            this.payment_details = recharge;
            this.setTransactionType(this.payment_details);
            console.log(this.payment_details);

          }else
          {
            if(recharge.pay_token != null) {
              this.getTransactionStatus(this.ref_order);
            } else {

            }
            console.log(this.payment_details);

          }
          //visa
          // if(order.status_pay == 'ACCEPT') {
          //   // paiement ok
          //   this.payment_details = order;
          // } else {
          //   //display message failed;
          // }
        }  else if(insurance != null )
        {
          this.payment_details = insurance;
          if(insurance.status_pay != null)
          {
            this.payment_details = insurance;
            this.setTransactionType(this.payment_details);
            console.log(this.payment_details);

          }else
          {
            if(insurance.pay_token != null) {
              this.getTransactionStatus(this.ref_order);
            } else {

            }
            console.log(this.payment_details);

          }
          //visa
          // if(order.status_pay == 'ACCEPT') {
          //   // paiement ok
          //   this.payment_details = order;
          // } else {
          //   //display message failed;
          // }
        } else
        {
          this.invalidReference = true;
          this.showResult = true;
        }
        // this.loading = false;
        this.globalSvc.spinner.hide();
      },
      async (error) =>
      {
        this.globalSvc.spinner.hide();
        // this.loading = false;
        await this.toastr.error('', "Echec de l'opération, veuillez réessayer", {
          timeOut: 4000,
          tapToDismiss: true,
          progressBar: true
        });
        console.log(error);
      }
    );


    // console.log(this.ref_order);
    // this.getTransactionStatus(this.ref_order);

  }
  setTransactionType(payment)
  {
    if (payment.pay_token == null)
    {
      this.moyenPaiement = "Ecobank Visa";
    } else
    {
      this.moyenPaiement = "Orange Money"
    }
    this.showResult = true;
  }
  async getTransactionStatus(ref_order)
  {
    let data = {
      ref_order: ref_order
    };
    this.globalSvc.showNgxLoader();
    this.OrderService.checkOmTransactionStatus(data).subscribe(
      async (res) =>
      {
        console.log(res);
        if (res !== null)
        {
          this.payment_details = res['data'];
          this.setTransactionType(this.payment_details);
        } else
        {
          this.message = "Désolé, cette référence ne correspond à aucune transaction";
          this.invalidReference = true;
          this.showResult = true;
          await this.toastr.warning('', this.message, {
            timeOut: 4000,
            tapToDismiss: true,
            progressBar: true
          });
        }
        // this.globalSvc.spinner.hide();

        // console.log(res['data']);
      },
      async (error) =>
      {
       this.globalSvc.spinner.hide();
        await this.toastr.error('', "Echec de l'opération, veuillez réessayer", {
          timeOut: 4000,
          tapToDismiss: true,
          progressBar: true
        });
        console.log(error);
      }
    );
  }

  // showSpinner()
  // {
  //   this.loading = true;
  //   setTimeout(() => { this.loading = false; }, 30000);
  // }

}
