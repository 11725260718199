import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-between-us',
  templateUrl: './between-us.component.html',
  styleUrls: ['./between-us.component.css']
})
export class BetweenUsComponent implements OnInit {

  constructor(
    private globalSvc: GlobalService
  ) { }

  ngOnInit(): void {
    this.globalSvc.fixDisplay();
  }

}
