<div class="page-title-overlap stylish-color-dark pt-4 posi">
    <div class="container d-lg-flex justify-content-between">
      <div class="order-lg-2 pt-lg-2">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center justify-content-lg-start">
            <li class="breadcrumb-item"><a class="text-nowrap" href="index.html"><i class="fa fa-home"></i>Acceuil</a></li>
            <li class="breadcrumb-item text-nowrap active" aria-current="page">Login</li>
          </ol> 
        </nav> 
      </div>
      <div class="order-lg-1 pr-lg-4 text-center text-lg-left">
        <h1 class="h3 text-light mb-0">Login</h1>
      </div>
    </div>
  </div>

  <section class="view intro-2" >
    <div class="mask rgba-stylish-strong h-100 d-flex justify-content-center align-items-center">
      <div class="container">
        <div class="row">
          <div class="col-xl-5 col-lg-6 col-md-10 col-sm-12 mx-auto mt-lg-5">

            <!--Form with header-->
            <div class="card wow fadeIn" data-wow-delay="0.3s">
              <div class="card-body">

                <!--Header-->
                <div class="form-header purple-gradient">
                  <h3><i class="fa fa-user mt-2 mb-2"></i> Log in:</h3>
                </div>

                <div class="alert alert-danger" role="alert" *ngIf="message" >
                  {{message}}
                </div>
                <!--Body-->
              <form [formGroup]="form" (submit)="onLogin()" >
                <div class="md-form">
                  <i class="fa fa-envelope prefix white-text"></i>
                  <input type="email" id="orangeForm-email" class="form-control" formControlName="email">
                  <label for="orangeForm-email">Votre email</label>
                
                </div>
  
                <div class="md-form">
                  <i class="fa fa-lock prefix white-text"></i>
                  <input type="password" id="orangeForm-pass" class="form-control" formControlName="password">
                  <label for="orangeForm-pass">Mot de password</label>
                </div>
  
                <div class="text-right mt-2">
                  <a class="white-text" routerLink="/forgot_pass" >Mot de passe oublier ?</a> 
                </div>
                <div class="mt-3">
                  <button type="submit" mat-raised-button color="primary" [class.spinner]="loading" [disabled]="loading" style="width: 100%;" >Se connecter</button>
                 
                  <hr>
                  <div class="text-center">
                    <span class="white-text">Don't have an account ? </span><a class="green-text" routerLink="/register">Create an account</a>
                  </div>
                 
                  <div class="inline-ul text-center d-flex justify-content-center">
                    <a class="p-2 m-2 fa-lg tw-ic"><i class="fa fa-twitter white-text"></i></a>
                    <a class="p-2 m-2 fa-lg li-ic"><i class="fa fa-linkedin white-text"> </i></a>
                    <a class="p-2 m-2 fa-lg ins-ic"><i class="fa fa-instagram white-text"> </i></a>
                  </div>
                </div>

              </form>
               

              </div>
            </div>
            <!--/Form with header-->

          </div>
        </div>
      </div>
    </div>
  </section>
