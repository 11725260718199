import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  message: string;
  form: FormGroup;
  data : any;
  loading = false;

  constructor(private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private http: HttpClient,
    private globalSvc: GlobalService,
    ) { }

  ngOnInit(): void {
    this.globalSvc.fixDisplay();

    this.form = this.fb.group({
      first_name: new FormControl('', Validators.required),
      last_name: new FormControl('', Validators.required),
      email: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required),
      conf_password: new FormControl('', Validators.required)
     });
  }

  onRegister(){
    this.loading = true;
    this.authService.register(this.form.getRawValue())
    .subscribe(data=> {
      this.loading = false;
      this.router.navigateByUrl('/login');

    });


  }

}
