<div class="page-title-overlap stylish-color-img posi">
  <div class="container d-lg-flex justify-content-between pt-3">
    <div class="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb  flex-lg-nowrap justify-content-center justify-content-lg-start pb-2">
           <li class="breadcrumb-item"><a class="text-nowrap" routerLink="/home" ><i
                class="fa fa-home"></i>Acceuil</a></li>
                <li class="breadcrumb-item text-nowrap active" aria-current="page">Particuliers</li>
                <li class="breadcrumb-item text-nowrap active" aria-current="page" >Offres d'assurance</li>
        </ol>
      </nav>
    </div>
    <div class="order-lg-1 pr-lg-4 text-center text-lg-left">
      <h1 class="h3 text-light mb-0"></h1>
    </div>
  </div>
  <div class="row">
    <div class="col-md-8 offset-md-2">
      <h1 class="text-center mb-2 pt-2" style="color: #033B55; font-size: 38px; font-weight: bold;">
        Offres d'assurance Hospi
      </h1>
      <p class="text-center mb-2" style="color: white;">
        Faites votre choix parmis les offres disponibles
      </p>
    </div>
  </div>
</div>

<div class="shell">
  <div class="container">
    <div class="row">
      <div class="col-md-3 col-sm-6">
        <!-- <div>
          <p>
            Notre offre la plus populaire <i class="fa fa-certificate" style="color: gold;"></i>
          </p>
        </div> -->
        <div class="wsk-cp-product">
          <div class="wsk-cp-img">
            <img src="../../../../assets/img/carte-santé.png" alt="Product" class="img-responsive" />
          </div>
          <div class="wsk-cp-text">
            <!-- <div class="category">
              <span>200.000 GNF</span>
            </div> -->
            <div class="title-product">
              <h3>SOGAM</h3>
            </div>
            <div class="text-center mb-3">
              <span>200.000 GNF</span>
            </div>
            <div class="description-prod mb-2">
              <!-- <p>Description Product tell me how to change playlist height size like 600px in html5 player. player good
                work now check this link</p> -->
              <div>
                <span><i class="fa fa-check-square-o" style="color: green;" aria-hidden="true"></i> Dossier Médical
                  Partagé DMP</span><br><br>
                <span><i class="fa fa-check-square-o" style="color: green;" aria-hidden="true"></i> Portemonnaie
                  électronique de santé</span><br><br>
                <span><i class="fa fa-check-square-o" style="color: green;" aria-hidden="true"></i> Réseau de
                  prestatires de soins de qualité</span><br><br>
                <span style="text-decoration: line-through; color: red;"><i class="fa fa-window-close-o"
                    aria-hidden="true"></i> Couverture hospi de 100%</span><br>
              </div>
            </div>
            <div class="text-center pb-2">
              <a href="">Plus de détails</a>
            </div>
            <div class="card-footer">
              <p class="text-center">
                <button class="btn btn-outline-success rounded">Souscrire</button>
              </p>
              <!-- <div class="wcf-left"><span class="price">Rp500.000</span></div>
              <div class="wcf-right"><a href="#" class="buy-btn"><i class="zmdi zmdi-shopping-basket"></i></a></div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-sm-6">
        <div class="wsk-cp-product">
          <div class="wsk-cp-img">
            <img src="../../../../assets/img/carte-santé.png" alt="Product" class="img-responsive" />
          </div>
          <div class="wsk-cp-text">
            <!-- <div class="category">
              <span>1.000.000 GNF</span>
            </div> -->
            <div class="title-product">
              <h3>UGAR</h3>
            </div>
            <div class="text-center mb-3">
              <span>1.000.000 GNF</span>
            </div>
            <div class="description-prod mb-2">
              <!-- <p>Description Product tell me how to change playlist height size like 600px in html5 player. player good
                work now check this link</p> -->
              <span><i class="fa fa-check-square-o" style="color: green;" aria-hidden="true"></i> Dossier Médical
                Partagé DMP</span><br><br>
              <span><i class="fa fa-check-square-o" style="color: green;" aria-hidden="true"></i> Portemonnaie
                électronique de santé</span><br><br>
              <span><i class="fa fa-check-square-o" style="color: green;" aria-hidden="true"></i> Réseau de prestatires
                de soins de qualité</span><br><br>
              <span><i class="fa fa-check-square-o" style="color: green;" aria-hidden="true"></i> Couverture hospi de
                100%</span><br>
            </div>
            <div class="text-center pb-2">
              <a href="">Plus de détails</a>
            </div>
            <div class="card-footer">
              <p class="text-center">
                <button class="btn btn-outline-success rounded">Souscrire</button>
              </p>
              <!-- <div class="wcf-left"><span class="price">Rp500.000</span></div>
              <div class="wcf-right"><a href="#" class="buy-btn"><i class="zmdi zmdi-shopping-basket"></i></a></div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-sm-6">
        <!-- <div>
          <p>
            Notre offre la plus populaire <i class="fa fa-certificate" style="color: gold;"></i>
          </p>
        </div> -->
        <div class="wsk-cp-product">
          <div class="wsk-cp-img">
            <img src="../../../../assets/img/carte-santé.png" alt="Product" class="img-responsive" />
          </div>
          <div class="wsk-cp-text">
            <!-- <div class="category">
              <span>200.000 GNF</span>
            </div> -->
            <div class="title-product">
              <h3>LANALA</h3>
            </div>
            <div class="text-center mb-3">
              <span>200.000 GNF</span>
            </div>
            <div class="description-prod mb-2">
              <!-- <p>Description Product tell me how to change playlist height size like 600px in html5 player. player good
                work now check this link</p> -->
              <div>
                <span><i class="fa fa-check-square-o" style="color: green;" aria-hidden="true"></i> Dossier Médical
                  Partagé DMP</span><br><br>
                <span><i class="fa fa-check-square-o" style="color: green;" aria-hidden="true"></i> Portemonnaie
                  électronique de santé</span><br><br>
                <span><i class="fa fa-check-square-o" style="color: green;" aria-hidden="true"></i> Réseau de
                  prestatires de soins de qualité</span><br><br>
                <span style="text-decoration: line-through; color: red;"><i class="fa fa-window-close-o"
                    aria-hidden="true"></i> Couverture hospi de 100%</span><br>
              </div>
            </div>
            <div class="text-center pb-2">
              <a href="">Plus de détails</a>
            </div>
            <div class="card-footer">
              <p class="text-center">
                <button class="btn btn-outline-success rounded">Souscrire</button>
              </p>
              <!-- <div class="wcf-left"><span class="price">Rp500.000</span></div>
              <div class="wcf-right"><a href="#" class="buy-btn"><i class="zmdi zmdi-shopping-basket"></i></a></div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-sm-6">
        <div class="wsk-cp-product">
          <div class="wsk-cp-img">
            <img src="../../../../assets/img/carte-santé.png" alt="Product" class="img-responsive" />
          </div>
          <div class="wsk-cp-text">
            <!-- <div class="category">
              <span>1.000.000 GNF</span>
            </div> -->
            <div class="title-product">
              <h3>MUTRAGUI</h3>
            </div>
            <div class="text-center mb-3">
              <span>1.000.000 GNF</span>
            </div>
            <div class="description-prod mb-2">
              <!-- <p>Description Product tell me how to change playlist height size like 600px in html5 player. player good
                work now check this link</p> -->
              <span><i class="fa fa-check-square-o" style="color: green;" aria-hidden="true"></i> Dossier Médical
                Partagé DMP</span><br><br>
              <span><i class="fa fa-check-square-o" style="color: green;" aria-hidden="true"></i> Portemonnaie
                électronique de santé</span><br><br>
              <span><i class="fa fa-check-square-o" style="color: green;" aria-hidden="true"></i> Réseau de prestatires
                de soins de qualité</span><br><br>
              <span><i class="fa fa-check-square-o" style="color: green;" aria-hidden="true"></i> Couverture hospi de
                100%</span><br>
            </div>
            <div class="text-center pb-2">
              <a href="">Plus de détails</a>
            </div>
            <div class="card-footer">
              <p class="text-center">
                <button class="btn btn-outline-success rounded">Souscrire</button>
              </p>
              <!-- <div class="wcf-left"><span class="price">Rp500.000</span></div>
              <div class="wcf-right"><a href="#" class="buy-btn"><i class="zmdi zmdi-shopping-basket"></i></a></div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-sm-6">
        <!-- <div>
          <p>
            Notre offre la plus populaire <i class="fa fa-certificate" style="color: gold;"></i>
          </p>
        </div> -->
        <div class="wsk-cp-product">
          <div class="wsk-cp-img">
            <img src="../../../../assets/img/carte-santé.png" alt="Product" class="img-responsive" />
          </div>
          <div class="wsk-cp-text">
            <!-- <div class="category">
              <span>200.000 GNF</span>
            </div> -->
            <div class="title-product">
              <h3>SAAR</h3>
            </div>
            <div class="text-center mb-3">
              <span>200.000 GNF</span>
            </div>
            <div class="description-prod mb-2">
              <!-- <p>Description Product tell me how to change playlist height size like 600px in html5 player. player good
                work now check this link</p> -->
              <div>
                <span><i class="fa fa-check-square-o" style="color: green;" aria-hidden="true"></i> Dossier Médical
                  Partagé DMP</span><br><br>
                <span><i class="fa fa-check-square-o" style="color: green;" aria-hidden="true"></i> Portemonnaie
                  électronique de santé</span><br><br>
                <span><i class="fa fa-check-square-o" style="color: green;" aria-hidden="true"></i> Réseau de
                  prestatires de soins de qualité</span><br><br>
                <span style="text-decoration: line-through; color: red;"><i class="fa fa-window-close-o"
                    aria-hidden="true"></i> Couverture hospi de 100%</span><br>
              </div>
            </div>
            <div class="text-center pb-2">
              <a href="">Plus de détails</a>
            </div>
            <div class="card-footer">
              <p class="text-center">
                <button class="btn btn-outline-success rounded">Souscrire</button>
              </p>
              <!-- <div class="wcf-left"><span class="price">Rp500.000</span></div>
              <div class="wcf-right"><a href="#" class="buy-btn"><i class="zmdi zmdi-shopping-basket"></i></a></div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-sm-6">
        <div class="wsk-cp-product">
          <div class="wsk-cp-img">
            <img src="../../../../assets/img/carte-santé.png" alt="Product" class="img-responsive" />
          </div>
          <div class="wsk-cp-text">
            <!-- <div class="category">
              <span>1.000.000 GNF</span>
            </div> -->
            <div class="title-product">
              <h3>SUNU</h3>
            </div>
            <div class="text-center mb-3">
              <span>1.000.000 GNF</span>
            </div>
            <div class="description-prod mb-2">
              <!-- <p>Description Product tell me how to change playlist height size like 600px in html5 player. player good
                work now check this link</p> -->
              <span><i class="fa fa-check-square-o" style="color: green;" aria-hidden="true"></i> Dossier Médical
                Partagé DMP</span><br><br>
              <span><i class="fa fa-check-square-o" style="color: green;" aria-hidden="true"></i> Portemonnaie
                électronique de santé</span><br><br>
              <span><i class="fa fa-check-square-o" style="color: green;" aria-hidden="true"></i> Réseau de prestatires
                de soins de qualité</span><br><br>
              <span><i class="fa fa-check-square-o" style="color: green;" aria-hidden="true"></i> Couverture hospi de
                100%</span><br>
            </div>
            <div class="text-center pb-2">
              <a href="">Plus de détails</a>
            </div>
            <div class="card-footer">
              <p class="text-center">
                <button class="btn btn-outline-success rounded">Souscrire</button>
              </p>
              <!-- <div class="wcf-left"><span class="price">Rp500.000</span></div>
              <div class="wcf-right"><a href="#" class="buy-btn"><i class="zmdi zmdi-shopping-basket"></i></a></div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
