import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-insurance-offers',
  templateUrl: './insurance-offers.component.html',
  styleUrls: ['./insurance-offers.component.css']
})
export class InsuranceOffersComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }
  goToOffer(offer: string){
    (offer === "hospi") ? this.router.navigateByUrl("/subscribe-to-insurance") : this.router.navigateByUrl("/checkout");
  }

}
