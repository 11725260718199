 <!-- Footer -->
 <footer class="page-footer text-center text-md-left stylish-color-dark pt-0 pl-0">

    <div style="background-color: #15b357;">

      <div class="container">

        <!-- Grid row -->
        <div class="row py-4 d-flex align-items-center">

          <!-- Grid column -->
          <div class="col-md-6 col-lg-5 text-center text-md-left mb-4 mb-md-0">

            <h3 class="mb-0 white-text">Rejoignez nous sur les réseaux sociaux!</h3>

          </div>
          <!-- Grid column -->

          <!-- Grid column -->
          <div class="col-md-6 col-lg-7 text-center text-md-right">

            <!-- Facebook -->
            <a class="p-2 m-2 fb-ic ml-0" href="https://www.facebook.com/cartesante/" target="_blank">

              <i class="fa fa-facebook-f white-text mr-lg-4 fa-2x"> </i>

            </a>

            <!-- Twitter -->
            <!-- <a class="p-2 m-2 tw-ic">

              <i class="fa fa-twitter white-text mr-lg-4 fa-2x"> </i>

            </a> -->

            <!-- Linkedin -->
            <!-- <a class="p-2 m-2 li-ic">

              <i class="fa fa-linkedin white-text mr-lg-4 fa-2x"> </i>

            </a> -->



          </div>
          <!-- Grid column -->

        </div>
        <!-- Grid row -->

      </div>

    </div>

    <!-- Footer Links -->
    <div class="container mt-5 mb-5 text-center text-md-left">

      <div class="row mt-3">

        <!-- First column -->
        <div class="col-md-3 col-lg-4 col-xl-3 mb-r">

          <h4 class="title font-bold">

            <strong class="text-uppercase">Teliya</strong>

          </h4>

          <hr class="green accent-3 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px;">
          <p>
            Teliya santé est une solution mise en place dans le but de faciliter et de sécuriser
            la gestion de la santé en Afrique
          </p>

        </div>
        <!-- First column -->

        <!-- Second column -->
        <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-r">

          <h4 class="title font-bold">

            <strong class="text-uppercase">Navigation</strong>

          </h4>

          <hr class="green accent-3 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px;">

          <p>

            <a routerLink="/home">Accueil</a>

          </p>


          <p>

            <a routerLink="/contact">Contact</a>

          </p>


        </div>
        <!-- Second column -->

        <!-- Third column -->
        <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-r">

          <h4 class="title font-bold">

            <strong class="text-uppercase" >Liens utiles</strong>

          </h4>

          <hr class="green accent-3 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px;">

          <!-- <p>

            <a routerLink="/conditions">Conditions d'utilisation</a>

          </p> -->

          <p>

            <a routerLink="/about">A propos</a>

          </p>
          <!-- <p>

            <a routerLink="#!">Aide</a>

          </p> -->

        </div>
        <!-- Third column -->

        <!-- Fourth column -->
        <div class="col-md-4 col-lg-3 col-xl-3">

          <h4 class="title font-bold">

            <strong class="text-uppercase">Contact</strong>
          </h4>

          <hr class="green accent-3 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px;">

          <p>

            <i class="fa fa-home mr-3"></i> Taouyah corniche, Commune de Ratoma - BP 200 Conakry</p>

          <p>

            <i class="fa fa-envelope mr-3"></i> contact@teliya.com</p>

          <p>

            <i class="fa fa-phone mr-3"></i> +224 625 700 000</p>


        </div>
        <!-- Fourth column -->

      </div>

    </div>
    <!-- Footer Links -->

    <!-- Copyright -->
    <div class="footer-copyright text-center py-3">

      <div class="container-fluid">

        © 2021 Copyright: GAMMA CONCEPT S.A

      </div>

    </div>
    <!-- Copyright -->

  </footer>
